import React from "react";
import PropTypes from "prop-types";

export const ExcelExportIcon = (props) => {
  const {
    handleExport,
    getFilteredRows,
    className = "",
    iconClass = "mdi mdi-microsoft-excel",
    mouseEnterColor = "#0875e1",
    mouseLeaveColor = "#4aa3ff",
  } = props;

  return (
    <div className={className}>
      <i
        onClick={() =>
          getFilteredRows
            ? handleExport({ getRowsToExport: getFilteredRows })
            : handleExport()
        }
        className={iconClass}
        aria-hidden="true"
        aria-label="export"
        role="button"
        style={{ cursor: "pointer", fontSize: "16px", color: mouseLeaveColor }}
        onMouseEnter={(e) => (e.target.style.color = mouseEnterColor)}
        onMouseLeave={(e) => (e.target.style.color = mouseLeaveColor)}
      ></i>
    </div>
  );
};

ExcelExportIcon.propTypes = {
  handleExport: PropTypes.func.isRequired,
  getFilteredRows: PropTypes.any,
  className: PropTypes.string,
  iconClass: PropTypes.string,
  mouseEnterColor: PropTypes.string,
  mouseLeaveColor: PropTypes.string,
};
