import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import "./landpagestyle.scss";
import { Card, CardBody, CardTitle } from "reactstrap";
import { quickLinkFetch } from "../../store/dashboard/orchestractor";

const QuickLinks = () => {
  const [quickLinksData, setQuickLinksData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const requestParam = `?per_page=10`;
        const data = await quickLinkFetch(requestParam);
        setQuickLinksData(data || []);
      } catch (error) {
        console.error("Error fetching quick links:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <Card style={{ height: "240px" }}>
      <CardBody>
        <CardTitle>Quick Links</CardTitle>
        <div className="pe-3">
          <SimpleBar style={{ maxHeight: "180px" }}>
            {quickLinksData.map((item, key) => (
              <Link
                key={item.id}
                className="text-body d-block highlight"
                onClick={(event) => {
                  event.preventDefault(); // Prevent the default Link behavior
                  window.open(item.linkURL, "_blank"); // Open the link in a new tab
                }}
              >
                <div className="d-flex py-3">
                  <div className="flex-shrink-0 me-3 align-self-center">
                    {item.src ? (
                      <img
                        className="rounded-circle avatar-xs"
                        alt=""
                        src={item.src}
                      />
                    ) : (
                      <div className="avatar-xs">
                        <span className="avatar-title bg-soft-primary rounded-circle text-primary">
                          <i
                            className="mdi mdi-link-variant"
                            style={{ fontSize: "20px" }}
                          ></i>
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-14 mb-1">{item.subject}</h5>
                    <p className="text-truncate mb-0">{item.description}</p>
                  </div>
                </div>
                {key !== quickLinksData.length - 1 && <hr className="my-1" />}
              </Link>
            ))}
          </SimpleBar>
        </div>
      </CardBody>
    </Card>
  );
};

export default QuickLinks;
