import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
const colorArr = ["#11c46e", "#ff3d60", "#f1b44c", "#9da1a6"]; // Define the color array

const DashdountSuite = ({
  passedCount,
  failedCount,
  warningCount,
  undefinedCount,
}) => {
  // Data and options for the donut chart
  const chartData = {
    series: [passedCount, failedCount, warningCount, undefinedCount],
    options: {
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let total = 0;
          for (let x of series) {
            total += x;
          }
          let selected = series[seriesIndex];

          return `
            <div style="color :white;font-size:10px;padding:5px; background-color: ${
              colorArr[seriesIndex]
            };">
              ${w.config.labels[seriesIndex]}: ${((selected / total) * 100)
            .toFixed(0)
            .toLocaleString()}%
            </div>
          `;
        },
      },
      labels: ["Passed", "Failed", "Warning", "Undefined"],
      colors: ["#11c46e", "#ff3d60", "#f1b44c", "#9da1a6"],
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false, // Hide data labels
      },
      plotOptions: {
        pie: {
          donut: {
            size: "70%",
            labels: {
              show: false, // Hide data labels within donut segments
            },
          },
        },
      },
      stroke: {
        show: false, // Remove lines between series
        width: 1,
      },
    },
  };

  return (
    <ReactApexChart
      id="donutDashSuite"
      options={chartData.options}
      series={chartData.series}
      type="donut"
      height="95"
      className="apex-charts"
    />
  );
};

export default DashdountSuite;

DashdountSuite.propTypes = {
  passedCount: PropTypes.any,
  failedCount: PropTypes.any,
  warningCount: PropTypes.any,
  undefinedCount: PropTypes.any,
};
