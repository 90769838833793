import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { Box, Paper, Typography } from "@mui/material";

import { menuSelectionChange, setSelectedReport } from "../../store/actions";
import { fetchSuiteRunListAPI } from "../../store/dashboard/orchestractor";

import Breadcrumbs from "../AHeaderFooterSections/HeaderComponents/Breadcrumb";
import { Col, Row } from "reactstrap";
import { ReportFunctionalArea } from "./ReportFunctionalArea";
import { BarChart } from "../DashboardNew/Charts/BarChart";
import GridMain from "../Dashboard/DataGrid/gridDash";
import { LeftFilter } from "./LeftFilter";
import emptyReportIcon from "../../assets/svg/empty_report.svg";

const Reports = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const chartHeight = 370;
  const dateRangeLists = [
    { value: 1, name: "Today" },
    { value: 2, name: "This Week" },
    { value: 3, name: "This Month" },
    { value: 4, name: "This Year" },
    { value: 5, name: "Custom" },
  ];
  const defaultFormDetails = {
    selectedTenant: "",
    dateRange: 1,
    functionalArea: 0,
    category: 0,
    testSuite: 0,
    status: [],
  };
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate());

  const { tenants, selectedReportData, faAreas } = useSelector(
    (state) => state.dashboardData || {}
  );

  const [filterDetails, setFilterDetails] = useState(null);
  const [formDetails, setFormDetails] = useState(defaultFormDetails);
  const [selectedDateRangeValue, setSelectedDateRangeValue] = useState(null);
  const [isCustomSelected, setIsCustomSelected] = useState(false);
  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedDateRangeType, setSelectedDateRangeType] = useState(0);
  const [FASelection, setFAAreaSelection] = useState();

  const [functionalAreaList, setFunctionalAreaList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [testSuiteList, setTestSuiteList] = useState([]);
  const [reportType, setReportType] = useState("");

  useEffect(() => {
    document.title = "Home | Ilumn ";
    dispatch(menuSelectionChange(2));
  }, [dispatch]);
  useEffect(() => {
    setSelectedDate([]);
    const todayDate = moment(new Date()).format("YYYY-MM-DD");
    setSelectedDateRangeValue({ startDate: todayDate, endDate: todayDate });
    setSelectedDateRangeType(1);
    setFormDetails({
      ...defaultFormDetails,
      selectedTenant: tenants?.length > 0 ? tenants[0]?.tenantId : 0,
    });
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (filterDetails) {
      populateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDetails]);

  const fetchData = async () => {
    try {
      const data = await fetchSuiteRunListAPI();
      if (data?.suiteScriptListByFA) {
        setFilterDetails(data);
      }
    } catch (error) {}
  };

  const populateForm = () => {
    console.log("populateForm ===> ", filterDetails);
    console.log("populateForm 2 ===> ", selectedReportData);
    if (selectedReportData?.id === 5) {
      setReportType("new");
    }
    if (filterDetails.suiteScriptListByFA?.length) {
      const funcAreaList = [];
      let categoryList = [];
      let testSuiteList = [];
      filterDetails.suiteScriptListByFA.forEach((rec, index) => {
        funcAreaList.push({
          value: rec?.faId || "",
          name: rec?.faShortName || "",
        });
        if (index === 0 && rec?.modules?.length) {
          categoryList = rec?.modules?.map((cat, val) => {
            if (val === 0 && cat?.suiteScripts?.length) {
              testSuiteList = cat?.suiteScripts?.map((test) => ({
                value: test?.suiteId || 0,
                name: test?.suiteName || "",
              }));
            }
            return {
              value: cat?.moduleName || "",
              name: cat?.moduleName || "",
            };
          });
        }
      });
      setFunctionalAreaList(funcAreaList);
      setCategoryList(categoryList);
      setTestSuiteList(testSuiteList);
    } else {
      setFunctionalAreaList([]);
      setCategoryList([]);
      setTestSuiteList([]);
    }
  };

  const getStartEndDate = (type = "week") => {
    const startDate = moment().startOf(type).format("YYYY-MM-DD");
    const endDate = moment().endOf(type).format("YYYY-MM-DD");
    return {
      startDate,
      endDate,
    };
  };

  const handleFilterSelection = (event) => {
    const { name, value } = event.target;
    const selectedVal = value ? parseInt(value) : 0;
    if (name === "dateRange" && selectedVal) {
      if (selectedVal === 1) {
        const todayDate = moment(new Date()).format("YYYY-MM-DD");
        setIsCustomSelected(false);
        setSelectedDate([]);
        setSelectedDateRangeValue({ startDate: todayDate, endDate: todayDate });
      } else if (selectedVal === 2) {
        setIsCustomSelected(false);
        setSelectedDate([]);
        setSelectedDateRangeValue(getStartEndDate("week"));
      } else if (selectedVal === 3) {
        setIsCustomSelected(false);
        setSelectedDate([]);
        setSelectedDateRangeValue(getStartEndDate("month"));
      } else if (selectedVal === 4) {
        setIsCustomSelected(false);
        setSelectedDate([]);
        setSelectedDateRangeValue(getStartEndDate("year"));
      } else if (selectedVal === 5) {
        setIsCustomSelected(true);
      } else {
        setIsCustomSelected(false);
        setSelectedDate([]);
      }
      setSelectedDateRangeType(selectedVal);
    } else if (name === "functionalArea") {
    } else if (name === "category") {
    } else if (name === "testSuite") {
    } else if (name === "status") {
    }
    setFormDetails({
      ...formDetails,
      [name]: selectedVal,
    });
  };

  const redirectToReportPage = (event) => {
    dispatch(setSelectedReport(null));
    navigate("/dashboard");
  };

  return (
    <div
      className="page-content"
      id="component"
      style={{ backgroundColor: "#fff" }}
    >
      <Box
        pb={1}
        style={{
          borderBottom: "1px solid #f3f3f3",
        }}
      >
        <Breadcrumbs
          title="Custom Reports"
          breadcrumbItem="Custom Report"
          showButton={true}
          buttonText={"Go to Dashboard"}
          buttonClickHandler={redirectToReportPage}
        />
      </Box>
      <Box mt={2} style={{ minHeight: "500px" }}>
        <Row>
          <Col className="col-3" style={{ borderRight: "1px solid #f3f3f3" }}>
            <LeftFilter
              formDetails={formDetails}
              handleFilterSelection={handleFilterSelection}
              tenants={tenants}
              dateRangeLists={dateRangeLists}
              isCustomSelected={isCustomSelected}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              tomorrow={tomorrow}
              functionalAreaList={functionalAreaList}
              categoryList={categoryList}
              testSuiteList={testSuiteList}
            />
          </Col>
          {reportType === "new" ? (
            <Col className="col-9">
              <Box
                sx={{
                  height: "500px", // Parent box height
                  display: "flex",
                  justifyContent: "center", // Centers horizontally
                  alignItems: "center", // Centers vertically
                }}
              >
                <Box
                  sx={{
                    width: "350px",
                    height: "200px",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={emptyReportIcon}
                    style={{ height: 100, width: 100 }}
                    alt="drag-icon"
                  />
                  <Typography
                    gutterBottom
                    sx={{ mt: 1, color: "text.primary", fontSize: 24 }}
                  >
                    Create your custom report
                  </Typography>
                  <Typography
                    sx={{ color: "text.secondary", fontSize: 14 }}
                  >
                    Start building your custom report by selecting values from
                    left panel.
                  </Typography>
                </Box>
              </Box>
            </Col>
          ) : (
            <Col className="col-9">
              <Box mt={2}>
                <ReportFunctionalArea
                  FASelection={FASelection}
                  setFAAreaSelection={setFAAreaSelection}
                  dashboardData={faAreas}
                />
              </Box>
              <Box
                mt={2}
                style={{
                  marginLeft: 2,
                  marginRight: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    marginTop: 2,
                    border: "1px solid #f3f3f3",
                    padding: "0px",
                    width: "49%",
                  }}
                >
                  <Box>
                    <BarChart
                      isBar={false}
                      isStacked={true}
                      colors={["#29CA7D", "#FF3F61", "#FFC53F", "#AAAAAA"]}
                      height={chartHeight}
                    />
                  </Box>
                </div>
                <div
                  style={{
                    marginTop: 2,
                    border: "1px solid #f3f3f3",
                    padding: "0px",
                    width: "49%",
                  }}
                >
                  <Box>
                    <BarChart
                      isBar={false}
                      isStacked={true}
                      colors={["#29CA7D", "#FF3F61", "#FFC53F", "#AAAAAA"]}
                      height={chartHeight}
                    />
                  </Box>
                </div>
              </Box>
              <Box>
                <Paper
                  elevation={0}
                  style={{ paddingBottom: 10, marginBottom: 20 }}
                >
                  <Row style={{ marginLeft: 2, marginRight: 2 }}>
                    {/* <SuiteArea /> */}
                    <GridMain />
                  </Row>
                </Paper>
              </Box>
            </Col>
          )}
        </Row>
      </Box>
    </div>
  );
};

export default Reports;
