import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import { useProfile } from "../../Hooks/UserHooks";
import { StyledDataGrid } from "../generalFunctions";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { EditUserDetails } from "./EditUserDetails";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import {
  createNewUserDetails,
  deleteUser,
  updateSelfUserDetails,
  updateUserDetails,
} from "../../store/dashboard/orchestractor";
import { setLoadingState } from "../../store/actions"; // showError for error
import { roleDetails } from "../../config/util";
import { CreateUser } from "./CreateUser";

const renderUserRole = (params) => {
  const userRoleMap = {
    t: "Tester",
    v: "Viewer",
    a: "Admin",
    pv: "Privileged",
  };

  return userRoleMap[params.row.userRole] || "";
};

const CustomToolbar = () => (
  <div
    className="d-flex justify-content-between align-items-center"
    style={{ padding: "0 5px", borderBottom: "1px solid #f0f0f0" }}
  >
    <h6 style={{ marginBottom: "5px", fontSize: "11px" }}>User Listing</h6>
  </div>
);

export const UserList = (props) => {
  const { userList, getAllUserDetails } = props;
  const dispatch = useDispatch();
  const { userProfile } = useProfile(); // loading

  const headerHeight = 28;
  const rowHeight = 26;
  const fNameErrMsg = "First Name is required.";
  const sNameErrMsg = "Last Name is required.";
  const emailErrMsg = "Email is required.";
  const defaultError = {
    isError: false,
    message: null,
  };
  const defaultCreateUserDetails = {
    userFirstName: "",
    userLastName: "",
    userEmailId: "",
    userRole: "t",
    userPassword: "",
    userDisplayName: "",
  };

  const [profileDetails, setProfileDetails] = useState(userProfile?.user || {});
  const [rows, setRows] = useState([]);
  const [selectedUserSelection, setSelectedUserSelection] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [userModelType, setUserModelType] = useState(null);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [view, setView] = React.useState("thumbnail");
  const [searchText, setSearchText] = useState("");
  const [profileEditDetails, setProfileEditDetails] = useState({});
  const [fNameErrorMessages, setFNameErrorMessages] = useState(defaultError);
  const [lNameErrorMessages, setLNameErrorMessages] = useState(defaultError);
  const [createUserDetails, setCreateUserDetails] = useState(
    defaultCreateUserDetails
  );
  const [fNameCreateErrorMessages, setFNameCreateErrorMessages] =
    useState(defaultError);
  const [lNameCreateErrorMessages, setLNameCreateErrorMessages] =
    useState(defaultError);
  const [emailCreateErrorMessages, setEmailCreateErrorMessages] =
    useState(defaultError);

  useEffect(() => {
    // Effect to handle changes in selected suite selection
  }, [selectedUserSelection]);

  useEffect(() => {
    setProfileDetails(userProfile?.user || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (userList?.length) {
      const updatedUserList = userList?.length
        ? [...userList].map((item, index) => {
            return { ...item, id: index + 1, isActive: true };
          })
        : [];
      setRows(updatedUserList);
    } else {
      setRows([]);
    }
  }, [userList]);

  const handleDeleteUserClick = (params) => {
    setSelectedUserDetails(params);
    setOpen(true);
  };
  const handleEditUserClick = (params, type) => {
    setSelectedUserDetails(type !== "Create" ? params : null);
    setFNameErrorMessages(defaultError);
    setLNameErrorMessages(defaultError);
    setCreateUserDetails(defaultCreateUserDetails);
    resetCreateUserErrors();
    setUserModelType(type);
    setOpenEditUser(true);
  };
  const resetCreateUserErrors = () => {
    setFNameCreateErrorMessages(defaultError);
    setLNameCreateErrorMessages(defaultError);
    setEmailCreateErrorMessages(defaultError);
  };

  const columns = [
    {
      field: "userDisplayName",
      headerName: "User Name",
      width: 200,
      flex: 1,
    },
    {
      field: "userEmailId",
      headerName: "User Email Id",
      width: 350,
      flex: 1,
    },
    {
      field: "userRole",
      headerName: "User Role",
      renderCell: renderUserRole,
      width: 200,
      maxWidth: 200,
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => handleDeleteUserClick(params.row)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="edit"
              size="small"
              onClick={() => handleEditUserClick(params?.row, "Edit")}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];
  const handleCellClick = async ({ row }) => {
    setSelectedUserSelection(row.id);
  };

  const resetUserDetails = () => {
    setOpen(false);
    setSelectedUserDetails(null);
  };

  const handleClose = resetUserDetails;

  const handleDeleteUser = async () => {
    if (selectedUserDetails) {
      dispatch(setLoadingState(true));
      const response = await deleteUser(selectedUserDetails.userId);
      dispatch(setLoadingState(false));

      if (response) {
        getAllUserDetails(); // Refresh User List
        resetUserDetails();
      }
    }
  };

  const handleEditUserClose = () => {
    setOpenEditUser(false);
    resetUserDetails();
    setCreateUserDetails(defaultCreateUserDetails);
    resetCreateUserErrors();
  };

  const validateField = (field, errorMessage, setter) => {
    if (!field || field.trim() === "") {
      setter({ isError: true, message: errorMessage });
      return false;
    }
    return true;
  };

  const validateEmail = (field, errorMessage, setter) => {
    if (!field || field.trim() === "") {
      setter({ isError: true, message: errorMessage });
      return false;
    }
    return true;
  };

  const validateUserProfile = () => {
    const isFNameValid = validateField(
      profileEditDetails?.userFirstName,
      fNameErrMsg,
      setFNameErrorMessages
    );
    const isLNameValid = validateField(
      profileEditDetails?.userLastName,
      sNameErrMsg,
      setLNameErrorMessages
    );
    return isFNameValid && isLNameValid;
  };

  const handleUserUpdate = async () => {
    if (validateUserProfile()) {
      const { userId } = profileEditDetails;
      const requestPayload = {
        isActive: profileEditDetails.isActive || true,
        userDisplayName: `${profileEditDetails.userFirstName?.trim()} ${profileEditDetails.userLastName?.trim()}`,
        userEmailId: profileEditDetails.userEmailId || "",
        userFirstName: profileEditDetails.userFirstName?.trim() || "",
        userLastName: profileEditDetails.userLastName?.trim() || "",
        userRole: profileEditDetails.userRole || "",
      };

      dispatch(setLoadingState(true));
      const response =
        userId === profileDetails?.userId
          ? await updateSelfUserDetails(userId, requestPayload)
          : await updateUserDetails(userId, requestPayload);

      if (response) {
        getAllUserDetails(); // Refresh User List
        handleEditUserClose();
      }

      dispatch(setLoadingState(false));
    }
  };

  const handleChange = (event, nextView) => setView(nextView);
  const handleRoleChange = (event) =>
    setProfileEditDetails((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));

  const handleProfileFieldsChangeClick = (event, fieldName) => {
    const { value } = event.target;
    setFNameErrorMessages(defaultError);
    setLNameErrorMessages(defaultError);
    setProfileEditDetails((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleEnableScreenshot = (event, fieldName) => {
    const { checked } = event.target;
    setProfileEditDetails((prev) => ({ ...prev, [fieldName]: checked }));
  };

  const handleSearchClick = () => {
    try {
      if (searchText?.trim()) {
        const filterData = rows?.filter(
          (rec) =>
            rec?.userFirstName
              ?.toLowerCase()
              .includes(searchText.toLowerCase()) ||
            rec?.userLastName?.toLowerCase().includes(searchText.toLowerCase())
        );
        setRows(filterData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClearSearch = () => {
    const updatedUserList =
      userList?.map((item, index) => ({
        ...item,
        id: index + 1,
        isActive: true,
      })) || [];
    setRows(updatedUserList);
    setSearchText("");
  };

  const handleCreateUserFieldsChangeClick = (event, fieldName) => {
    const { value } = event.target;
    resetCreateUserErrors();
    setCreateUserDetails((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleCreateUserRoleChange = (event) => {
    setCreateUserDetails((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const validateNewUserProfile = () => {
    const isFNameValid = validateField(
      createUserDetails?.userFirstName,
      fNameErrMsg,
      setFNameCreateErrorMessages
    );
    const isLNameValid = validateField(
      createUserDetails?.userLastName,
      sNameErrMsg,
      setLNameCreateErrorMessages
    );
    const isEmailValid = validateEmail(
      createUserDetails?.userEmailId,
      emailErrMsg,
      setEmailCreateErrorMessages
    );

    return isFNameValid && isLNameValid && isEmailValid;
  };
  const handleCreateUser = async () => {
    try {
      if (validateNewUserProfile()) {
        console.log(createUserDetails);
        const requestPayload = {
          isActive: true,
          userDisplayName: `${createUserDetails.userFirstName?.trim()} ${createUserDetails.userLastName?.trim()}`,
          userEmailId: createUserDetails.userEmailId || "",
          userFirstName: createUserDetails.userFirstName?.trim() || "",
          userLastName: createUserDetails.userLastName?.trim() || "",
          userRole: createUserDetails.userRole || "",
        };

        dispatch(setLoadingState(true));
        const response = await createNewUserDetails(requestPayload);

        if (response) {
          getAllUserDetails();

          setOpenEditUser(false);
          setCreateUserDetails(defaultCreateUserDetails);
          resetCreateUserErrors();
          setUserModelType(null);
        }

        dispatch(setLoadingState(false));
      }
    } catch (error) {
      console.error(error);
      dispatch(setLoadingState(false));
    }
  };

  return (
    <>
      <Row>
        <Col className="col-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="text-muted" style={{ padding: 20 }}>
                <div className="col-md-12 col-sm-12">
                  <Typography>Manage User(s)</Typography>
                </div>
                <div>
                  <Row>
                    <div className="col-md-8">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div style={{ width: "40%", marginRight: "10px" }}>
                          <TextField
                            label=""
                            fullWidth
                            className="search-ai-insights"
                            style={{ height: 35 }}
                            placeholder="Search for person"
                            value={searchText}
                            onChange={(event) => {
                              const { value } = event?.target || {};
                              setSearchText(value);
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                              endAdornment: searchText ? (
                                <InputAdornment
                                  position="end"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleClearSearch}
                                >
                                  <CloseIcon />
                                </InputAdornment>
                              ) : (
                                <></>
                              ),
                            }}
                            variant="outlined"
                          />
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSearchClick}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div style={{ marginRight: "5px" }}>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => handleEditUserClick(null, "Create")}
                          >
                            Create User
                          </button>
                        </div>
                        <ToggleButtonGroup
                          orientation="horizontal"
                          value={view}
                          exclusive
                          onChange={handleChange}
                        >
                          <ToggleButton
                            value="thumbnail"
                            aria-label="thumbnail"
                          >
                            <ViewModuleIcon />
                          </ToggleButton>
                          <ToggleButton value="list" aria-label="list">
                            <ViewListIcon />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </div>
                  </Row>
                </div>
                {/* Thumbnail Form */}
                {view === "thumbnail" ? (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {rows?.length ? (
                      rows?.map((user) => {
                        return (
                          <div
                            style={{
                              height: "200px",
                              width: "210px",
                              borderRadius: "10px",
                              border: "1px solid lightgrey",
                              margin: "10px",
                              padding: "10px",
                            }}
                            key={`user_key_${user?.userId}`}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              <Stack direction="row" spacing={1}>
                                {user?.userId !== profileDetails?.userId ? (
                                  <IconButton
                                    aria-label="delete"
                                    size="small"
                                    onClick={() => handleDeleteUserClick(user)}
                                  >
                                    <DeleteIcon fontSize="inherit" />
                                  </IconButton>
                                ) : (
                                  <></>
                                )}
                                <IconButton
                                  aria-label="edit"
                                  size="small"
                                  onClick={() =>
                                    handleEditUserClick(user, "Edit")
                                  }
                                >
                                  <EditIcon fontSize="inherit" />
                                </IconButton>
                              </Stack>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Avatar sx={{ height: 60, width: 60 }}>
                                <PersonIcon />
                              </Avatar>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "20px",
                              }}
                            >
                              {user?.userDisplayName?.length > 25 ? (
                                <Tooltip title={user?.userDisplayName}>
                                  <Typography variant="body1">
                                    {user?.userDisplayName
                                      ? `${user.userDisplayName?.substring(
                                          0,
                                          22
                                        )}...`
                                      : ""}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography variant="body1">
                                  {user?.userDisplayName || ""}
                                </Typography>
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography variant="body2">
                                {renderUserRole({
                                  row: {
                                    userRole: user?.userRole,
                                  },
                                })}
                              </Typography>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {/* TABLE Form */}
                {view === "list" ? (
                  <div
                    style={{
                      background: "#ffffff",
                      overflow: "auto",
                      height: "445px",
                      width: "100%",
                      flexGrow: 1,
                    }}
                  >
                    <StyledDataGrid
                      initialState={{}}
                      rows={rows}
                      columns={columns}
                      rowHeight={rowHeight}
                      columnHeaderHeight={headerHeight}
                      onCellClick={handleCellClick}
                      hideFooter
                      hideFooterPagination
                      hideFooterSelectedRowCount
                      slots={{ toolbar: CustomToolbar }}
                      onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                      }}
                      rowSelectionModel={rowSelectionModel}
                      componentsProps={{
                        basePopper: {
                          sx: {
                            "& .MuiTypography-root": { fontSize: "12px" },
                          },
                        },
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure, you want to delete user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDeleteUser} autoFocus variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditUser}
        onClose={handleEditUserClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>{`${
          userModelType === "Create" ? "Create" : "Edit"
        } User`}</DialogTitle>
        {userModelType === "Create" ? (
          <DialogContent>
            <CreateUser
              roleDetails={roleDetails}
              createUserDetails={createUserDetails}
              handleCreateUserFieldsChangeClick={
                handleCreateUserFieldsChangeClick
              }
              fNameCreateErrorMessages={fNameCreateErrorMessages}
              lNameCreateErrorMessages={lNameCreateErrorMessages}
              emailCreateErrorMessages={emailCreateErrorMessages}
              handleCreateUserRoleChange={handleCreateUserRoleChange}
            />
          </DialogContent>
        ) : (
          <DialogContent>
            <EditUserDetails
              profileDetails={profileDetails}
              roleDetails={roleDetails}
              editUserDetails={selectedUserDetails}
              handleRoleChange={handleRoleChange}
              profileEditDetails={profileEditDetails}
              setProfileEditDetails={setProfileEditDetails}
              handleProfileFieldsChangeClick={handleProfileFieldsChangeClick}
              handleEnableScreenshot={handleEnableScreenshot}
              fNameErrorMessages={fNameErrorMessages}
              lNameErrorMessages={lNameErrorMessages}
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleEditUserClose}>Close</Button>
          <Button
            onClick={
              userModelType === "Create" ? handleCreateUser : handleUserUpdate
            }
          >
            {userModelType === "Create" ? "Save" : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

UserList.propTypes = {
  userList: PropTypes.any,
  getAllUserDetails: PropTypes.any,
};
