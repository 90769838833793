import { useState } from "react";

const useProfile = () => {
  const getLoggedInUser = () => {
    const user = localStorage.getItem("authUser");
    if (!user) {
      return null;
    } else {
      return JSON.parse(user);
    }
  };

  const userProfileSession = getLoggedInUser();

  const [loading] = useState(!userProfileSession);
  const [userProfile] = useState(userProfileSession || null);

  return { userProfile, loading };
};

export { useProfile };
