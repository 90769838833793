import React from "react";
import PropTypes from "prop-types";
import { Navigate, Route } from "react-router-dom";
import { useProfile } from "../Hooks/UserHooks";

const AuthProtected = (props) => {
  const { userProfile, loading } = useProfile();

  if (!userProfile && loading) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };

AuthProtected.propTypes = {
  location: PropTypes.any,
  children: PropTypes.any,
};
AccessRoute.propTypes = {
  component: PropTypes.any,
};
