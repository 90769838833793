import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Container, Col } from "reactstrap";
import {
  menuSelectionChange,
  setLoadingState,
  showError,
} from "../../store/actions";
import Breadcrumbs from "../AHeaderFooterSections/HeaderComponents/Breadcrumb";
import {
  announcementCreate,
  announcementDelete,
  announcementFetch,
  announcementUpdate,
} from "../../store/dashboard/orchestractor";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { StyledDataGrid } from "../generalFunctions";
import { AnnouncementDetails } from "./AnnouncementDetails";

const CustomToolbar = () => {
  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ padding: "0 5px 0 5px", borderBottom: " 1px solid #f0f0f0" }}
    >
      <div>
        <h6 style={{ marginBottom: "5px", fontSize: "11px" }}>
          Announcement Listing
        </h6>
      </div>
    </div>
  );
};

export const ManageAnnouncement = () => {
  const dispatch = useDispatch();

  const headerHeight = 28;
  const rowHeight = 26;
  const defaultError = {
    isError: false,
    message: null,
  };

  const [rows, setRows] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [openAnnouncementModal, setOpenAnnouncementModal] = useState({
    open: false,
    details: null,
    type: "Add",
  });
  const [selectedAnnouncementDetails, setSelectedAnnouncementDetails] =
    useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [announcementEditDetails, setAnnouncementEditDetails] = useState({
    id: 0,
    subject: "",
    description: "",
    detailedDescription: "",
  });
  const [subjectErrorMessages, setSubjectErrorMessages] =
    useState(defaultError);
  const [descriptionErrorMessages, setDescriptionErrorMessages] =
    useState(defaultError);
  const [
    detailedDescriptionErrorMessages,
    setDetailedDescriptionErrorMessages,
  ] = useState(defaultError);

  useEffect(() => {
    handleMenuClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAllAnnouncementDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenuClick = () => {
    dispatch(menuSelectionChange(8));
  };
  const getAllAnnouncementDetails = async () => {
    try {
      dispatch(setLoadingState(true));
      const requestParam = `?per_page=9999`;
      const data = await announcementFetch(requestParam);
      setRows(data);
      dispatch(setLoadingState(false));
    } catch (error) {
      console.error("Error fetching announcementsData:", error);
      setRows([]);
      dispatch(setLoadingState(false));
    }
  };
  const columns = [
    {
      field: "subject",
      headerName: "Subject",
      width: 200,
      flex: 1,
      renderCell: (params) => {
        const subject = params.row.subject;
        return <div dangerouslySetInnerHTML={{ __html: subject }} />;
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 350,
      flex: 1,
      renderCell: (params) => {
        const description = params.row.description;
        return <div dangerouslySetInnerHTML={{ __html: description }} />;
      },
    },
    {
      field: "published_date",
      headerName: "Published Date",
      width: 160,
      type: "date",
      valueFormatter: (params) => {
        const date = params.value ? new Date(params.value) : "";
        const options = {
          day: "2-digit",
          month: "short",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const formattedDateTime = date
          ? date.toLocaleDateString("en-UK", options)
          : "";
        return params.value ? formattedDateTime : "";
      },
    },
    {
      field: "updated_at",
      headerName: "Updated Date ",
      width: 160,
      type: "date",
      valueFormatter: (params) => {
        const date = params.value ? new Date(params.value) : "";
        const options = {
          day: "2-digit",
          month: "short",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const formattedDateTime = date
          ? date.toLocaleDateString("en-UK", options)
          : "";
        return params.value ? formattedDateTime : "";
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => handleDeleteUserClick(params?.row)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="edit"
              size="small"
              onClick={() => handleEditUserClick(params?.row)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];
  const handleCellClick = async (params) => {
    const suiteSelection = params.row.id;
    console.error(suiteSelection);
  };

  const handleDeleteUserClick = (params) => {
    setSelectedAnnouncementDetails(params);
    setOpenDeleteConfirmation(true);
  };
  const handleEditUserClick = (params) => {
    setOpenAnnouncementModal({
      open: true,
      details: params,
      type: "Edit",
    });
    setAnnouncementEditDetails({
      id: params?.id || 0,
      subject: params?.subject || "",
      description: params?.description || "",
      detailedDescription: params?.detailedDescription || "",
    });
  };
  const handleDeleteAnnouncementConfirmationClose = () => {
    setOpenDeleteConfirmation(false);
  };
  const handleDeleteAnnouncementConfirmationClick = async () => {
    try {
      const { id } = selectedAnnouncementDetails || {};
      if (id) {
        dispatch(setLoadingState(true));
        const response = await announcementDelete(id);
        if (response) {
          const resp = await response.json();
          setSelectedAnnouncementDetails(null);
          setOpenDeleteConfirmation(false);
          dispatch(setLoadingState(false));

          const message = resp?.message || "Announcement deleted successfully";
          dispatch(showError(true, message, "info"));
          getAllAnnouncementDetails();
        } else {
          const message = "Error with announcement deletion";
          dispatch(showError(true, message, "error"));
          dispatch(setLoadingState(false));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleCreateAnnouncement = (event) => {
    try {
      setOpenAnnouncementModal({
        open: true,
        details: null,
        type: "Add",
      });
      setAnnouncementEditDetails({
        id: 0,
        subject: "",
        description: "",
        detailedDescription: "",
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleAnnouncementFieldsChangeClick = (event, fieldName) => {
    try {
      const { value } = event.target;
      setAnnouncementEditDetails({
        ...announcementEditDetails,
        [fieldName]: value,
      });
      setSubjectErrorMessages(defaultError);
      setDescriptionErrorMessages(defaultError);
      setDetailedDescriptionErrorMessages(defaultError);
    } catch (error) {
      console.error(error);
    }
  };
  const handleAnnouncementModalClose = () => {
    setOpenAnnouncementModal({ open: false, details: null, type: "Add" });
    setAnnouncementEditDetails({
      id: 0,
      subject: "",
      description: "",
      detailedDescription: "",
    });
  };
  const handleAnnouncementModalSave = async () => {
    try {
      const { id, subject, description, detailedDescription } =
        announcementEditDetails || {};

      // Validate input fields
      if (!subject?.trim()) {
        setSubjectErrorMessages({
          isError: true,
          message: "Subject is required",
        });
        return;
      }

      if (!description?.trim()) {
        setDescriptionErrorMessages({
          isError: true,
          message: "Description is required",
        });
        return;
      }

      if (!detailedDescription?.trim()) {
        setDetailedDescriptionErrorMessages({
          isError: true,
          message: "Detailed Description is required",
        });
        return;
      }

      dispatch(setLoadingState(true));

      const requestParam = {
        announcement: {
          description,
          subject,
          detailedDescription,
        },
      };

      // Call the appropriate API based on the modal type
      const response =
        openAnnouncementModal?.type === "Edit" && id
          ? await announcementUpdate(requestParam, id)
          : await announcementCreate(requestParam);

      // Handle response
      if (response) {
        handleAnnouncementModalClose();
        const message =
          response.message ||
          `Announcement ${
            openAnnouncementModal?.type === "Edit" ? "updated" : "added"
          } successfully`;
        dispatch(showError(true, message, "info"));
        getAllAnnouncementDetails();
      } else {
        throw new Error(
          `Error occurred while ${
            openAnnouncementModal?.type === "Edit" ? "updating" : "creating"
          } announcement`
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(setLoadingState(false));
      const message = `Error occurred while ${
        openAnnouncementModal?.type === "Edit" ? "updating" : "creating"
      } announcement`;
      dispatch(showError(true, message, "error"));
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  return (
    <React.Fragment>
      <div className="page-content" style={{ marginTop: "30px" }}>
        <Container fluid>
          <Breadcrumbs
            title="Home"
            titleRoute={"/manage-users"}
            breadcrumbItem="Manage Announcement"
            style={{ backgroundColor: "#f8f9fa" }}
          />
          <Row>
            <Row>
              <Col className="col-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="col-md-12 col-sm-12">
                      <div
                        style={{
                          padding: 10,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>Announcement List</Typography>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={handleCreateAnnouncement}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#ffffff",
                        overflow: "auto",
                        height: "445px",
                        width: "100%",
                        flexGrow: 1,
                        padding: 10,
                      }}
                    >
                      <StyledDataGrid
                        initialState={{}}
                        rows={rows}
                        columns={columns}
                        rowHeight={rowHeight}
                        columnHeaderHeight={headerHeight}
                        onCellClick={handleCellClick}
                        hideFooter
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        slots={{ toolbar: CustomToolbar }}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                          setRowSelectionModel(newRowSelectionModel);
                        }}
                        rowSelectionModel={rowSelectionModel}
                        componentsProps={{
                          basePopper: {
                            sx: {
                              "& .MuiTypography-root": { fontSize: "12px" },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Row>
        </Container>
      </div>

      {openDeleteConfirmation ? (
        <Dialog
          open={openDeleteConfirmation}
          onClose={handleDeleteAnnouncementConfirmationClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure, you want to delete announcement?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteAnnouncementConfirmationClose}>
              Cancel
            </Button>
            <Button
              onClick={handleDeleteAnnouncementConfirmationClick}
              autoFocus
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
      {openAnnouncementModal?.open ? (
        <Dialog
          open={openAnnouncementModal?.open}
          onClose={handleAnnouncementModalClose}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle>{`${openAnnouncementModal?.type} Announcement`}</DialogTitle>
          <DialogContent>
            <Row>
              <AnnouncementDetails
                subjectErrorMessages={subjectErrorMessages}
                descriptionErrorMessages={descriptionErrorMessages}
                detailedDescriptionErrorMessages={
                  detailedDescriptionErrorMessages
                }
                announcementEditDetails={announcementEditDetails}
                handleAnnouncementFieldsChangeClick={
                  handleAnnouncementFieldsChangeClick
                }
              />
            </Row>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAnnouncementModalClose}>Close</Button>
            <Button onClick={handleAnnouncementModalSave}>
              {openAnnouncementModal?.type === "Add" ? "Save" : "Update"}
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};
