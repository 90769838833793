import { call, fork, put, takeEvery } from "redux-saga/effects";
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
//Include Both Helper File with needed methods
import { loginApi } from "../../dashboard/orchestractor";
import DashboardSaga from "../../dashboard/saga";
import { setLoadingState } from "../../actions";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      loginApi,
      user.email,
      user.password,
      user?.encrypted || false
    );
    if (response?.error) {
      callAPICallHandler();
    } else if (response?.user) {
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(setLoadingState(false));
      yield put(loginSuccess(response));
      if (response?.user?.adminRole) {
        history("/manage-users");
      } else {
        yield fork(DashboardSaga);
        history("/home");
      }
    } else {
      callAPICallHandler();
    }
  } catch (error) {
    callAPICallHandler();
  }
}

function* callAPICallHandler() {
  yield put(setLoadingState(false));
  yield put(apiError("Login Failed"));
}

function* logoutUser() {
  try {
    localStorage.removeItem("authUser");

    yield put(logoutUserSuccess(LOGOUT_USER, true));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
