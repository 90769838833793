import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import {
  currentSuiteNameSelection,
  fetchSuiteInstanceListing,
  setSuiteSelection,
  setWorkboardSuiteId,
} from "../../../store/actions";
import DashdountSuite from "../Charts/DashdountSuite";
import { sortSuites } from "../../../config/util";

const smallSelect = {
  fontSize: "12px",
  lineHeight: "15px",
  width: "100%",
  borderRadius: "2px",
  padding: "3px",
  marginBottom: "3px",
};

const paddingPassed = {
  paddingTop: "0px",
  paddingRight: "0px",
  paddingBottom: "0px",
  paddingLeft: "0px",
};
const reducefontsize = { fontSize: "10px" };

const PassedFormat = {
  fontSize: "11px",
  paddingBottom: "0px",
  marginBottom: "0px",
  paddingTop: "0px",
  paddingLeft: "0px",
  paddingRight: "0px",
};

const paddingTopBottom = {
  paddingTop: "10px",
  paddingBottom: "5px",
  paddingLeft: "5px",
};
const myBorder = {
  borderRight: "1.4px solid rgb(223, 223, 223)",
  paddingRight: "5px",
  textAlign: "center",
};
const borderStyles = {
  border: "solid",
  borderWidth: "1px",
  borderColor: "#ced4da",
  marginBottom: "5px",
};

const WbsuiteCard = ({
  suites,
  currentNameSuiteID,
  currentSuiteNameSelection,
  fetchSuiteInstanceListing,
  setSuiteSelection,
  workboardSelectedSuiteId,

  testSuiteList,
  
}) => {
  const [selectedSuite, setSelectedSuite] = useState(null);
  const [totalCountForTestSuite, setTotalCountForTestSuite] = useState({
    failedCount: 0,
    passedCount: 0,
    undefinedCount: 0,
    warningCount: 0,
    runningCount: 0,
    cancelledCount: 0,
  });
  const dispatch = useDispatch();

  const [workboardSelectedSuiteIdData,setWorkboardSelectedSuiteIdData] = useState(workboardSelectedSuiteId[0])

console.log('workboardSelectedSuiteId',workboardSelectedSuiteId)
  console.log('wwwwwwwww',suites,'\n',
    "currentNameSuiteID",currentNameSuiteID,'\n',
    "currentSuiteNameSelection",currentSuiteNameSelection,'\n',
    "fetchSuiteInstanceListing",fetchSuiteInstanceListing,'\n',
    "setSuiteSelection",setSuiteSelection,)
    console.log('suiteList123',testSuiteList)
  useEffect(() => {
    if (suites.length > 0) {
      const selectedSuiteList = suites.find(
        (suite) => suite.suiteRISummary.suiteId === parseInt(workboardSelectedSuiteId[0])
      );
      setSelectedSuite(selectedSuiteList || null);
      console.log('111111111',selectedSuiteList)
      setWorkboardSelectedSuiteIdData(workboardSelectedSuiteId[0])
    }
  }, [suites, workboardSelectedSuiteId]);

  useEffect(() => {

    console.log('suiteRIDetailssuiteRIDetails',testSuiteList?.suRIList)
    if (testSuiteList?.suRIList?.length) {
      
      const defaultSummary = {
        failedCount: 0,
        passedCount: 0,
        undefinedCount: 0,
        warningCount: 0,
        runningCount: 0,
        cancelledCount: 0,
      };
      
      // Summing up all values from summary objects
      const finalSummary = testSuiteList?.suRIList.reduce((acc, item) => {
        Object.keys(item.summary).forEach((key) => {
          acc[key] = (acc[key] || 0) + item.summary[key];
        });
        return acc;
      }, { ...defaultSummary });
      
      console.log(finalSummary);
      setTotalCountForTestSuite(finalSummary)
      // setSumOfAllModuleData(finalSummary)

      // Result in the required format
      console.log('sumOfAllModuleData', finalSummary);
    }
  }, [testSuiteList]);

  const handleSuiteChange = (event) => {
    const selectedSuiteId = event.target.value;
    setWorkboardSelectedSuiteIdData(event.target.value)
    const selectedSuite = suites.find(
      (suite) => suite.suiteRISummary.suiteId === parseInt(selectedSuiteId)
    );
    dispatch(setWorkboardSuiteId([ Number(event.target.value)]))

    console.log('1111111112',selectedSuite)
    setSelectedSuite(selectedSuite);
    setSuiteSelection(selectedSuite.suiteRIDetails[0].suiteRIId);
    currentSuiteNameSelection(parseInt(selectedSuiteId));
    fetchSuiteInstanceListing(selectedSuite.suiteRIDetails);
  };

  const passedCount =
    selectedSuite?.suiteRISummary?.suiteRIStatusSummary?.passedCount || 0;
  const failedCount =
    selectedSuite?.suiteRISummary?.suiteRIStatusSummary?.failedCount || 0;
  const warningCount =
    selectedSuite?.suiteRISummary?.suiteRIStatusSummary?.warningCount || 0;
  const undefinedCount =
    selectedSuite?.suiteRISummary?.suiteRIStatusSummary?.undefinedCount || 0;
  const suiteScenarioTotal =
    selectedSuite?.suiteRISummary?.suiteScenarioTotal || 0;

  return (
    <Col xl={3} sm={6} style={{ paddingRight: "0px" }}>
      <Card style={borderStyles}>
        <CardBody style={paddingTopBottom}>
          <h6 style={{ marginBottom: "8px", fontSize: "11px" }}>Suites</h6>
          <Row>
            <Col xl={12}>
              <select
                className="form-select"
                style={smallSelect}
                id="inlineFormSelectPref"
                onChange={handleSuiteChange}
                value={workboardSelectedSuiteIdData}
              >
                {suites?.length ? (
                  sortSuites(suites).map((suite) => (
                    <option
                      key={suite.suiteRISummary.suiteId}
                      value={suite.suiteRISummary.suiteId}
                    >
                      {suite.suiteRISummary.suiteName}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </select>
            </Col>
          </Row>
          <Row>
            <Col className="col-4" style={myBorder}>
              <Row className="mb-2 row"></Row>
              <p
                className="mb-1"
                style={{ fontWeight: "bold", paddingTop: "10px" }}
              >
                {totalCountForTestSuite?.passedCount+totalCountForTestSuite?.failedCount+totalCountForTestSuite?.warningCount+totalCountForTestSuite?.undefinedCount}
              </p>

              <p style={reducefontsize}> # Scenario Run</p>
            </Col>

            <Col className="col-4" style={{ paddingLeft: "4px" }}>
              <div id="radialchartsuite">
                <DashdountSuite
                  passedCount={totalCountForTestSuite?.passedCount}
                  failedCount={totalCountForTestSuite?.failedCount}
                  warningCount={totalCountForTestSuite?.warningCount}
                  undefinedCount={totalCountForTestSuite?.undefinedCount}
                />
              </div>
            </Col>

            <Col className="col-4" style={{ marginTop: "5px" }}>
              <Row>
                <Col
                  xl={12}
                  style={paddingPassed}
                  className="d-flex align-items-center justify-content-left text-left"
                >
                  <p style={PassedFormat}>{totalCountForTestSuite?.passedCount} Passed</p>
                </Col>
              </Row>
              <Row>
                <Col
                  xl={12}
                  style={paddingPassed}
                  className="d-flex align-items-center justify-content-left text-left"
                >
                  <p style={PassedFormat}>{totalCountForTestSuite?.failedCount} Failed</p>
                </Col>
              </Row>
              <Row>
                <Col
                  xl={12}
                  style={paddingPassed}
                  className="d-flex align-items-center justify-content-left text-left"
                >
                  <p style={PassedFormat}>{totalCountForTestSuite?.warningCount} Warning</p>
                </Col>
              </Row>
              <Row>
                <Col
                  xl={12}
                  style={paddingPassed}
                  className="d-flex align-items-center justify-content-left text-left"
                >
                  <p style={PassedFormat}>{totalCountForTestSuite?.undefinedCount} Undefined</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  suites: state.dashboardData.suiteList || [],
  currentNameSuiteID: state.dashboardData.suiteNameSelectionID || 0,
  workboardSelectedSuiteId:state.dashboardData.selectedSuiteIds,
  testSuiteList:state.dashboardData.testSuiteList,
});

const mapDispatchToProps = {
  currentSuiteNameSelection,
  // workboardSelectedSuiteIdSelection,
  
  fetchSuiteInstanceListing,
  setSuiteSelection,
};

export default connect(mapStateToProps, mapDispatchToProps)(WbsuiteCard);

WbsuiteCard.propTypes = {
  suites: PropTypes.any,
  currentNameSuiteID: PropTypes.any,
  currentSuiteNameSelection: PropTypes.any,
  fetchSuiteInstanceListing: PropTypes.any,
  setSuiteSelection: PropTypes.any,
  workboardSelectedSuiteId:PropTypes.any,
  testSuiteList:PropTypes.any
  // workboardSelectedSuiteIdSelection:PropTypes.any
};
