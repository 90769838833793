import { Comparison } from "./Comparison";
import { Insights } from "./Insights";
import Dashboard from "./Dashboard";
import Home from "./LandingPage";
import TestRunArea from "./TestManager";
import WorkBoard from "./WorkBoard";
import { Navigate } from "react-router-dom";
import { ManagerUsers } from "./ManagerUsers";
import { ManageAnnouncement } from "./ManageAnnouncement";
import { QuickLinks } from "./QuickLinks";

// Access Details and assigned to each role accordingly

const HomeMenu = 1;
const DashboardMenu = 2;
const WorkBoardMenu = 3;
const TestRunMenu = 4;
const comparisonMenu = 5;
const insightsMenu = 6;
const manageUserMenu = 7;
const announcementMenu = 8;
const quickLinksMenu = 9;

// Routes for different Roles

const defaultPage = {
  path: "/",
  exact: true,
  component: <Navigate to="/home" />,
};
const homePage = { path: "/home", component: <Home /> };
const dashBoardPage = { path: "/dashboard", component: <Dashboard /> };
const workBoardPage = { path: "/workboard", component: <WorkBoard /> };
const testManagerPage = { path: "/testmanager", component: <TestRunArea /> };
const comparisonPage = { path: "/comparison", component: <Comparison /> };
const insightsPage = { path: "/insights", component: <Insights /> };
const manageUser = { path: "/manage-users", component: <ManagerUsers /> };
const announcementUser = {
  path: "/announcement",
  component: <ManageAnnouncement />,
};
const quickLinks = {
  path: "/quick-links",
  component: <QuickLinks />,
};

export const commonRoutes = [homePage, defaultPage];

//-------------------------------Change Below for Any Access Change--------------------------

export const adminRoleMenuItems = [
  manageUserMenu,
  announcementMenu,
  quickLinksMenu,
];

export const testerRoleMenuItems = [
  HomeMenu,
  DashboardMenu,
  WorkBoardMenu,
  TestRunMenu,
  comparisonMenu,
  insightsMenu,
];

export const viewerRoleMenuItems = [
  HomeMenu,
  DashboardMenu,
  WorkBoardMenu,
  comparisonMenu,
  insightsMenu,
];

export const privilegedRoleMenuItems = [
  HomeMenu,
  DashboardMenu,
  WorkBoardMenu,
  TestRunMenu,
  comparisonMenu,
  insightsMenu,
];

export const adminRoutes = [manageUser, announcementUser, quickLinks];

export const testerRoutes = [
  dashBoardPage,
  workBoardPage,
  testManagerPage,
  comparisonPage,
  insightsPage,
];
export const privilegedRoutes = [
  dashBoardPage,
  workBoardPage,
  testManagerPage,
  comparisonPage,
  insightsPage,
];

export const viewerRoutes = [
  dashBoardPage,
  workBoardPage,
  comparisonPage,
  insightsPage,
];
