import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { MyTextField } from "../../components/TextField";
import { FormControl, Select, Typography } from "@mui/material";

export const CreateUser = (props) => {
  const {
    roleDetails,
    createUserDetails,
    handleCreateUserFieldsChangeClick,
    fNameCreateErrorMessages,
    lNameCreateErrorMessages,
    emailCreateErrorMessages,
    handleCreateUserRoleChange,
  } = props;

  return (
    <Row>
      <Col className="col-12">
        <div className="card">
          <div className="card-body p-0">
            <div
              className="text-muted"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <Row>
                <div className="col-md-6 col-sm-6">
                  <Row className="mb-2 row">
                    <Col className="col-3">
                      <label
                        className="small-select"
                        htmlFor="create-user_fName"
                      >
                        First Name <i style={{ color: "red" }}>*</i>
                      </label>
                    </Col>
                    <Col className="col-9">
                      <MyTextField
                        ariaLabelledby="create-user_fName"
                        value={createUserDetails?.userFirstName || ""}
                        handleChange={(event) =>
                          handleCreateUserFieldsChangeClick(
                            event,
                            "userFirstName"
                          )
                        }
                        type={"text"}
                        className={""}
                        placeholder={""}
                        pattern={""}
                        inputMode={"text"}
                      />
                      {fNameCreateErrorMessages?.isError ? (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {fNameCreateErrorMessages?.message}
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-2 row">
                    <Col className="col-3">
                      <label
                        className="small-select"
                        htmlFor="create-user_lName"
                      >
                        Last Name <i style={{ color: "red" }}>*</i>
                      </label>
                    </Col>
                    <Col className="col-9">
                      <MyTextField
                        ariaLabelledby="create-user_lName"
                        value={createUserDetails?.userLastName || ""}
                        handleChange={(event) =>
                          handleCreateUserFieldsChangeClick(
                            event,
                            "userLastName"
                          )
                        }
                        type={"text"}
                        className={""}
                        placeholder={""}
                        pattern={""}
                        inputMode={"text"}
                      />
                      {lNameCreateErrorMessages?.isError ? (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {lNameCreateErrorMessages?.message}
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="col-md-6 col-sm-6">
                  <Row className="mb-2 row">
                    <Col className="col-3">
                      <label
                        className="small-select"
                        htmlFor="create-user_email"
                      >
                        Email <i style={{ color: "red" }}>*</i>
                      </label>
                    </Col>
                    <Col className="col-9">
                      <MyTextField
                        ariaLabelledby="create-user_email"
                        value={createUserDetails?.userEmailId || ""}
                        handleChange={(event) =>
                          handleCreateUserFieldsChangeClick(
                            event,
                            "userEmailId"
                          )
                        }
                        type={"email"}
                        className={""}
                        placeholder={""}
                        pattern={
                          "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
                        }
                        inputMode={"email"}
                      />
                      {emailCreateErrorMessages?.isError ? (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {emailCreateErrorMessages?.message}
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-2 row">
                    <Col className="col-3">
                      <label
                        className="small-select"
                        htmlFor="create-user-role"
                      >
                        Role
                      </label>
                    </Col>
                    <Col className="col-9">
                      <FormControl
                        sx={{
                          minWidth: "100%",
                          minHeight: 20,
                        }}
                      >
                        <Select
                          native
                          ariaLabelledby="create-user-role"
                          defaultValue=""
                          value={createUserDetails?.userRole || null}
                          name="userRole"
                          onChange={handleCreateUserRoleChange}
                          style={{ height: 25 }}
                          className="font-size-12"
                        >
                          {roleDetails?.length &&
                            roleDetails.map((role, index) => {
                              return (
                                <option
                                  className="font-size-12"
                                  key={`${role.value}_${index}`}
                                  value={role.value || 0}
                                >
                                  {role?.name || ""}
                                </option>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

CreateUser.propTypes = {
  roleDetails: PropTypes.any,
  createUserDetails: PropTypes.any,
  handleCreateUserFieldsChangeClick: PropTypes.any,
  fNameCreateErrorMessages: PropTypes.any,
  lNameCreateErrorMessages: PropTypes.any,
  emailCreateErrorMessages: PropTypes.any,
  handleCreateUserRoleChange: PropTypes.any,
};
