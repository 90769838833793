import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

export const BarChart = ({ moduleData, colors, isStacked = false, height }) => {
  // Ensure moduleData is an array and has at least one element
  if (!Array.isArray(moduleData) || moduleData.length === 0 || !moduleData[0].scenarioSummary) {
    return <p>No Data Found</p>;
  }

  console.log("Module Data:", moduleData);

  const scenarioData = moduleData[0]; // Extract the first object from the array

  // Extract categories (dates)
  const categories = scenarioData.scenarioSummary.map((item) => item.date);

  // Extract data series
  const series = [
    {
      name: "Passed",
      data: scenarioData.scenarioSummary.map((item) => item.countSummary.passedCount || 0),
    },
    {
      name: "Failed",
      data: scenarioData.scenarioSummary.map((item) => item.countSummary.failedCount || 0),
    },
    {
      name: "Warning",
      data: scenarioData.scenarioSummary.map((item) => item.countSummary.warningCount || 0),
    },
    {
      name: "Undefined",
      data: scenarioData.scenarioSummary.map((item) => item.countSummary.undefinedCount || 0),
    },
    {
      name: "In Progress",
      data: scenarioData.scenarioSummary.map((item) => item.countSummary.runningCount || 0),
    },
    {
      name: "Cancelled",
      data: scenarioData.scenarioSummary.map((item) => item.countSummary.cancelledCount || 0),
    }
  ];

  // Chart options
  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: isStacked,
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    colors: colors,
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
      },
    },
    dataLabels: { enabled: false },
    xaxis: { categories },
    legend: {
      position: "bottom",
      offsetX: -10,
      offsetY: 0,
    },
    fill: { opacity: 1 },
  };

  return (
    <div style={{ width: "100%" }}>
      <ReactApexChart options={options} series={series} type="bar" height={height} />
    </div>
  );
};

BarChart.propTypes = {
  moduleData: PropTypes.array.isRequired, // Ensure it's an array
  colors: PropTypes.array,
  isStacked: PropTypes.bool,
  height: PropTypes.number,
};
