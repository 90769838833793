import { Card, CardBody, Col, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import {
  DonutLargeRounded as DonutIcon,
  TimelineRounded as LineIcon,
  BubbleChartRounded as BubbleIcon,
  StackedBarChartRounded as StackedBarIcon,
  BarChartRounded as BarIcon,
  StackedLineChartRounded as StackedLineIcon,
  Assignment as AssignmentIcon,
} from "@mui/icons-material";
import { grey, lightGreen } from "@mui/material/colors";
import { DonutChart } from "./DonutChart";
import { LineChart } from "./LineChart";
import { BubbleChart } from "./BubbleChart";
import { StackedChart } from "./StackedChart";
import { DataTableForChart } from "./DataTableForChart";
import { getRandomColor } from "../../config/util";

const colors = [
  "#33b2df",
  "#546E7A",
  "#d4526e",
  "#13d8aa",
  "#A5978B",
  "#2b908f",
  "#f9a3a4",
  "#90ee7e",
  "#f48024",
  "#69d2e7",
];

const RenderChartRightSection = ({ rightText }) => (
  <>
    <Box display="flex" alignItems="center" justifyContent="center">
      <Avatar
        sx={{ width: 50, height: 50, marginTop: -3, bgcolor: lightGreen[200] }}
      >
        <AssignmentIcon />
      </Avatar>
    </Box>
    <Box padding={2}>
      <Typography
        component="span"
        dangerouslySetInnerHTML={{
          __html:
            rightText ||
            `Here are the Top-performing transactions within the specified timeframe. It's imperative to hone in on these pivotal Business Processes during the test execution.`,
        }}
      />
    </Box>
  </>
);

const ChartSelector = ({ selectedChartType, handleClickChart }) => {
  const chartTypes = [
    { icon: <DonutIcon />, label: "Donut", type: 1 },
    { icon: <LineIcon />, label: "Line", type: 2 },
    { icon: <BubbleIcon />, label: "Bubble", type: 3 },
    { icon: <StackedBarIcon />, label: "Clustered Column", type: 4 },
    { icon: <StackedBarIcon />, label: "Stacked Column", type: 5 },
    { icon: <BarIcon />, label: "100% Column", type: 6 },
    { icon: <StackedBarIcon />, label: "Clustered Bar", type: 7 },
    { icon: <StackedBarIcon />, label: "Stacked Bar", type: 8 },
    { icon: <BarIcon />, label: "100% Bar", type: 9 },
    { icon: <StackedLineIcon />, label: "Overlain Area", type: 10 },
    { icon: <StackedLineIcon />, label: "Stacked Area", type: 11 },
    { icon: <StackedLineIcon />, label: "100% Area", type: 12 },
  ];

  return (
    <Stack spacing={1} alignItems="center">
      {chartTypes.map(({ icon, label, type }) => (
        <Box
          key={type}
          display="inline-grid"
          className={selectedChartType === type ? "selected-box" : ""}
        >
          <IconButton
            className={selectedChartType === type ? "selected-icon" : ""}
            aria-label="View"
            color={selectedChartType === type ? "primary" : "default"}
            onClick={(e) => handleClickChart(e, type)}
          >
            {icon}
          </IconButton>
          <Typography
            variant="caption"
            color={selectedChartType === type ? "primary" : "default"}
          >
            {label}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};

export const InsightsChart = ({
  chartName,
  BusinessProcessDetails,
  defaultChart = 1,
  lineType = 1,
  isStacked = false,
  selectedMenu = 1,
  rightText = "",
  tableHeaderName = "",
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedChartType, setSelectedChartType] = useState(defaultChart);
  const [dataToShow, setDataToShow] = useState(
    BusinessProcessDetails.slice(0, 5)
  );

  const chartHeight = 330;
  const open = Boolean(anchorEl);

  useEffect(() => {
    const handleResize = () => {}; // Implement resize logic if needed
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (BusinessProcessDetails?.length) {
      setDataToShow(BusinessProcessDetails.slice(0, 5));
    }
  }, [BusinessProcessDetails]);

  const handleOpenPopup = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleClickChart = (e, type) => {
    handleClose();
    setSelectedChartType(type);
  };

  const renderChart = () => {
    switch (selectedChartType) {
      case 1:
        return (
          <DonutChart
            businessProcessDetails={dataToShow}
            height={chartHeight}
          />
        );
      case 2:
        return (
          <LineChart
            businessProcessDetails={
              lineType === 2 ? BusinessProcessDetails : dataToShow
            }
            lineType={lineType}
            colors={colors}
            height={chartHeight}
          />
        );
      case 3:
        return (
          <BubbleChart
            businessProcessDetails={dataToShow}
            height={chartHeight}
          />
        );
      case 4:
      case 6:
        return (
          <StackedChart
            businessProcessDetails={dataToShow}
            isBar={false}
            colors={selectedMenu === 2 ? ["#69caaf"] : getRandomColor(colors)}
            height={chartHeight}
          />
        );
      case 5:
        return (
          <StackedChart
            businessProcessDetails={dataToShow}
            isBar={false}
            isStacked={isStacked}
            colors={
              isStacked
                ? ["#df8879", "#a7d5ed", "#a4a2a8", "#48b5c4", "#76c68f"]
                : getRandomColor(colors)
            }
            height={chartHeight}
          />
        );
      case 7:
      case 8:
      case 9:
        return (
          <StackedChart
            businessProcessDetails={dataToShow}
            isBar={true}
            colors={getRandomColor(colors)}
            height={chartHeight}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Row
        style={{
          background: "#f3f3f4",
          paddingTop: "0px",
          marginTop: "20px",
          marginLeft: "0px",
        }}
      >
        <Col xl={5} style={{ paddingRight: "0px", paddingLeft: "0px" }}>
          <Card className="mb-1">
            <CardBody className="card-body-dash">
              <Box className="d-flex align-items-center">
                <Box className="flex-grow-1">
                  <Typography className="card-title">
                    {chartName || "Chart"}
                  </Typography>
                </Box>
                <Tooltip title="Download" arrow>
                  <IconButton
                    aria-label="Download"
                    color="success"
                    onClick={handleOpenPopup}
                  >
                    <TuneIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box style={{ padding: 20, height: "360px" }}>
                {renderChart()}
              </Box>
            </CardBody>
          </Card>
        </Col>
        <Col xl={4} style={{ paddingRight: "0px", paddingLeft: "1px" }}>
          <Card className="mb-1">
            <CardBody>
              <DataTableForChart
                businessProcessDetails={dataToShow}
                chartName={tableHeaderName || chartName}
                height="370px"
              />
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} style={{ paddingLeft: "1px" }}>
          <Card className="mb-1">
            <CardBody>
              <Col
                style={{
                  backgroundColor: grey[200],
                  border: `1px solid ${lightGreen[200]}`,
                  borderRadius: "20px",
                  marginTop: 30,
                  height: "340px",
                }}
              >
                <RenderChartRightSection rightText={rightText} />
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box padding={4}>
          <ChartSelector
            selectedChartType={selectedChartType}
            handleClickChart={handleClickChart}
          />
        </Box>
      </Popover>
    </>
  );
};

InsightsChart.propTypes = {
  chartName: PropTypes.string,
  BusinessProcessDetails: PropTypes.any,
  defaultChart: PropTypes.any,
  lineType: PropTypes.any,
  isStacked: PropTypes.any,
  selectedMenu: PropTypes.any,
  rightText: PropTypes.any,
  tableHeaderName: PropTypes.any,
};

RenderChartRightSection.propTypes = {
  rightText: PropTypes.any,
};
ChartSelector.propTypes = {
  selectedChartType: PropTypes.any,
  handleClickChart: PropTypes.any,
};
