import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { MyTextField } from "../../components/TextField";
import { FormControl, Select, Typography } from "@mui/material";

export const EditUserDetails = (props) => {
  const {
    profileDetails,
    editUserDetails,
    roleDetails,
    handleRoleChange,
    profileEditDetails,
    setProfileEditDetails,
    handleProfileFieldsChangeClick,
    // handleEnableScreenshot,
    fNameErrorMessages,
    lNameErrorMessages,
  } = props;

  useEffect(() => {
    if (editUserDetails) {
      setProfileEditDetails({ ...editUserDetails });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Col className="col-12">
        <div className="card">
          <div className="card-body p-0">
            <div
              className="text-muted"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <Row>
                <div className="col-md-6 col-sm-6">
                  <Row className="mb-2 row">
                    <Col className="col-3">
                      <label className="small-select" htmlFor="edit-user_fName">
                        First Name <i style={{ color: "red" }}>*</i>
                      </label>
                    </Col>
                    <Col className="col-9">
                      <MyTextField
                        ariaLabelledby="edit-user_fName"
                        value={profileEditDetails?.userFirstName || ""}
                        handleChange={(event) =>
                          handleProfileFieldsChangeClick(event, "userFirstName")
                        }
                        type={"text"}
                        className={""}
                        placeholder={""}
                        pattern={""}
                        inputMode={"text"}
                      />
                      {fNameErrorMessages?.isError ? (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {fNameErrorMessages?.message}
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-2 row">
                    <Col className="col-3">
                      <label className="small-select" htmlFor="edit-user-lName">
                        Last Name <i style={{ color: "red" }}>*</i>
                      </label>
                    </Col>
                    <Col className="col-9">
                      <MyTextField
                        ariaLabelledby="edit-user-lName"
                        value={profileEditDetails?.userLastName || ""}
                        handleChange={(event) =>
                          handleProfileFieldsChangeClick(event, "userLastName")
                        }
                        type={"text"}
                        className={""}
                        placeholder={""}
                        pattern={""}
                        inputMode={"text"}
                      />
                      {lNameErrorMessages?.isError ? (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {lNameErrorMessages?.message}
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="col-md-6 col-sm-6">
                  <Row className="mb-2 row">
                    <Col className="col-3">
                      <label className="small-select" htmlFor="edit-user-email">
                        Email
                      </label>
                    </Col>
                    <Col className="col-9">
                      <MyTextField
                        ariaLabelledby="edit-user-email"
                        value={profileEditDetails?.userEmailId || ""}
                        handleChange={(event) =>
                          handleProfileFieldsChangeClick(event, "userEmailId")
                        }
                        type={"email"}
                        className={""}
                        placeholder={""}
                        pattern={
                          "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
                        }
                        inputMode={"email"}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2 row">
                    <Col className="col-3">
                      <label className="small-select" htmlFor="edit-user-role">
                        Role
                      </label>
                    </Col>
                    <Col className="col-9">
                      <FormControl
                        sx={{
                          minWidth: "100%",
                          minHeight: 20,
                        }}
                      >
                        <Select
                          native
                          ariaLabelledby="edit-user-role"
                          defaultValue=""
                          value={profileEditDetails?.userRole || null}
                          name="userRole"
                          onChange={handleRoleChange}
                          style={{ height: 25 }}
                          className="font-size-12"
                          disabled={
                            profileEditDetails?.userId ===
                            profileDetails?.userId
                          }
                        >
                          {roleDetails?.length &&
                            roleDetails.map((role, index) => {
                              return (
                                <option
                                  className="font-size-12"
                                  key={`${role.value}_${index}`}
                                  value={role.value || 0}
                                >
                                  {role?.name || ""}
                                </option>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

EditUserDetails.propTypes = {
  profileDetails: PropTypes.any,
  editUserDetails: PropTypes.any,
  roleDetails: PropTypes.any,
  handleRoleChange: PropTypes.any,
  profileEditDetails: PropTypes.any,
  setProfileEditDetails: PropTypes.any,
  handleProfileFieldsChangeClick: PropTypes.any,
  fNameErrorMessages: PropTypes.any,
  lNameErrorMessages: PropTypes.any,
};
