import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export function initDate() {
  const today = new Date();
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

  const formatDateString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const startDate = formatDateString(sixMonthsAgo);
  const endDate = formatDateString(today);

  return { startDate, endDate };
}

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  fontFamily: ["Work Sans, sans-serif"],
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiInputBase-root-MuiInput-root-MuiSelect-root": { fontSize: "5px" },
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .wrapHeader .MuiDataGrid-colCellTitle": {
    overflow: "hidden",
    lineHeight: "20px",
    whiteSpace: "normal",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    fontSize: "11px",
    lineHeight: "20px",
    whiteSpace: "normal",
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
    cursor: "pointer",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.65)",
  },
  "& .MuiDataGrid-footer": {
    display: "none",
  },

  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "rgba(0,116,230,0.4)",
  },
}));

export function StatusIconComponent(params, property) {
  const statCheck = params.row[property];
  let color;
  switch (statCheck) {
    case "Passed":
      color = "success";
      break;
    case "Failed":
      color = "danger";
      break;
    case "Warning":
      color = "warning";
      break;
    case "Undefined":
      color = "info";
      break;
    default:
      color = "info"; // You can set a default color for other cases if needed
      break;
  }

  return (
    <div>
      {statCheck !== "" && (
        <i className={`mdi mdi-checkbox-blank-circle me-1 text-${color}`}></i>
      )}
    </div>
  );
}


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;


export const generatePDF = (element,varName) => {
  
  const elementToCapture = document.getElementById(element); // Replace with the ID of the element you want to capture

  html2canvas(elementToCapture).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * 10; // Subtract margin
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    // Add a timestamp and username at the top of the document
    const timestamp = new Date().toLocaleString();
    pdf.setFontSize(10);
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const username = obj.user.userDisplayName;
    pdf.text(`Generated on: ${timestamp}, by: ${username}`, 10, 10);
    const timestampFileName = new Date().toISOString().replace(/[-:.]/g, "");
    pdf.addImage(imgData, "PNG", 10, 20, pdfWidth, pdfHeight); // Adjust position for image
    pdf.save(`${varName}-${timestampFileName}.pdf`);
 
  });
};


