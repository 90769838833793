import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Container, Col } from "reactstrap";
import {
  menuSelectionChange,
  setLoadingState,
  showError,
} from "../../store/actions";
import Breadcrumbs from "../AHeaderFooterSections/HeaderComponents/Breadcrumb";
import {
  quickLinkCreate,
  quickLinkDelete,
  quickLinkFetch,
  quickLinkUpdate,
} from "../../store/dashboard/orchestractor";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { StyledDataGrid } from "../generalFunctions";
import { QuickLinkDetails } from "./QuickLinkDetails";
import { useProfile } from "../../Hooks/UserHooks";

const CustomToolbar = () => {
  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ padding: "0 5px 0 5px", borderBottom: " 1px solid #f0f0f0" }}
    >
      <div>
        <h6 style={{ marginBottom: "5px", fontSize: "11px" }}>
          Quick Link Listing
        </h6>
      </div>
    </div>
  );
};

export const QuickLinks = () => {
  const dispatch = useDispatch();
  const { userProfile } = useProfile();

  // Validation messages
  const validationMessages = {
    subject: "Subject is required",
    description: "Description is required",
    linkURL: "Quick link is required",
  };
  const headerHeight = 28;
  const rowHeight = 26;
  const defaultError = {
    isError: false,
    message: null,
  };

  const [rows, setRows] = useState([]);
  const [profileDetails, setProfileDetails] = useState(userProfile?.user || {});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [openQuickLinkModal, setOpenQuickLinkModal] = useState({
    open: false,
    details: null,
    type: "Add",
  });
  const [selectedQuickLinkDetails, setSelectedQuickLinkDetails] =
    useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [quickLinkEditDetails, setQuickLinkEditDetails] = useState({
    id: 0,
    subject: "",
    description: "",
    linkURL: "",
  });
  const [subjectErrorMessages, setSubjectErrorMessages] =
    useState(defaultError);
  const [descriptionErrorMessages, setDescriptionErrorMessages] =
    useState(defaultError);
  const [linkURLErrorMessages, setLinkURLErrorMessages] =
    useState(defaultError);

  useEffect(() => {
    setProfileDetails(userProfile?.user || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    handleMenuClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAllQuickLinkDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenuClick = () => {
    dispatch(menuSelectionChange(9));
  };
  const getAllQuickLinkDetails = async () => {
    try {
      dispatch(setLoadingState(true));
      const requestParam = `?per_page=9999`;
      const data = await quickLinkFetch(requestParam);
      setRows(data);
      dispatch(setLoadingState(false));
    } catch (error) {
      console.error("Error fetching quickLinksData:", error);
      setRows([]);
      dispatch(setLoadingState(false));
    }
  };
  const columns = [
    {
      field: "subject",
      headerName: "Subject",
      width: 200,
      flex: 1,
      renderCell: (params) => {
        const subject = params.row.subject;
        return <div dangerouslySetInnerHTML={{ __html: subject }} />;
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 350,
      flex: 1,
      renderCell: (params) => {
        const description = params.row.description;
        return <div dangerouslySetInnerHTML={{ __html: description }} />;
      },
    },
    {
      field: "linkURL",
      headerName: "Link URL",
      width: 350,
      flex: 1,
    },
    {
      field: "inserted_at",
      headerName: "Created Date",
      width: 160,
      type: "date",
      valueFormatter: (params) => {
        const date = params.value ? new Date(params.value) : "";
        const options = {
          day: "2-digit",
          month: "short",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const formattedDateTime = date
          ? date.toLocaleDateString("en-UK", options)
          : "";
        return params.value ? formattedDateTime : "";
      },
    },
    {
      field: "updated_at",
      headerName: "Updated Date",
      width: 160,
      type: "date",
      valueFormatter: (params) => {
        const date = params.value ? new Date(params.value) : "";
        const options = {
          day: "2-digit",
          month: "short",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const formattedDateTime = date
          ? date.toLocaleDateString("en-UK", options)
          : "";
        return params.value ? formattedDateTime : "";
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => handleDeleteUserClick(params?.row)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="edit"
              size="small"
              onClick={() => handleEditUserClick(params?.row)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];
  const handleCellClick = async (params) => {
    const suiteSelection = params.row.id;
    console.error(suiteSelection);
  };
  const handleDeleteUserClick = (params) => {
    setSelectedQuickLinkDetails(params);
    setOpenDeleteConfirmation(true);
  };
  const handleEditUserClick = (params) => {
    setOpenQuickLinkModal({
      open: true,
      details: params,
      type: "Edit",
    });
    setQuickLinkEditDetails({
      id: params?.id || 0,
      subject: params?.subject || "",
      description: params?.description || "",
      linkURL: params?.linkURL || "",
    });
  };
  const handleDeleteQuickLinkConfirmationClose = () => {
    setOpenDeleteConfirmation(false);
  };
  const handleDeleteQuickLinkConfirmationClick = async () => {
    try {
      const { id } = selectedQuickLinkDetails || {};
      if (id) {
        dispatch(setLoadingState(true));
        const response = await quickLinkDelete(id);
        if (response) {
          const resp = await response.json();
          setSelectedQuickLinkDetails(null);
          setOpenDeleteConfirmation(false);
          dispatch(setLoadingState(false));

          const message = resp?.message || "QuickLink deleted successfully";
          dispatch(showError(true, message, "info"));
          getAllQuickLinkDetails();
        } else {
          const message = "Error with quickLink deletion";
          dispatch(showError(true, message, "error"));
          dispatch(setLoadingState(false));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleCreateQuickLink = (event) => {
    try {
      setOpenQuickLinkModal({
        open: true,
        details: null,
        type: "Add",
      });
      setQuickLinkEditDetails({
        id: 0,
        subject: "",
        description: "",
        linkURL: "",
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleQuickLinkFieldsChangeClick = (event, fieldName) => {
    try {
      const { value } = event.target;
      setQuickLinkEditDetails({
        ...quickLinkEditDetails,
        [fieldName]: value,
      });
      setSubjectErrorMessages(defaultError);
      setDescriptionErrorMessages(defaultError);
      setLinkURLErrorMessages(defaultError);
    } catch (error) {
      console.error(error);
    }
  };
  const handleQuickLinkModalClose = () => {
    setOpenQuickLinkModal({ open: false, details: null, type: "Add" });
    setQuickLinkEditDetails({
      id: 0,
      subject: "",
      description: "",
      linkURL: "",
    });
  };

  const handleError = (message) => {
    dispatch(showError(true, message, "info"));
    dispatch(setLoadingState(false));
  };

  const handleResponse = (response) => {
    if (response) {
      handleQuickLinkModalClose();
      const message =
        response.message ||
        `QuickLink ${
          openQuickLinkModal?.type === "Edit" ? "updated" : "added"
        } successfully`;
      dispatch(showError(true, message, "info"));
      getAllQuickLinkDetails();
    } else {
      handleError(
        `Error occurred while ${
          openQuickLinkModal?.type === "Edit" ? "updating" : "creating"
        } quickLink`
      );
    }
  };
  const handleQuickLinkModalSave = async () => {
    const { id, subject, description, linkURL } = quickLinkEditDetails || {};

    const validateInputs = () => {
      if (!subject?.trim())
        return { isValid: false, message: validationMessages.subject };
      if (!description?.trim())
        return { isValid: false, message: validationMessages.description };
      if (!linkURL?.trim())
        return { isValid: false, message: validationMessages.linkURL };
      return { isValid: true };
    };

    try {
      const { isValid, message } = validateInputs();
      if (!isValid) {
        setSubjectErrorMessages({ isError: true, message });
        return;
      }

      dispatch(setLoadingState(true));

      const requestParam = {
        quick_link: {
          description,
          subject,
          linkURL,
          userId: profileDetails?.userId || 0,
        },
      };

      const response =
        openQuickLinkModal?.type === "Edit" && id
          ? await quickLinkUpdate(requestParam, id)
          : await quickLinkCreate(requestParam);

      handleResponse(response);
    } catch (error) {
      console.error(error);
      handleError(
        `Error occurred while ${
          openQuickLinkModal?.type === "Edit" ? "updating" : "creating"
        } quickLink`
      );
    }
  };

  return (
    <>
      <div className="page-content" style={{ marginTop: "30px" }}>
        <Container fluid>
          <Breadcrumbs
            title="Home"
            titleRoute={"/manage-users"}
            breadcrumbItem="Quick Links"
            style={{ backgroundColor: "#f8f9fa" }}
          />
          <Row>
            <Col className="col-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="col-md-12 col-sm-12">
                    <div
                      style={{
                        padding: 10,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>QuickLink List</Typography>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={handleCreateQuickLink}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      background: "#ffffff",
                      overflow: "auto",
                      height: "445px",
                      width: "100%",
                      flexGrow: 1,
                      padding: 10,
                    }}
                  >
                    <StyledDataGrid
                      initialState={{}}
                      rows={rows}
                      columns={columns}
                      rowHeight={rowHeight}
                      columnHeaderHeight={headerHeight}
                      onCellClick={handleCellClick}
                      hideFooter
                      hideFooterPagination
                      hideFooterSelectedRowCount
                      slots={{ toolbar: CustomToolbar }}
                      onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                      }}
                      rowSelectionModel={rowSelectionModel}
                      componentsProps={{
                        basePopper: {
                          sx: {
                            "& .MuiTypography-root": { fontSize: "12px" },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {openDeleteConfirmation ? (
        <Dialog
          open={openDeleteConfirmation}
          onClose={handleDeleteQuickLinkConfirmationClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure, you want to delete quick link?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteQuickLinkConfirmationClose}>
              Cancel
            </Button>
            <Button
              onClick={handleDeleteQuickLinkConfirmationClick}
              autoFocus
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
      {/* Edit User Details */}
      {openQuickLinkModal?.open ? (
        <Dialog
          open={openQuickLinkModal?.open}
          onClose={handleQuickLinkModalClose}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle>{`${openQuickLinkModal?.type} Quick Link`}</DialogTitle>
          <DialogContent>
            <Row>
              <QuickLinkDetails
                subjectErrorMessages={subjectErrorMessages}
                descriptionErrorMessages={descriptionErrorMessages}
                linkURLErrorMessages={linkURLErrorMessages}
                quickLinkEditDetails={quickLinkEditDetails}
                handleQuickLinkFieldsChangeClick={
                  handleQuickLinkFieldsChangeClick
                }
              />
            </Row>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleQuickLinkModalClose}>Close</Button>
            <Button onClick={handleQuickLinkModalSave}>
              {openQuickLinkModal?.type === "Add" ? "Save" : "Update"}
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
};
