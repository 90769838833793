import { Box, Button, FormControl, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputGroup } from "reactstrap";
import Flatpickr from "react-flatpickr";

export const LeftFilter = (props) => {
  const {
    formDetails,
    handleFilterSelection,
    tenants,
    dateRangeLists,
    isCustomSelected,
    selectedDate,
    setSelectedDate,
    tomorrow,
    functionalAreaList = [],
    categoryList = [],
    testSuiteList = [],
  } = props;

  return (
    <>
      <Box
        style={{
          minHeight: "1150px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between", // Pushes the button to the bottom
        }}
      >
        <Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5">
              <strong>Create Report</strong>
            </Typography>
            <Button variant="outlined">View History</Button>
          </Box>
          <Box>
            <label className="small-select" htmlFor="run-test-tenant-select">
              Tenant
            </label>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                sx={{
                  m: 1,
                  minWidth: "100%",
                  minHeight: 40,
                  margin: "3px",
                }}
              >
                <Select
                  native
                  aria-labelledby="run-test-tenant-select"
                  defaultValue=""
                  value={formDetails?.selectedTenant || null}
                  name="selectedTenant"
                  onChange={handleFilterSelection}
                  style={{ height: 40 }}
                  className="font-size-12"
                >
                  {tenants?.length &&
                    tenants.map((tenant) => {
                      return (
                        <option
                          className="font-size-12"
                          key={tenant.tenantId || 0}
                          value={tenant.tenantId || 0}
                        >
                          {tenant.tenantName || ""}
                        </option>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box>
            <label className="small-select" htmlFor="run-test-tenant-select">
              Date range
            </label>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                sx={{
                  m: 1,
                  minWidth: "100%",
                  minHeight: 40,
                  margin: "3px",
                }}
              >
                <Select
                  native
                  aria-labelledby="run-test-tenant-select"
                  defaultValue=""
                  value={formDetails?.dateRange || null}
                  name="dateRange"
                  onChange={handleFilterSelection}
                  style={{ height: 40 }}
                  className="font-size-12"
                >
                  {dateRangeLists?.length &&
                    dateRangeLists.map((item) => {
                      return (
                        <option
                          className="font-size-12"
                          key={item.value || 0}
                          value={item.value || 0}
                        >
                          {item.name || ""}
                        </option>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          </Box>
          {isCustomSelected ? (
            <Box>
              <label className="small-select" htmlFor="run-test-tenant-select">
                Date Range Picker
              </label>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InputGroup
                  style={{
                    m: 1,
                    minWidth: "100%",
                    minHeight: 40,
                    margin: "3px",
                  }}
                >
                  <Flatpickr
                    id="dateRangeDatepicker"
                    aria-labelledby="date-range"
                    className="form-control-cal d-block"
                    placeholder="Date range"
                    options={{
                      mode: "range",
                      altInput: true,
                      altFormat: "d-M-Y",
                      dateFormat: "Y-m-d",
                      defaultDate: selectedDate,
                      onChange: (selectedDates) => {
                        console.log(selectedDates);
                      },
                      onClose: (selectedDates) => {
                        console.log("onClose ===> ", selectedDates);
                        setSelectedDate(
                          selectedDates?.length === 2 ? selectedDates : []
                        );
                      },
                      disable: [
                        {
                          from: tomorrow,
                          to: new Date(2099, 0, 1), // You can adjust the 'to' date as needed
                        },
                      ],
                    }}
                    value={null}
                  />
                </InputGroup>
              </Box>
            </Box>
          ) : (
            <></>
          )}
          <Box>
            <label className="small-select" htmlFor="functional-area-select">
              Functional area
            </label>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                sx={{
                  m: 1,
                  minWidth: "100%",
                  minHeight: 40,
                  margin: "3px",
                }}
              >
                <Select
                  native
                  aria-labelledby="functional-area-select"
                  defaultValue=""
                  value={formDetails?.functionalArea || null}
                  name="functionalArea"
                  onChange={handleFilterSelection}
                  style={{ height: 40 }}
                  className="font-size-12"
                >
                  {functionalAreaList?.length &&
                    functionalAreaList.map((item) => {
                      return (
                        <option
                          className="font-size-12"
                          key={item.value || 0}
                          value={item.value || 0}
                        >
                          {item.name || ""}
                        </option>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box>
            <label className="small-select" htmlFor="category-area-select">
              Choose category (optional)
            </label>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                sx={{
                  m: 1,
                  minWidth: "100%",
                  minHeight: 40,
                  margin: "3px",
                }}
              >
                <Select
                  native
                  aria-labelledby="category-area-select"
                  defaultValue=""
                  value={formDetails?.category || null}
                  name="category"
                  onChange={handleFilterSelection}
                  style={{ height: 40 }}
                  className="font-size-12"
                >
                  {categoryList?.length &&
                    categoryList.map((item) => {
                      return (
                        <option
                          className="font-size-12"
                          key={item.value || 0}
                          value={item.value || 0}
                        >
                          {item.name || ""}
                        </option>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box>
            <label className="small-select" htmlFor="testSuite-area-select">
              Test suite (optional)
            </label>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                sx={{
                  m: 1,
                  minWidth: "100%",
                  minHeight: 40,
                  margin: "3px",
                }}
              >
                <Select
                  native
                  aria-labelledby="testSuite-area-select"
                  defaultValue=""
                  value={formDetails?.testSuite || null}
                  name="testSuite"
                  onChange={handleFilterSelection}
                  style={{ height: 40 }}
                  className="font-size-12"
                >
                  {testSuiteList?.length &&
                    testSuiteList.map((item) => {
                      return (
                        <option
                          className="font-size-12"
                          key={item.value || 0}
                          value={item.value || 0}
                        >
                          {item.name || ""}
                        </option>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box
          pt={2}
          style={{
            borderTop: "1px solid #f3f3f3",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button variant="text">Clear all</Button>
          <Button variant="contained" color="primary">
            Generate report
          </Button>
        </Box>
      </Box>
    </>
  );
};
