import React, { useEffect, useState } from "react";
import moment from "moment";
import Flatpickr from "react-flatpickr";

import { Box, FormControl, Select } from "@mui/material";
import { Col, InputGroup, Row } from "reactstrap";
import { getTenants, setTenantSelection, showError } from "../../store/actions";
import { useDispatch } from "react-redux";
import { tenantfetch as tenantFetch } from "../../store/dashboard/orchestractor";
import { initDate } from "../generalFunctions";

export const SearchByFunctionArea = ({ tenants }) => {
  const dispatch = useDispatch();
  const dateRangeLists = [
    { value: 2, name: "This Week" },
    { value: 1, name: "Today" },
    { value: 3, name: "This Month" },
    { value: 4, name: "This Year" },
    { value: 5, name: "Custom" },
  ];
  const defaultFormDetails = {
    selectedTenant: "",
    dateRange: 2,
  };
  // const date = moment(new Date());

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate());

  const [isCustomSelected, setIsCustomSelected] = useState(false);
  const [selectedDate, setSelectedDate] = useState([]);
  const [formDetails, setFormDetails] = useState(defaultFormDetails);
  const [selectedDateRangeType, setSelectedDateRangeType] = useState(0);
  const [selectedDateRangeValue, setSelectedDateRangeValue] = useState(null);
  const [selectedDateRangeType1, setSelectedDateRangeType1] = useState(null);

  useEffect(() => {
    setSelectedDate([]);
    const todayDate = moment(new Date()).format("YYYY-MM-DD");
    setSelectedDateRangeValue({ startDate: todayDate, endDate: todayDate });
    setSelectedDateRangeType(1);
    setSelectedDateRangeType1('today')
    setFormDetails({
      ...defaultFormDetails,
      selectedTenant: tenants?.length > 0 ? tenants[0]?.tenantId : 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStartEndDate = (type = "week") => {
    const startDate = moment().startOf(type).format("YYYY-MM-DD");
    const endDate = moment().endOf(type).format("YYYY-MM-DD");
    return {
      startDate,
      endDate,
    };
  };

  const handleFilterSelection = (event) => {
    const { name, value } = event.target;
    console.log('date',name, value)
    const selectedVal = value ? parseInt(value) : 0;
    if (name === "dateRange" && selectedVal) {
      if (selectedVal === 1) {
        const todayDate = moment(new Date()).format("YYYY-MM-DD");
        setIsCustomSelected(false);
        setSelectedDateRangeType1('today')
        setSelectedDate([]);
        setSelectedDateRangeValue({ startDate: todayDate, endDate: todayDate });
      } else if (selectedVal === 2) {
        setIsCustomSelected(false);
        setSelectedDateRangeType1('this_week')
        setSelectedDate([]);
        setSelectedDateRangeValue(getStartEndDate("week"));
      } else if (selectedVal === 3) {
        setSelectedDateRangeType1('this_month')
        setIsCustomSelected(false);
        setSelectedDate([]);
        setSelectedDateRangeValue(getStartEndDate("month"));
      } else if (selectedVal === 4) {
        setSelectedDateRangeType1('this_year')
        setIsCustomSelected(false);
        setSelectedDate([]);
        setSelectedDateRangeValue(getStartEndDate("year"));
      } else if (selectedVal === 5) {
        setSelectedDateRangeType1('custom')
        setIsCustomSelected(true);
      } else {
        setIsCustomSelected(false);
        setSelectedDate([]);
        setSelectedDateRangeType1('today')
      }
      setSelectedDateRangeType(selectedVal);
    }
    setFormDetails({
      ...formDetails,
      [name]: selectedVal,
    });
  };

  const handleSubmitClick = () => {
    let startDateVal = "";
    let endDateVal = "";
    let formattedStartDate = null;
    let formattedEndDate = null;

    if (
      selectedDateRangeType === 1 ||
      selectedDateRangeType === 2 ||
      selectedDateRangeType === 3 ||
      selectedDateRangeType === 4
    ) {
      const { startDate, endDate } = selectedDateRangeValue || {};
      formattedStartDate = startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : null;
      formattedEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
    } else if (selectedDateRangeType === 5 && selectedDate?.length === 2) {
      startDateVal = selectedDate[0];
      endDateVal = selectedDate[1];
      formattedStartDate = startDateVal
        ? moment(startDateVal).format("YYYY-MM-DD")
        : null;
      formattedEndDate = endDateVal
        ? moment(endDateVal).format("YYYY-MM-DD")
        : null;
    }
    console.log(
      "handleSubmitClick ===> ",
      formDetails,
      formattedStartDate,
      formattedEndDate,
      selectedDateRangeType1
    );
    if (
      formattedStartDate &&
      formattedEndDate &&
      formattedStartDate <= formattedEndDate
    ) {
      dispatch(
        setTenantSelection(
          parseInt(formDetails?.selectedTenant, 10),
          formattedStartDate,
          formattedEndDate,
          selectedDateRangeType1
        )
      );
    } else {
      const message = "From-Date should not greater than or equalto To-Date";
      dispatch(showError(true, message, "error"));
    }
  };

  const handleReset = async () => {
    try {
      // const res = date.format("YYYY-MM-DD");
      const tenantInit = await tenantFetch();
      setFormDetails({
        ...defaultFormDetails,
        selectedTenant: tenantInit.tenantSelection,
      });
      setIsCustomSelected(false);
      setSelectedDate([]);
      dispatch(getTenants(tenantInit.tenants));
      dispatch(
        setTenantSelection(
          tenantInit.tenantSelection,
          initDate().startDate,
          initDate().endDate
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box m={2}>
      <Row className="row">
        <Col className="col-3">
          <label className="small-select" htmlFor="run-test-tenant-select">
            Tenant
          </label>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl
              sx={{
                m: 1,
                minWidth: 350,
                minHeight: 20,
                margin: "3px",
              }}
            >
              <Select
                native
                aria-labelledby="run-test-tenant-select"
                defaultValue=""
                value={formDetails?.selectedTenant || null}
                name="selectedTenant"
                onChange={handleFilterSelection}
                style={{ height: 25 }}
                className="font-size-12"
              >
                {tenants?.length &&
                  tenants.map((tenant) => {
                    return (
                      <option
                        className="font-size-12"
                        key={tenant.tenantId || 0}
                        value={tenant.tenantId || 0}
                      >
                        {tenant.tenantName || ""}
                      </option>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
        </Col>
        <Col className="col-3">
          <label className="small-select" htmlFor="run-test-tenant-select">
            Date range
          </label>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl
              sx={{
                m: 1,
                minWidth: 350,
                minHeight: 20,
                margin: "3px",
              }}
            >
              <Select
                native
                aria-labelledby="run-test-tenant-select"
                defaultValue=""
                value={formDetails?.dateRange || null}
                name="dateRange"
                onChange={handleFilterSelection}
                style={{ height: 25 }}
                className="font-size-12"
              >
                {dateRangeLists?.length &&
                  dateRangeLists.map((item) => {
                    return (
                      <option
                        className="font-size-12"
                        key={item.value || 0}
                        value={item.value || 0}
                      >
                        {item.name || ""}
                      </option>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
        </Col>
        {isCustomSelected ? (
          <Col className="col-3">
            <label className="small-select" htmlFor="run-test-tenant-select">
              Date Range Picker
            </label>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <InputGroup>
                <Flatpickr
                  id="dateRangeDatepicker"
                  aria-labelledby="date-range"
                  className="form-control-cal d-block"
                  placeholder="Date range"
                  options={{
                    mode: "range",
                    altInput: true,
                    altFormat: "d-M-Y",
                    dateFormat: "Y-m-d",
                    defaultDate: selectedDate,
                    onChange: (selectedDates) => {
                      console.log(selectedDates);
                    },
                    onClose: (selectedDates) => {
                      console.log("onClose ===> ", selectedDates);
                      setSelectedDate(
                        selectedDates?.length === 2 ? selectedDates : []
                      );
                    },
                    disable: [
                      {
                        from: tomorrow,
                        to: new Date(2099, 0, 1), // You can adjust the 'to' date as needed
                      },
                    ],
                  }}
                  value={null}
                />
              </InputGroup>
            </Box>
          </Col>
        ) : (
          <></>
        )}
        <Col className="col-2" style={{ marginTop: 28 }}>
          <Box display={"flex"} gap={2}>
            <button
              className="btn btn-primary small-select"
              style={{ width: 61, height: 25 }}
              onClick={handleSubmitClick}
            >
              Submit
            </button>
            <button
              type="button"
              className="btn btn-outline-primary small-select"
              style={{ width: 40, height: 25 }}
              onClick={handleReset}
            >
              Reset
            </button>
          </Box>
        </Col>
      </Row>
    </Box>
  );
};
