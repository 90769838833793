// Define action type constants
export const GET_TENANTS = "GET_TENANTS";
export const SET_TENANT_SELECTION = "SET_TENANT_SELECTION";
export const GET_FA_AREAS = "GET_FA_AREAS";
export const SET_FA_AREA_SELECTION = "SET_FA_AREA_SELECTION";
export const GET_SUITE_LIST = "GET_SUITE_LIST";
export const GET_MODULE_LIST = "GET_MODULE_LIST";
export const GET_TEST_SUITE_LIST = "GET_TEST_SUITE_LIST";
export const SET_WORKBOARD_SUITE_ID = "SET_WORKBOARD_SUITE_ID";
export const SET_SUITE_SELECTION = "SET_SUITE_SELECTION";
// export const SET_TEST_SUITE_SELECTION = "SET_TEST_SUITE_SELECTION";
export const SET_MODULE_SELECTION = "SET_MODULE_SELECTION";
export const SET_TEST_SUITE_SELECTION = "SET_TEST_SUITE_SELECTION";
export const CURRENT_SUITE_NAME_SELECTION = "CURRENT_SUITE_NAME_SELECTION";
export const CURRENT_SUITE_INSTANCE_LIST = "CURRENT_SUITE_INSTANCE_LIST";
export const GET_SCENARIO_LIST = "GET_SCENARIO_LIST";
export const CURRENT_SCENARIO_NAME_SELECTION =
  "CURRENT_SCENARIO_NAME_SELECTION";
export const SET_SCENARIO_SELECTION = "SET_SCENARIO_SELECTION";
export const GET_STEP_LIST = "GET_STEP_LIST";
export const SET_LOADING_STATE = "SET_LOADING_STATE";
export const SET_SEARCH = "SET_SEARCH";
export const SET_SEARCH_FLAG = "SET_SEARCH_FLAG";
export const SHOW_ERROR = "SHOW_ERROR";
export const MENU_SELECTION = "MENU_SELECTION";
export const HANDLE_ERROR = "HANDLE_ERROR";
export const SELECTED_REPORTS = "SELECTED_REPORTS";
export const SET_SELECTED_SUITE_IDS= "SET_SELECTED_SUITE_IDS";

// Define action creators

export const getTenants = (tenants) => ({
  type: GET_TENANTS,
  payload: tenants,
});

export const setTenantSelection = (tenantSelection, startDate, endDate, selectedDateRangeType1) => ({
  type: SET_TENANT_SELECTION,
  payload: { tenantSelection, startDate, endDate, selectedDateRangeType1 },
});

export const getFAAreas = (faAreas) => ({
  type: GET_FA_AREAS,
  payload: faAreas,
});

export const setFAAreaSelection = (faAreaSelection) => ({
  type: SET_FA_AREA_SELECTION,
  payload: faAreaSelection,
});

export const setSelectedSuiteIds = (selectedSuiteIds) => ({
  type: SET_SELECTED_SUITE_IDS,
  payload: selectedSuiteIds,
});

export const getSuiteList = (suiteList) => ({
  type: GET_SUITE_LIST,
  payload: suiteList,
});

export const getModuleList = (moduleList) => ({
  type: GET_MODULE_LIST,
  payload: moduleList,
});

export const getTestSuiteList = (testSuiteList) => ({
  type: GET_TEST_SUITE_LIST,
  payload: testSuiteList,
});

export const currentSuiteNameSelection = (suiteNameSelectionID) => ({
  type: CURRENT_SUITE_NAME_SELECTION,
  payload: suiteNameSelectionID,
});

export const fetchSuiteInstanceListing = (suiteInstanceListing) => ({
  type: CURRENT_SUITE_INSTANCE_LIST,
  payload: suiteInstanceListing,
});

export const setSuiteSelection = (suiteSelection) => ({
  type: SET_SUITE_SELECTION,
  payload: suiteSelection,
});
export const setWorkboardSuiteId = (suiteSelection) => ({
  type: SET_WORKBOARD_SUITE_ID,
  payload: suiteSelection,
});




export const setModuleSelection = (moduleSelection) => ({
  type: SET_MODULE_SELECTION,
  payload: moduleSelection,
});
// export const setTestSuiteSelection = (testSuiteSelection) => ({
//   type: SET_TEST_SUITE_SELECTION,
//   payload: testSuiteSelection,
// });

export const getScenarioList = (scenarioList) => ({
  type: GET_SCENARIO_LIST,
  payload: scenarioList,
});

export const currentScenarioNameSelection = (scenarioNameSelection) => ({
  type: CURRENT_SCENARIO_NAME_SELECTION,
  payload: scenarioNameSelection,
});

export const setScenarioSelection = (scenarioSelection) => ({
  type: SET_SCENARIO_SELECTION,
  payload: scenarioSelection,
});

export const getStepList = (stepList) => ({
  type: GET_STEP_LIST,
  payload: stepList,
});

export const setLoadingState = (isLoading) => {
  return {
    type: "SET_LOADING_STATE",
    payload: isLoading,
  };
};

export const setRunSearch = (searchText, searchType, searchFlag) => {
  return {
    type: "SET_SEARCH",
    payload: { searchText, searchType, searchFlag },
  };
};

export const setSearchFlag = (searchFlag) => {
  return {
    type: "SET_SEARCH_FLAG",
    payload: searchFlag,
  };
};

export const showError = (error_flag, error_msg, error_type) => {
  return {
    type: SHOW_ERROR,
    payload: { error_flag, error_msg, error_type },
  };
};

export const menuSelectionChange = (menuSelection) => {
  return {
    type: MENU_SELECTION,
    payload: menuSelection,
  };
};

export const commonErrorHandle = (error_flag, error_msg, error_type) => {
  return {
    type: HANDLE_ERROR,
    payload: { error_flag, error_msg, error_type },
  };
};
export const setSelectedReport = (payload) => {
  return {
    type: SELECTED_REPORTS,
    payload,
  };
};
