import React, { useEffect } from "react";
import ProgressBar from "../../WorkBoard/Charts/ResultsBarChart";
import { Card, CardBody } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
} from "@mui/x-data-grid";

import { StyledDataGrid } from "../../generalFunctions";
import { Link } from "react-router-dom";
import { menuSelectionChange, setSuiteSelection } from "../../../store/actions";
import { ExcelExportIcon } from "../../../components/ExcelExportIcon";

const getFilteredRows = ({ apiRef }) =>
  gridExpandedSortedRowIdsSelector(apiRef);

const columns = [
  {
    field: "id",
    headerName: "Run ID",
    // align: "center",
    width: 150,
  },
  {
    field: "date1",
    headerName: "Date",
    width: 400,
    flex: 1,
    type: "date",
    valueFormatter: (params) => {
      const date = new Date(params.value);
      const options = {
        day: "2-digit",
        month: "short",
        year: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      const formattedDateTime = date.toLocaleDateString("en-UK", options);
      return formattedDateTime;
    },
  },
  {
    field: "duration",
    headerName: "Duration",
    width: 400,
    // renderHeader: () => <ColumnHeaderWrapper />,
  },
  {
    field: "results",
    renderCell: RenderCellProgressBar,
    headerName: "Results",
    width: 400,
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: "Actions",
    renderCell: ActionsBtnRedirect,
    headerName: "Actions",
    width: 100,
    disableColumnMenu: true,
    sortable: false,
    disableExport: true,
  },
  {
    field: "passed",
    headerName: "Passed",
    align: "center",
    width: 70,
  },
  {
    field: "failed",
    headerName: "Failed",
    align: "center",
    width: 70,
  },
  {
    field: "warning",
    headerName: "Warning",
    align: "center",
    width: 70,
  },
  {
    field: "undefined",
    headerName: "Undefined",
    align: "center",
    width: 80,
  },
  {
    field: "failed",
    headerName: "Failed",
    align: "center",
    width: 70,
  },
  {
    field: "warning",
    headerName: "Warning",
    align: "center",
    width: 70,
  },
  {
    field: "undefined",
    headerName: "Undefined",
    align: "center",
    width: 80,
  },
];

const CustomToolbar = () => {
  const apiRef = useGridApiContext();

  const handleExport = (options) => {
    // Set a custom file name for the exported CSV file

    const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    options.fileName = `suite_instance_listing_${timestamp}`;

    apiRef.current.exportDataAsCsv(options);
  };

  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ padding: "0 5px 0 5px", borderBottom: " 1px solid #f0f0f0" }}
    >
      <div>
        <h6 style={{ marginBottom: "0px", fontSize: "11px" }}>
          Suite Run Instance Listing
        </h6>
      </div>

      <ExcelExportIcon
        handleExport={handleExport}
        getFilteredRows={getFilteredRows}
      />
    </div>
  );
};

const GridMain = () => {
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const suiteInstanceListing = useSelector(
    (state) => state.dashboardData.suiteInstanceListing || []
  );
  const suiteSelection = useSelector(
    (state) => state.dashboardData.suiteSelection || []
  );
  console.log(suiteInstanceListing, suiteSelection);
  useEffect(() => {
    setRowSelectionModel([suiteSelection]);
  }, [suiteSelection, suiteInstanceListing]);

  const headerHeight = 28;
  const rowHeight = 28;
  const rows = suiteInstanceListing.map((item) => ({
    id: item.suiteRIId,
    date1: item.suiteRIStartDatetime,
    duration: item.suiteRIDuration,
    results: `Passed-${item.suiteRIStatus.passedCount}|Failed-${item.suiteRIStatus.failedCount}|Warning-${item.suiteRIStatus.warningCount}|Undefined-${item.suiteRIStatus.undefinedCount}`,

    passed: item.suiteRIStatus.passedCount,
    failed: item.suiteRIStatus.failedCount,
    warning: item.suiteRIStatus.warningCount,
    undefined: item.suiteRIStatus.undefinedCount,
  }));
  return (
    <Card>
      <CardBody className="card-body-dash">
        <div
          style={{
            overflow: "auto",
            height: "460px",
            marginRight: "5px",
            width: "100%",
            flexGrow: 1,
            marginBottom: "12px",
          }}
        >
          <StyledDataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  warning: false,
                  passed: false,
                  failed: false,
                  undefined: false,
                },
              },
            }}
            rows={rows}
            columns={columns}
            rowHeight={rowHeight}
            columnHeaderHeight={headerHeight}
            hideFooter
            hideFooterPagination
            hideFooterSelectedRowCount
            slots={{ toolbar: CustomToolbar }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            componentsProps={{
              // Set custom styles for the datafg grid components

              basePopper: {
                // sx: {
                //   backgroundColor: "red",
                //   "& .MuiPaper-root": { backgroundColor: "green",fontSize:"2px" },
                //   "&.MuiDataGrid-menu .MuiPaper-root": { backgroundColor: "blue",fontSize:"10px" }
                // }
                sx: {
                  "& .MuiTypography-root": { fontSize: "12px" },
                  // '& .MuiDataGrid-filterForm': {backgroundColor: 'lightblue'},
                },
              },
            }}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default GridMain;

function RenderCellProgressBar(params) {
  const passed = params.row.passed;
  const failed = params.row.failed;
  const warning = params.row.warning;
  const Undefined = params.row.undefined;

  return (
    <ProgressBar
      Passed={passed}
      Failed={failed}
      Warning={warning}
      Undefined={Undefined}
    />
  );
}

function ActionsBtnRedirect(params) {
  const dispatch = useDispatch();
  const smallSelect = {
    // fontSize: "8px",
    // lineHeight: "8px",
    // width: "80%",
    // borderRadius: "2px",
    padding: "3px",
    marginBottom: "4px",
    marginTop: "4px",
  };

  const handleLinkClick = () => {
    dispatch(setSuiteSelection(params.row.id));
    dispatch(menuSelectionChange(3));
  };

  return (
    <Link
      style={smallSelect}
      // className="btn btn-outline-primary waves-effect waves-light"
      to="/workboard"
      onClick={handleLinkClick}
    >
      View
    </Link>
  );
}
