import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// users
import avatar2 from "../../../assets/images/users/Ilumn-Avatar-05.png";
import { ManageProfile } from "../../Profile/ManageProfile";
import { logoutMyUser } from "../../../store/dashboard/WrapperAPI";
const ProfileMenu = ({ success }) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setUsername] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    try {
      // Fetch the user data from localStorage and update the username state based on the authentication method
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const username = obj.user.userDisplayName;

      setUsername(username);
    } catch (error) {
      // Handle any errors that occur during the process and log them
      console.error("Error while fetching user data:", error);
    }
  }, [success]);

  const handleClose = () => {
    setOpen(false);
  };

  const logoutUser = async () => {
    logoutMyUser();
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={avatar2}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-2">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* Add a logout link */}
          <Link
            className="dropdown-item"
            component="button"
            onClick={() => {
              setMenu(false);
              setOpen(true);
            }}
          >
            <i className="mdi mdi-logout align-middle me-2 text-danger" />
            <span>Manage Profile</span>
          </Link>
          <Link
            className="dropdown-item"
            component="button"
            onClick={() => {
              logoutUser();
            }}
          >
            <i className="mdi mdi-logout align-middle me-2 text-danger" />
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      {/* Dialog component for displaying logs */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>Manage Profile</DialogTitle>
        <DialogContent>
          <ManageProfile />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleClose}>Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any
};

const mapStateToProps = (state) => ({
  success: state.login.userLoggedIn,
});

export default connect(mapStateToProps, {})(ProfileMenu);
