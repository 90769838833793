import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import React from "react";
import reportIcon from "../../assets/svg/report_dash.svg";

export const ReportCard = (props) => {
  const {
    width = 500,
    isCreateNew = false,
    id = 0,
    reportName = "",
    reportDesc = "",
    handleReportCardClick = () => {},
  } = props;

  return (
    <Card
      key={id}
      variant={isCreateNew ? "" : "outlined"}
      style={
        isCreateNew
          ? {
              border: "2px dashed #f3f3f3",
              backgroundColor: "#F4FAFF",
              width: width,
            }
          : {
              width: width,
            }
      }
    >
      <CardActionArea onClick={handleReportCardClick}>
        <CardContent>
          <img src={reportIcon} alt="drag-icon" />
          <Typography
            gutterBottom
            sx={{ mt: 1, color: "text.primary", fontSize: 14 }}
          >
            {reportName || ""}
          </Typography>
          <Typography sx={{ color: "text.secondary", fontSize: "10px" }}>
            {reportDesc || ""}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
