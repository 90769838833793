import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";
import Radial4Cards from "../Dashboard/Charts/CardChart";
// import Radial4Cards from "./Charts/CardChart";

export const ReportAreaCards = ({
  dashboardData,
  FASelection,
  setFAAreaSelection,
}) => {
  // This component is called ReportAreaCards and receives props dashboardData, FASelection, and setFAAreaSelection.

  const handleButtonClick = (faId) => {
    // This function is called when a radio button is clicked. It sets the FA area selection.
    setFAAreaSelection(faId);
  };

  useEffect(() => {
    // This effect runs when the FASelection prop changes.
    // It checks if the FASelection is not null and if so, it selects the corresponding radio button.
    if (FASelection !== null) {
      const radioElement = document.getElementById(`card-${FASelection}`);
      if (radioElement) {
        radioElement.checked = true;
      }
    }
  }, [FASelection]);

  if (!dashboardData || dashboardData.length === 0) {
    return <p>No records found.</p>;
  }

  return (
    <React.Fragment>
      {dashboardData.map((data) => (
        <Col xl={3} sm={6} key={data.faId} className="mt-2">
          <Card className="card-containerv2">
            <input
              type="radio"
              className="btn-check"
              name="btnradiocards"
              id={`card-${data.faId}`}
              aria-labelledby={`card-${data.faId}`}
              autoComplete="off"
              onClick={() => handleButtonClick(data.faId)}
              defaultChecked={data.faId === FASelection}
            />
            <label
              className="btn btn-outline-cards btnOverRide"
              htmlFor={`card-${data.faId}`}
            >
              <CardBody
                style={{
                  padding: 0,
                }}
              >
                <Row
                  style={{
                    backgroundColor:
                      data.faId === FASelection ? "#0068CF" : "#FAFAFA",
                    borderBottom:
                      data.faId === FASelection
                        ? "1px solid #0875E1"
                        : "1px solid #E7E7E7",
                    borderRadius: "10px 10px 0px 0px",
                    margin: "1px",
                  }}
                >
                  <p className="mb-1">{data.faShortName}</p>
                </Row>
                <Row style={{ padding: "5px 10px" }}>
                  <Col className="col-7 cardBodyCol">
                    <div
                      id={`radialchart-${data.faId}`}
                      className="apex-charts"
                      dir="ltr"
                    >
                      <Radial4Cards Scores={data.faScenarioRISummary} />
                      {/* <div className="">{data.faScenarioTotal}</div> */}
                    </div>
                    {/* <p className="smallerfontsize"># Scenario Run</p> */}
                  </Col>
                  {/* myBorder */}
                  <Col className="col-5" style={{ marginTop: 10 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p className="smallTable">Passed</p>
                      <small className="font-size-4">
                        {data.faScenarioRISummary.passedCount}
                      </small>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p className="smallTable">Failed</p>
                      <small className="font-size-4">
                        {data.faScenarioRISummary.failedCount}
                      </small>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p className="smallTable">Warning</p>
                      <small className="font-size-4">
                        {data.faScenarioRISummary.warningCount}
                      </small>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p className="smallTable">Undefined</p>
                      <small className="font-size-4">
                        {data.faScenarioRISummary.undefinedCount}
                      </small>
                    </div>
                  </Col>
                </Row>
                {/* <Row className="mb-2 row"></Row>
                <Row className="topBorder"></Row>
                <Row className="d-flex align-items-center justify-content-center text-center">
                  <Col className="d-flex align-items-center justify-content-center text-center paddingz">
                    <p className="smallTable">Passed</p>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-center text-center paddingz">
                    <p className="smallTable">Failed</p>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-center text-center paddingz">
                    <p className="smallTable">Warning</p>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-center text-center paddingz">
                    <p className="smallTable">Undefined</p>
                  </Col>
                </Row> */}
                {/* <Row className="paddingz">
                  <Col className="d-flex align-items-center justify-content-center text-center paddingz">
                    <small className="font-size-4">
                      {data.faScenarioRISummary.passedCount}
                    </small>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-center text-center paddingz">
                    <small className="font-size-4">
                      {data.faScenarioRISummary.failedCount}
                    </small>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-center text-center paddingz">
                    <small className="font-size-4">
                      {data.faScenarioRISummary.warningCount}
                    </small>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-center text-center paddingz">
                    <small className="font-size-4">
                      {data.faScenarioRISummary.undefinedCount}
                    </small>
                  </Col>
                </Row> */}
              </CardBody>
            </label>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

ReportAreaCards.propTypes = {
  dashboardData: PropTypes.any,
  FASelection: PropTypes.any,
  setFAAreaSelection: PropTypes.any,
};
