import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

// Redux
import { useDispatch, connect } from "react-redux";
import { Link } from "react-router-dom";

import newLogoDark from "../../assets/images/logo/Ilumn Coloured Logo.png";
import newLogoSm from "../../assets/images/logo/Ilumn Coloured Small.png";

// Redux Store
import {
  getTenants,
  setTenantSelection,
  toggleLeftmenu,
} from "../../store/actions";
import ProfileMenu from "./HeaderComponents/ProfileMenu";
import { tenantfetch } from "../../store/dashboard/orchestractor";
import { initDate } from "../generalFunctions";
import {
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { TenantDetailsDrawer } from "./TenantDetailsDrawer";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";

const Header = (props) => {
  const dispatch = useDispatch();
  const dummyValue = "abc123";
  const [open, setOpen] = useState(false);
  const [tenantDrawerDetails, setTenantDrawerDetails] = useState();
  const tenantData = [
    {
      id: 1,
      tenantName: "Tenant 1",
      loginURL: "https://ilumn.eu/login1",
      nameDetails: dummyValue,
      pwddetails: dummyValue,
      isEditable: false,
      isPasswordVisible: false,
    },
    {
      id: 2,
      tenantName: "Tenant 2",
      loginURL: "https://ilumn.eu/login2",
      nameDetails: dummyValue,
      pwddetails: dummyValue,
      isEditable: false,
      isPasswordVisible: false,
    },
    {
      id: 3,
      tenantName: "Tenant 3",
      loginURL: "https://ilumn.eu/login3",
      nameDetails: dummyValue,
      pwddetails: dummyValue,
      isEditable: false,
      isPasswordVisible: false,
    },
    {
      id: 4,
      tenantName: "Tenant 4",
      loginURL: "https://ilumn.eu/login3",
      nameDetails: dummyValue,
      pwddetails: dummyValue,
      isEditable: false,
      isPasswordVisible: false,
    },
    {
      id: 5,
      tenantName: "Tenant 5",
      loginURL: "https://ilumn.eu/login5",
      nameDetails: dummyValue,
      pwddetails: dummyValue,
      isEditable: false,
      isPasswordVisible: false,
    },
    {
      id: 6,
      tenantName: "Tenant 6",
      loginURL: "https://ilumn.eu/login6",
      nameDetails: dummyValue,
      pwddetails: dummyValue,
      isEditable: false,
      isPasswordVisible: false,
    },
  ];

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const tenantInit = await tenantfetch();
        dispatch(getTenants(tenantInit.tenants));
        dispatch(
          setTenantSelection(
            tenantInit.tenantSelection,
            initDate().startDate,
            initDate().endDate
            

          )
        );
      } catch (error) {
        // Handle any errors that occur while fetching tenants
        console.error("Error fetching tenants:", error);
      }
    };

    fetchTenants();
  }, [dispatch]);

  // Function to toggle fullscreen mode
  function toggleFullscreen() {
    const isFullscreen =
      !!document.fullscreenElement ||
      !!document.mozFullScreenElement ||
      !!document.webkitFullscreenElement;

    const requestFullscreen = (element) => {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    };

    const exitFullscreen = () => {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    };

    if (!isFullscreen) {
      requestFullscreen(document.documentElement);
    } else {
      exitFullscreen();
    }
  }

  const handleOpenTenantDetailsPopUp = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setTenantDrawerDetails(tenantData);
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box text-center">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={newLogoSm} alt="logo-sm-dark" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={newLogoDark} alt="logo-dark" height="32" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu);
              }}
              data-target="#topnav-menu-content"
            >
              <i className="mdi mdi-menu" />
            </button>
          </div>

          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block ms-1">
              {/* Display the current tenant name */}
              {/* <Tooltip title="Tenant Details" arrow>
                <IconButton
                  aria-label="Tenant Details"
                  color="primary"
                  onClick={handleOpenTenantDetailsPopUp}
                >
                  <ContactEmergencyIcon />
                </IconButton>
              </Tooltip> */}
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon"
                data-toggle="fullscreen"
              >
                <i
                  style={{ fontSize: "20px" }}
                  className="mdi mdi-fullscreen"
                />
              </button>
            </div>

            <ProfileMenu />
          </div>
        </div>
      </header>
      {/* Dialog component for displaying logs */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>Tenant Details</DialogTitle>
        <DialogContent>
          <TenantDetailsDrawer
            tenantData={tenantData}
            tenantDrawerDetails={tenantDrawerDetails}
            setTenantDrawerDetails={setTenantDrawerDetails}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { layoutType, leftMenu } = state.Layout;
  return { layoutType, leftMenu };
};

export default connect(mapStateToProps, {
  toggleLeftmenu,
})(Header);
