import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import {
  setRunSearch,
  setSearchFlag,
  showError,
} from "../../../store/dashboard/actions";

const smallSelect = {
  fontSize: "12px",
  lineHeight: "15px",
  width: "100%",
  borderRadius: "2px",
  padding: "3px",
  marginBottom: "3px",
};

const paddingTopBottom = {
  paddingTop: "10px",
  paddingBottom: "5px",
  paddingLeft: "8px",
};

const borderStyles = {
  border: "solid",
  borderWidth: "1px",
  borderColor: "#ced4da",
  marginBottom: "5px",
};

const WbQuickSearchCard = () => {
  const [selectedRadio, setSelectedRadio] = useState("SuiteRunIDRadios");
  const [searchText, setSearchText] = useState("");
  const searchFlag = true;

  const dispatch = useDispatch();
  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };

  const handleSearch = () => {
    if (searchText.trim() !== "" && !isNaN(searchText)) {
      // Only dispatch if searchText is a number
      dispatch(setRunSearch(searchText, selectedRadio));
      dispatch(setSearchFlag(searchFlag));
    } else {
      dispatch(showError(true, "Please enter Valid Run ID to Search", "error"));
    }
  };

  return (
    <Col xl={2} sm={6} style={{ paddingRight: "0px" }}>
      <Card style={borderStyles}>
        <CardBody style={paddingTopBottom}>
          <h6 style={{ marginBottom: "8px", fontSize: "11px" }}>
            Quick Search
          </h6>
          <Row>
            <Col xl={8}>
              <input
                style={smallSelect}
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Col>
            <Col xl={4}>
              <button
                style={smallSelect}
                type="button"
                className="btn btn-primary btn-rounded waves-effect waves-light"
                onClick={handleSearch}
              >
                Go
              </button>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px", marginBottom: "3px" }}>
            <Col xl={12}>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadios"
                  id="SuiteRunIDRadios"
                  value="SuiteRunIDRadios"
                  aria-labelledby="SuiteRunIDRadios"
                  checked={selectedRadio === "SuiteRunIDRadios"}
                  onChange={handleRadioChange}
                />
                <label
                  style={smallSelect}
                  className="form-check-label"
                  htmlFor="SuiteRunIDRadios"
                >
                  Suite Run ID
                </label>
              </div>
            </Col>
          </Row>

          <Row style={{ marginBottom: "3px" }}>
            <Col xl={12}>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadios"
                  id="scenRunIDRadios"
                  value="scenRunIDRadios"
                  aria-labelledby="scenRunIDRadios"
                  checked={selectedRadio === "scenRunIDRadios"}
                  onChange={handleRadioChange}
                />
                <label
                  style={smallSelect}
                  className="form-check-label"
                  htmlFor="scenRunIDRadios"
                >
                  Scenario Run ID
                </label>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default WbQuickSearchCard;
