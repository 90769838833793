// Import required modules and components
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Col, Collapse, InputGroup, Row } from "reactstrap";
import { setTenantSelection, showError } from "../../store/dashboard/actions";
import moment from "moment";
import { useDispatch, connect } from "react-redux";
import { CreateSchedule } from "../TestManager/CreateSchedule";
import timezoneData from "../TestManager/timezones.json";

// Functional component ComparisonFilters
const ComparisonFilters = ({
  comparisonTabsData,
  tenantSelection,
  startDateValue,
  endDateValue,
  setFilterFormSelection,
}) => {
  const defaultFormDetails = {
    recurrenceType: 1,
    recurrenceTypeValue: 0,
    monthlyRecurrenceType: 1,
    monthlyRecurrenceTypeValue: 0,
    testSuiteTimezone: "GMT", // || "IST",
    testSuiteTimeSelection: "06:00",
    weeklyRecurrenceValue: 0,
  };

  // State variables using React hooks
  const [col1, setCol1] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [open, setOpen] = useState(false);
  const [formDetails, setFormDetails] = useState({ ...defaultFormDetails });
  const [daysOfWeek, setDaysOfWeek] = React.useState([]);
  const [monthlyMonthDetails, setMonthlyMonthDetails] = useState([]);
  const [monthlyWeekDetails, setMonthlyWeekDetails] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState("1");
  const [startDateSchedule, setStartDateSchedule] = useState(null);
  const [endDateSchedule, setEndDateSchedule] = useState(null);

  const recurrenceConstant = [
    "Daily Recurrence Criteria",
    "Weekly Recurrence Criteria",
    "Monthly Recurrence Criteria",
  ];
  const names = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const generateTimeList = () => {
    return Array.from({ length: 24 * 4 }, (_, index) => {
      const hour = Math.floor(index / 4);
      const minute = (index % 4) * 15;
      const formattedHour = String(hour).padStart(2, "0");
      const formattedMinute = String(minute).padStart(2, "0");
      return `${formattedHour}:${formattedMinute}`;
    });
  };

  // Example usage
  const timeList = generateTimeList();
  const dispatch = useDispatch();
  // useEffect hook to set state variables based on props
  useEffect(() => {
    setSelectedTenant(tenantSelection);
    setStartDate(startDateValue);
    setEndDate(endDateValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantSelection, startDateValue, endDateValue]);

  // Function to toggle the collapse state for column 1
  const toggleCol1 = () => {
    setCol1(!col1);
  };

  // Event handler for selecting a tenant from the dropdown
  const handleTenantChange = (event) => {
    setSelectedTenant(event.target.value);
  };

  // Event handler for form submission
  const handleFormSubmit = async () => {
    const formattedStartDate = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : null;
    const formattedEndDate = endDate
      ? moment(endDate).format("YYYY-MM-DD")
      : null;
    if (formattedStartDate <= formattedEndDate) {
      setFilterFormSelection({
        selectedTenant: parseInt(selectedTenant, 10),
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
    } else {
      const message = "From-Date should not great than or equalto To-Date";
      dispatch(showError(true, message, "error"));
      setStartDate(startDateValue);
      setEndDate(endDateValue);
    }
  };

  // Event handler for reset button click
  const handleReset = async () => {
    // To-Do
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate());

  const handleClose = () => {
    setStartDate(null);
    setEndDate(null);
    setFormDetails({ ...defaultFormDetails });
    setDaysOfWeek([]);
    setMonthlyWeekDetails([]);
    setMonthlyMonthDetails([]);
    setOpen(false);
  };
  const handleChangeTestSuite = (event) => {
    const { name, value } = event.target;
    setFormDetails({ ...formDetails, [name]: value });
  };
  const handleChangeTestSuiteFrequency = (event) => {
    const { name, value } = event.target;
    setFormDetails({ ...formDetails, [name]: value });
  };
  const handleChangeSchedule = (event) => {
    const { value } = event.target;
    setFormDetails({
      ...formDetails,
      recurrenceType: value ? parseInt(value) : 1,
    });
    setMonthlyMonthDetails([]);
  };
  const handleMonthlyChange = (event) => {
    const { value } = event.target;
    setFormDetails({
      ...formDetails,
      monthlyRecurrenceType: value ? parseInt(value) : 1,
    });
    setDaysOfWeek([]);
    setMonthlyWeekDetails([]);
  };
  const handleWeekChange = (event) => {
    const {
      target: { value },
    } = event;
    setDaysOfWeek(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleMonthlyMonthChange = (event) => {
    const { value } = event?.target || {};
    setMonthlyMonthDetails(
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleMonthlyWeekChange = (event) => {
    const { value } = event?.target || {};
    setMonthlyWeekDetails(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeTimeZone = (event) => {
    const { value, name } = event?.target || {};
    setFormDetails({ ...formDetails, [name]: value });
  };
  const handleChangeTimeSelection = (event) => {
    const { value, name } = event?.target || {};
    setFormDetails({ ...formDetails, [name]: value });
  };
  const handleRecurrenceTypeValueChange = (event) => {
    const { value, name } = event?.target || {};
    setFormDetails({ ...formDetails, [name]: value });
  };
  const handleWeeklyRecurrenceValueChange = (event) => {
    // weeklyRecurrenceValue
    const { value, name } = event?.target || {};
    setFormDetails({ ...formDetails, [name]: value ? parseInt(value) : 0 });
  };
  const checkCreateScheduleFormValidation = () => {
    const hasTestSuiteFrequency = formDetails?.testSuiteFrequency;
    const isStartDateValid = !!startDate;
    const isEndDateValid = !!endDate;

    return hasTestSuiteFrequency && isStartDateValid && isEndDateValid;
  };

  const handleCreateSchedule = (event) => {
    try {
      if (checkCreateScheduleFormValidation()) {
        console.log("handleCreateSchedule Calling...");
        console.log(formDetails, startDate, endDate, daysOfWeek);
        console.log("monthlyMonthDetails ===>", monthlyMonthDetails);
        console.log("monthlyWeekDetails ===>", monthlyWeekDetails);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Row>
        <Col className="col-12">
          <div className="card" style={{ marginBottom: 10 }}>
            <div className="card-body p-0">
              <div className="accordion" id="accordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col1,
                      })}
                      type="button"
                      onClick={toggleCol1}
                      style={{ cursor: "pointer" }}
                    >
                      <h4 className="mb-0 font-size-14">Filters</h4>
                    </button>
                  </h2>

                  <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">
                      <div className="text-muted">
                        <Row>
                          <div className="col-md-11 col-sm-12">
                            <Row className="mb-2 row">
                              <div className="card-title-desc-filters">
                                Please choose tenant, effective date to show
                                results.
                              </div>
                            </Row>
                            <Row className="mb-2 row">
                              <Col className="col-md-4 col-sm-12">
                                <div style={{ display: "flex" }}>
                                  <label
                                    className="small-select"
                                    htmlFor="tenant"
                                  >
                                    Choose your Tenant
                                  </label>
                                  <select
                                    aria-controls="tenant"
                                    className="form-select small-select"
                                    value={selectedTenant}
                                    onChange={handleTenantChange}
                                  >
                                    {comparisonTabsData?.map((rec) => {
                                      return (
                                        <option
                                          key={`tab_item_index_${rec.id}`}
                                          value={rec.id}
                                        >{`${rec.fName} & ${rec.sName}`}</option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </Col>
                              <Col className="col-md-4 col-sm-12">
                                <div style={{ display: "flex" }}>
                                  <label
                                    className="small-select"
                                    htmlFor="effective date"
                                  >
                                    Effective Date
                                  </label>
                                  <InputGroup>
                                    <Flatpickr
                                      aria-controls="effective date"
                                      className="form-control-cal d-block"
                                      placeholder="Enter to Date"
                                      options={{
                                        altInput: true,
                                        altFormat: "d-M-Y",
                                        dateFormat: "Y-m-d",
                                        onChange: (selectedDates) =>
                                          setStartDate(selectedDates[0]),
                                        disable: [
                                          {
                                            from: tomorrow,
                                            to: new Date(2099, 0, 1), // You can adjust the 'to' date as needed
                                          },
                                        ],
                                      }}
                                      value={startDate}
                                    />
                                  </InputGroup>
                                </div>
                              </Col>
                              <Col className="col-md-4 col-sm-12">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-primary small-select"
                                    onClick={handleFormSubmit}
                                    style={{ marginRight: 15 }}
                                  >
                                    Submit
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger small-select"
                                    onClick={handleReset}
                                  >
                                    Reset
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="col-md-1 col-sm-12 mystyle">
                            <Row className="row" style={{ marginTop: 29 }}>
                              <Col className="col-md-12 col-sm-12">
                                <button
                                  type="button"
                                  className="btn btn-primary small-select"
                                  onClick={() => {
                                    setOpen(true);
                                  }}
                                >
                                  <span>&nbsp;&nbsp;{"Schedule"}</span>
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {open ? (
        <CreateSchedule
          open={open}
          handleClose={handleClose}
          formDetails={formDetails}
          handleChangeTestSuite={handleChangeTestSuite}
          testSuitDetails={[]}
          handleChangeTestSuiteFrequency={handleChangeTestSuiteFrequency}
          recurrenceConstant={recurrenceConstant}
          handleChange={handleChangeSchedule}
          daysOfWeek={daysOfWeek}
          handleWeekChange={handleWeekChange}
          names={names}
          timeList={timeList}
          timezoneData={timezoneData}
          handleMonthlyChange={handleMonthlyChange}
          monthlyMonthDetails={monthlyMonthDetails}
          handleMonthlyMonthChange={handleMonthlyMonthChange}
          monthlyWeekDetails={monthlyWeekDetails}
          handleMonthlyWeekChange={handleMonthlyWeekChange}
          monthNames={monthNames}
          startDate={startDateSchedule}
          setStartDate={setStartDateSchedule}
          endDate={endDateSchedule}
          setEndDate={setEndDateSchedule}
          handleChangeTimeZone={handleChangeTimeZone}
          handleChangeTimeSelection={handleChangeTimeSelection}
          handleRecurrenceTypeValueChange={handleRecurrenceTypeValueChange}
          handleCreateSchedule={handleCreateSchedule}
          handleWeeklyRecurrenceValueChange={handleWeeklyRecurrenceValueChange}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  tenantSelection: state.dashboardData.tenantSelection || "",
  startDateValue: state.dashboardData.startDate || "",
  endDateValue: state.dashboardData.endDate || "",
});

export default connect(mapStateToProps, { setTenantSelection })(
  ComparisonFilters
);

ComparisonFilters.propTypes = {
  comparisonTabsData: PropTypes.any,
  tenantSelection: PropTypes.any,
  startDateValue: PropTypes.any,
  endDateValue: PropTypes.any,
  setFilterFormSelection: PropTypes.any,
};
