import React, { useEffect } from "react";
import RenderExpandableCell from "./tooltipHelp";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  currentScenarioNameSelection,
  setScenarioSelection,
} from "../../../store/actions";
import { StyledDataGrid, StatusIconComponent } from "../../generalFunctions";

// Columns configuration for the DataGrid
const columns = [
  // Column for Run ID
  {
    field: "id",
    headerName: "Run ID",
    align: "center",
    width: 60,
    maxWidth: 60,
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
  },
  // Column for Description
  {
    field: "desc",
    headerName: "Description",
    width: 190,
    renderCell: (params) =>
      React.createElement(RenderExpandableCell, { ...params }),
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
  },
  // Column for Date
  {
    field: "scenarioStartDate",
    headerName: "Date",
    width: 80,
    // flex: 1,
    disableColumnMenu: true,
    type: "date",
    align: "center",
    valueFormatter: (params) => {
      const date = new Date(params.value);
      const options = {
        day: "2-digit",
        month: "short",
        year: "2-digit",
        // hour: "numeric",
        // minute: "numeric",
        // second: "numeric",
      };
      const formattedDateTime = date.toLocaleDateString("en-UK", options);
      return formattedDateTime;
    },
    sortable: false,
  },
  // Column for Duration
  {
    field: "duration",
    headerName: "Duration",
    align: "center",
    width: 70,
    disableColumnMenu: true,
    sortable: false,
  },
  // Column for Status
  {
    field: "results",
    renderCell: (params) => StatusIconComponent(params, "results"),
    type: "singleSelect",
    valueOptions: ["Passed", "Failed", "Warning", "Undefined"],
    headerName: "Status",
    align: "center",
    width: 60,
    disableColumnMenu: true,
    sortable: false,
  },
];

const ScenarioTable = ({
  subScenario,
  currentscenarioID,
  setScenarioSelection,
}) => {
  // Define headerHeight and rowHeight for the DataGrid
  const headerHeight = 28;
  const rowHeight = 26;

  // State to manage row selection in the DataGrid
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  // useEffect hook to set the row selection when the currentscenarioID or subScenario changes
  useEffect(() => {
    setRowSelectionModel([currentscenarioID]);
  }, [subScenario, currentscenarioID]);

  // Function to handle cell click event
  const handleCellClick = async (params) => {
    const suiteSelection = params.row.id;
    setScenarioSelection(suiteSelection);
  };

  // Prepare rows data for the DataGrid
  const rows = subScenario
    ? subScenario.map((item) => ({
        id: item.scenarioRIId,
        scenarioStartDate: item.scenarioRIStartDatetime,
        duration: item.scenarioRIDuration,
        desc: item.scenarioRIDesc,
        results:
          item.scenarioRIStatus.charAt(0).toUpperCase() +
          item.scenarioRIStatus.slice(1),
      }))
    : [];

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        minWidthidth: "2px",
        background: "#ffffff",
      }}
    >
      {/* StyledDataGrid component with customized configuration */}
      <StyledDataGrid
        rows={rows}
        getRowId={(row) => row.id}
        columns={columns}
        rowHeight={rowHeight}
        columnHeaderHeight={headerHeight}
        hideFooter
        hideFooterPagination
        hideFooterSelectedRowCount
        onCellClick={handleCellClick}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
      />
    </div>
  );
};

// mapStateToProps to get data from Redux store
const mapStateToProps = (state) => ({
  scenaioList: state.dashboardData.scenaioList || [],
  currentscenarioID: state.dashboardData.scenarioSelection || [],
});

// mapDispatchToProps to dispatch actions to Redux store
const mapDispatchToProps = {
  setScenarioSelection,
  currentScenarioNameSelection,
};

// Connect the component to the Redux store using connect() higher-order component
export default connect(mapStateToProps, mapDispatchToProps)(ScenarioTable);

ScenarioTable.propTypes = {
  subScenario: PropTypes.any,
  currentscenarioID: PropTypes.any,
  setScenarioSelection: PropTypes.any,
};
