import React from "react";

export const Error404Page = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>404</h1>
      <h2>Ooops...</h2>
      <h3>Page not found</h3>
    </div>
  );
};
