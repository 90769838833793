// Importing API functions from "./WrapperAPI"
import {
  deleteAPI,
  downloadFileAPI,
  fileUploadAPI,
  getAPI,
  logoutMyUser,
  postAPI,
  putAPI,
} from "./WrapperAPI";
import { apiEndpoints } from "./apiEndpoints";

// Function to fetch tenant data from the API
export const tenantFetchAPI = async () => {
  try {
    // Sending a GET request to the specified URL to fetch tenant data

    const response = await getAPI(apiEndpoints.GET_TENANTS);
    return response.tenants; // Returning the tenant data from the response
  } catch (error) {
    console.error("Error fetching Tenant data:", error);
    throw new Error("Failed to fetch Tenant data");
  }
};

// Function to fetch Functional Area (FA) details data from the API
export const FunctionalAreaFetchAPI = async (apiPayload) => {
  try {
    // Sending a POST request to the specified URL with the given payload to fetch FA details data
    const response = await postAPI(apiEndpoints.DASHBOARD, apiPayload);
    return response.dashboardData; // Returning the FA details data from the response
  } catch (error) {
    console.error("Error fetching FA Details data:", error);
    throw new Error("Failed to get FunctionalArea data");
  }
};

// Function to fetch suite list data from the API
export const suiteListAPI = async (apiPayload) => {
  try {
    // Sending a POST request to the specified URL with the given payload to fetch suite list data

    const response = await postAPI(apiEndpoints.FA_DETAILS, apiPayload);

    return response.faUIData.suiteRIData; // Returning the suite list data from the response
  } catch (error) {
    console.error("Error posting data:", error);
    throw new Error("Failed to post data");
  }
};

// Function to fetch scenario list data from the API
export const scenarioListAPI = async (apiPayload) => {
  try {
    // Sending a POST request to the specified URL with the given payload to fetch scenario list data
    const response = await postAPI(apiEndpoints.SUITE_DETAILS, apiPayload);

    return response.SuiteRIDetails.scenarioRIData; // Returning the scenario list data from the response
  } catch (error) {
    console.error("Error posting scenario data:", error);
    throw new Error("Failed posting scenario data");
  }
};

// Function to fetch step list data from the API
export const stepListAPI = async (apiPayload) => {
  try {
    // Sending a POST request to the specified URL with the given payload to fetch step list data

    const response = await postAPI(
      apiEndpoints.SCENARIO_RUN_DETAILS,
      apiPayload
    );

    return response; // Returning the step list data from the response
  } catch (error) {
    console.error("Error posting steps data:", error);
    throw new Error("Failed to post steps data");
  }
};

// Function to search for suite data from the API based on suite ID
export const searchSuiteIdAPI = async (apiPayload) => {
  try {
    const url = apiEndpoints.TEST_RUN_SRI;
    const concatenatedUrl = `${url}${apiPayload}`;
    // Sending a GET request to the concatenated URL to search for suite data
    const response = await getAPI(concatenatedUrl);
    return response; // Returning the suite data from the response
  } catch (error) {
    console.error("Error fetching suite data:", error);
    throw new Error("Failed to fetch suite data");
  }
};

// Function to search for scenario data from the API based on scenario ID
export const searchScenarioIdAPI = async (apiPayload) => {
  try {
    const url = apiEndpoints.TEST_RUN_SCRI;
    const concatenatedUrl = `${url}${apiPayload}`;
    // Sending a GET request to the concatenated URL to search for scenario data
    const response = await getAPI(concatenatedUrl);
    return response; // Returning the scenario data from the response
  } catch (error) {
    console.error("Error fetching suite data:", error);
    throw new Error("Failed to fetch suite data");
  }
};

// Function to fetch login Profile from the API
export const loginAPIpost = async (apiPayload) => {
  try {
    // Sending a POST request to the specified URL with the given payload for Auth
    const response = await postAPI(apiEndpoints.LOGIN, apiPayload);

    return response; // Returning the user Profile data from the response
  } catch (error) {
    console.error("Error login:", error);
    throw new Error("Failed to login");
  }
};

// Function to fetch Suite Run List from the API
export const fetchSuiteRunListAPISub = async () => {
  try {
    // Sending a POST request to the specified URL with the given payload for Auth
    const response = await getAPI(apiEndpoints.TM_START);
    return response; // Returning the Suite Run List from the response
  } catch (error) {
    console.error("Error Suite Run List:", error);
    throw new Error("Failed to Suite Run List");
  }
};

// Function to fetch suite Run API from the API
export const suiteRunAPISub = async (apiPayload) => {
  try {
    // Sending a POST request to the specified URL with the given payload for Auth
    const response = await postAPI(apiEndpoints.TEST_RUN_SUITE, apiPayload);
    return response; // Returning the suite Run API data from the response
  } catch (error) {
    console.error("Error suite Run API:", error);
    throw new Error("Failed to suite Run API");
  }
};

// Function to fetch notification from the API
export const notificationFetchPost = async (apiPayload) => {
  try {
    // Sending a POST request to the specified URL with the given payload for Auth

    const response = await postAPI(apiEndpoints.NOTIFICATIONS, apiPayload);
    return response.notifications; // Returning the notification data from the response
  } catch (error) {
    console.error("Error on fetching Notification:", error);
    throw new Error("Nitification fetch Error");
  }
};

// Function to quick link from the API
export const quicklinkFetchAPI = async (requestParam) => {
  try {
    // Sending a POST request to the specified URL with the given payload for Auth
    const response = await getAPI(`${apiEndpoints.QUICK_LINKS}${requestParam}`);
    return response.rows; // Returning the quick link  from the response
  } catch (error) {
    console.error("Error quick link :", error);
    throw new Error("Failed to quick link ");
  }
};

export const quickLinkCreateAPI = async (requestPayload) => {
  try {
    const response = await postAPI(
      `${apiEndpoints.QUICK_LINKS}`,
      requestPayload
    );
    return response;
  } catch (error) {
    console.error("Error quickLinks :", error);
    throw new Error("Failed to quickLinks ");
  }
};
export const quickLinkUpdateAPI = async (requestParam, id) => {
  try {
    const response = await putAPI(
      `${apiEndpoints.QUICK_LINKS}/${id}`,
      requestParam
    );
    return response;
  } catch (error) {
    console.error("Error quickLinks :", error);
    throw new Error("Failed to quickLinks ");
  }
};
export const quickLinkDeleteAPI = async (requestParam) => {
  try {
    const response = await deleteAPI(
      `${apiEndpoints.QUICK_LINKS}/${requestParam}`
    );
    return response;
  } catch (error) {
    console.error("Error quickLinks :", error);
    throw new Error("Failed to quickLinks ");
  }
};

export const announcementFetchAPI = async (requestParam) => {
  try {
    const response = await getAPI(
      `${apiEndpoints.ANNOUNCEMENTS}${requestParam}`
    );
    return response.rows;
  } catch (error) {
    console.error("Error announcements :", error);
    throw new Error("Failed to announcements ");
  }
};
export const announcementCreateAPI = async (requestPayload) => {
  try {
    const response = await postAPI(
      `${apiEndpoints.ANNOUNCEMENTS}`,
      requestPayload
    );
    return response;
  } catch (error) {
    console.error("Error announcements :", error);
    throw new Error("Failed to announcements ");
  }
};
export const announcementUpdateAPI = async (requestParam, id) => {
  try {
    const response = await putAPI(
      `${apiEndpoints.ANNOUNCEMENTS}/${id}`,
      requestParam
    );
    return response;
  } catch (error) {
    console.error("Error announcements :", error);
    throw new Error("Failed to announcements ");
  }
};
export const announcementDeleteAPI = async (requestParam) => {
  try {
    const response = await deleteAPI(
      `${apiEndpoints.ANNOUNCEMENTS}/${requestParam}`
    );
    return response;
  } catch (error) {
    console.error("Error announcements :", error);
    throw new Error("Failed to announcements ");
  }
};

// Function to loginAPI from the API
export const logoutAPI = async () => {
  try {
    const response = await getAPI(`${apiEndpoints.LOGOUT}`);
    return response; // Returning the logout details from the response
  } catch (error) {
    console.error("Error announcements :", error);
    logoutMyUser();
    throw new Error("Failed to announcements ");
  }
};

export const getAllScheduleAPI = async () => {
  try {
    const response = await getAPI(`${apiEndpoints.GET_ALL_SCHEDULE}`);
    return response;
  } catch (error) {
    console.error("Error with get schedule:", error);
    throw new Error("Failed to get schedule");
  }
};

export const createScheduleAPI = async (apiPayload) => {
  try {
    const response = await postAPI(
      `${apiEndpoints.CREATE_UPDATE_DELETE_SCHEDULE}`,
      apiPayload
    );
    return response;
  } catch (error) {
    console.error("Error with create schedule:", error);
    throw new Error("Failed to create schedule");
  }
};

export const updateScheduleAPI = async (apiPayload, id) => {
  try {
    const response = await putAPI(
      `${apiEndpoints.CREATE_UPDATE_DELETE_SCHEDULE}/${id}`,
      apiPayload
    );
    return response;
  } catch (error) {
    console.error("Error with update schedule:", error);
    throw new Error("Failed to update schedule");
  }
};

export const deleteScheduleAPI = async (id) => {
  try {
    const response = await deleteAPI(
      `${apiEndpoints.CREATE_UPDATE_DELETE_SCHEDULE}/${id}`
    );
    return response;
  } catch (error) {
    console.error("Error with delete schedule:", error);
    throw new Error("Error with delete schedule ");
  }
};

export const uploadFileAPI = async (faId, suiteId, formData) => {
  try {
    const uploadImageUrl = `${apiEndpoints.TEST_SUITE_FILE_UPLOAD}/${faId}/${suiteId}/save`;
    const response = await fileUploadAPI(uploadImageUrl, formData);
    return response;
  } catch (error) {
    console.error("Error with upload file:", error);
    throw new Error("Error with upload file");
  }
};
export const getUploadedFilesListAPI = async (faId, suiteId, filename) => {
  try {
    const listURL = apiEndpoints.GET_UPLOADED_FILE_LIST;
    const queryParams = new URLSearchParams();

    if (faId) queryParams.append("faId", faId);
    if (suiteId) queryParams.append("suiteId", suiteId);
    if (filename) queryParams.append("filename", filename);

    const finalURL = queryParams.toString()
      ? `${listURL}?${queryParams}`
      : listURL;
    const response = await getAPI(finalURL);
    return response;
  } catch (error) {
    console.error("Error with get uploaded files list:", error);
    throw new Error("Failed to get uploaded files list");
  }
};

export const downloadTemplateFileForTestSuiteAPI = async (faId, suiteId) => {
  try {
    let listURL = `${apiEndpoints.DOWNLOAD_TEMPLATE_FILE}`;
    let queryParams = [];

    if (faId) queryParams.push(`faId=${faId}`);
    if (suiteId) queryParams.push(`suiteId=${suiteId}`);

    queryParams = queryParams.length ? queryParams.join("&") : null;

    listURL = queryParams ? `${listURL}?${queryParams}` : listURL;
    const response = await downloadFileAPI(listURL);
    return response;
  } catch (error) {
    console.error("Error with template file download:", error);
    throw new Error("Error with template file download");
  }
};

// Function to fetch Executed Suite Run List from the API
export const fetchExecutedSuiteRunListAPI = async () => {
  try {
    // Sending a POST request to the specified URL with the given payload for Auth
    const response = await getAPI(
      `${apiEndpoints.EXECUTED_TEST_LIST}?per_page=9999&page=1`
    );
    return response; // Returning the Suite Run List from the response
  } catch (error) {
    console.error("Error Suite Run List:", error);
    throw new Error("Failed to Suite Run List");
  }
};
export const cancelExecutedTestSuiteAPI = async (id) => {
  try {
    const response = await deleteAPI(`${apiEndpoints.CANCEL_SUITE_RUN}/${id}`);
    return response;
  } catch (error) {
    console.error("Error with delete schedule:", error);
    throw new Error("Error with delete schedule ");
  }
};
export const getAllUserListAPI = async () => {
  try {
    const response = await getAPI(apiEndpoints.USER_LIST);
    return response;
  } catch (error) {
    console.error("Error with fetching user list:", error);
    throw new Error("Error with fetching user list");
  }
};
export const updateUserDetailsAPI = async (userId, requestPayload) => {
  try {
    const response = await postAPI(
      `${apiEndpoints.USER_DETAILS}/${userId}`,
      requestPayload
    );
    return response;
  } catch (error) {
    console.error("Error with user update:", error);
    throw new Error("Error with user update");
  }
};
export const updateSelfUserDetailsAPI = async (userId, requestPayload) => {
  try {
    const response = await postAPI(
      `${apiEndpoints.USER_UPDATE_SELF}`,
      requestPayload
    );
    return response;
  } catch (error) {
    console.error("Error with user update:", error);
    throw new Error("Error with user update");
  }
};
export const updateUserPasswordAPI = async (requestPayload) => {
  try {
    const response = await postAPI(
      `${apiEndpoints.USER_PASSWORD_UPDATE}`,
      requestPayload
    );
    return response;
  } catch (error) {
    console.error("Error with user update:", error);
    throw new Error("Error with user update");
  }
};
export const deleteUserAPI = async (userId) => {
  try {
    const response = await deleteAPI(`${apiEndpoints.USER_DELETE}/${userId}`);
    return response;
  } catch (error) {
    console.error("Error with user update:", error);
    throw new Error("Error with user update");
  }
};
