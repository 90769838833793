// Import necessary dependencies and components
import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

// Define the color array
const colorArr = ["#11c46e", "#ff3d60", "#f1b44c", "#9da1a6"];

// Functional component for the DashDonutChart
const DashDonutChart = ({ dataChart }) => {
  // Extract data from dataChart prop
  const suiteName = dataChart.suiteName;
  const passedCount = dataChart.suiteRIStatusSummary.passedCount;
  const failedCount = dataChart.suiteRIStatusSummary.failedCount;
  const warningCount = dataChart.suiteRIStatusSummary.warningCount;
  const undefinedCount = dataChart.suiteRIStatusSummary.undefinedCount;
  const totalCount = passedCount + failedCount + warningCount + undefinedCount;

  // Define the chart data and options for the donut chart
  const chartData = {
    series: [passedCount, failedCount, warningCount, undefinedCount],
    options: {
      // Tooltip custom formatting
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let total = 0;
          for (let x of series) {
            total += x;
          }
          let selected = series[seriesIndex];

          return `
            <div style="color :white;font-size:10px;padding:5px; background-color: ${
              colorArr[seriesIndex]
            };">
              ${w.config.labels[seriesIndex]}: ${((selected / total) * 100)
            .toFixed(0)
            .toLocaleString()}%
            </div>
          `;
        },
      },
      labels: ["Passed", "Failed", "Warning", "Undefined"],
      colors: ["#11c46e", "#ff3d60", "#f1b44c", "#9da1a6"],
      legend: {
        show: false,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: false,
        fontSize: "12px",
        offsetX: 0,
        offsetY: -10,
      },
      dataLabels: {
        enabled: false, // Hide data labels
      },
      plotOptions: {
        pie: {
          donut: {
            size: "70%",
            labels: {
              show: false, // Hide data labels within donut segments
            },
          },
        },
      },
      stroke: {
        show: false, // Remove lines between series
        width: 1,
      },
      responsive: [
        {
          breakpoint: 200,
          options: {
            chart: {
              height: 240,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  };

  return (
    // Card component to wrap the donut chart and related content
    <Card style={{ marginBottom: "5px" }}>
      {/* Display the suite name */}
      <CardTitle className="suite-dount-title">Suite - {suiteName}</CardTitle>
      <CardBody className="card-body-dash">
        {/* Render the donut chart */}
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="donut"
          height="160"
          className="apex-charts"
        />
        {/* Display the total count in the center */}
        <div className="centerAbsolute">
          <p className="chartTotalText">{totalCount}</p>
        </div>
        {/* Render the legend items */}
        <Row className="d-flex align-items-center justify-content-center text-center suite-dount-legend-top">
          <Col className="p-0 d-flex align-items-center justify-content-center text-center">
            <p className="p-0 m-0 font-size-10">Passed</p>
          </Col>
          <Col className="p-0 d-flex align-items-center justify-content-center text-center">
            <p className="p-0 m-0 font-size-10">Failed</p>
          </Col>
          <Col className="p-0 d-flex align-items-center justify-content-center text-center">
            <p className="p-0 m-0 font-size-10">Warning</p>
          </Col>
          <Col className="p-0 d-flex align-items-center justify-content-center text-center">
            <p className="p-0 m-0 font-size-10">Undefined</p>
          </Col>
        </Row>
        {/* Render the legend icons and values */}
        <Row className="suite-dount-legend">
          <Col className="p-0 d-flex align-items-center justify-content-center text-center">
            <small className="font-size-4">{passedCount}</small>
          </Col>
          <Col className="d-flex align-items-center justify-content-center text-center">
            <small className="font-size-4">{failedCount}</small>
          </Col>
          <Col className="p-0 d-flex align-items-center justify-content-center text-center">
            <small className="font-size-4">{warningCount}</small>
          </Col>
          <Col className="p-0 d-flex align-items-center justify-content-center text-center">
            <small className="font-size-4">{undefinedCount}</small>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default DashDonutChart;

DashDonutChart.propTypes = {
  dataChart: PropTypes.any,
};
