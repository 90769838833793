import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import PreviewIcon from "@mui/icons-material/Preview";

const styleDetails = { fontSize: 14 };
const boxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 5,
  marginTop: 5,
};
export const AccordionData = (props) => {
  const { title, handleViewClick, handleDownloadClick, type } = props;

  return (
    <>
      <Typography
        sx={{
          bgcolor: "#0875e1",
          color: "#fff",
          paddingLeft: 1,
        }}
      >
        {title || ""}
      </Typography>
      <Box style={{ marginLeft: 20 }}>
        <Box style={{ ...boxStyle }}>
          <Box>
            <Typography style={{ ...styleDetails }}>
              Mismatch Entries
            </Typography>
          </Box>
          <Box>
            <Tooltip title="View" arrow>
              <IconButton
                aria-label="View"
                color="primary"
                onClick={() => handleViewClick(type, 1)}
              >
                <PreviewIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download" arrow>
              <IconButton
                aria-label="Download"
                color="success"
                onClick={() => handleDownloadClick()}
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box style={{ ...boxStyle }}>
          <Box>
            <Typography style={{ ...styleDetails }}>Newly Added</Typography>
          </Box>
          <Box>
            <Tooltip title="View" arrow>
              <IconButton
                aria-label="View"
                color="primary"
                onClick={() => handleViewClick(type, 2)}
              >
                <PreviewIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download" arrow>
              <IconButton
                aria-label="Download"
                color="success"
                onClick={() => handleDownloadClick()}
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box style={{ ...boxStyle }}>
          <Box>
            <Typography style={{ ...styleDetails }}>
              Consolidated View
            </Typography>
          </Box>
          <Box>
            <Tooltip title="View" arrow>
              <IconButton
                aria-label="View"
                color="primary"
                onClick={() => handleViewClick(type, 3)}
              >
                <PreviewIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download" arrow>
              <IconButton
                aria-label="Download"
                color="success"
                onClick={() => handleDownloadClick()}
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </>
  );
};

AccordionData.propTypes = {
  title: PropTypes.any,
  handleViewClick: PropTypes.any,
  handleDownloadClick: PropTypes.any,
  type: PropTypes.any,
};
