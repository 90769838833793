import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { StyledDataGrid } from "../generalFunctions";

const CustomToolbar = ({ businessProcessDetails = [] }) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{
        padding: "0 5px 0 5px",
        marginLeft: 10,
        borderBottom: " 1px solid #f0f0f0",
      }}
    >
      <div style={{ paddingTop: 5, paddingBottom: 10 }}>
        <h6 style={{ marginBottom: "0px", fontSize: "11px" }}>
          {`${businessProcessDetails?.length} items`}
        </h6>
      </div>
    </div>
  );
};

export const DataTableForChart = (props) => {
  const { businessProcessDetails, chartName, height } = props;

  const headerHeight = 50;
  const rowHeight = 45;
  const [columns, setColumns] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setColumns([
      {
        field: "processName",
        headerName: chartName || "Business Process Type",
        headerClassName: "headerStyle",
        align: "left",
        width: 250,
        flex: 1,
      },
      {
        field: "Count",
        headerName: "Count",
        headerClassName: "headerStyle",
        align: "right",
        headerAlign: "right",
        width: 170,
      },
    ]);
  }, [chartName]);
  useEffect(() => {
    if (businessProcessDetails?.length) {
      const updatedData = [...businessProcessDetails]?.map((item, index) => {
        return { ...item, id: index };
      });
      setRows(updatedData);
    }
  }, [businessProcessDetails]);

  const handleCellClick = async (params) => {
    const suiteSelection = params.row.id;
    console.error(suiteSelection);
  };

  return (
    <>
      <CustomToolbar businessProcessDetails={businessProcessDetails} />
      <div
        style={{
          background: "#ffffff",
          overflow: "auto",
          height: height,
          width: "100%",
          flexGrow: 1,
          marginLeft: 10,
        }}
      >
        <StyledDataGrid
          initialState={{}}
          rows={rows}
          columns={columns}
          rowHeight={rowHeight}
          columnHeaderHeight={headerHeight}
          onCellClick={handleCellClick}
          hideFooter
          hideFooterPagination
          hideFooterSelectedRowCount
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          componentsProps={{
            basePopper: {
              sx: {
                "& .MuiTypography-root": { fontSize: "12px" },
              },
            },
          }}
        />
      </div>
    </>
  );
};

DataTableForChart.propTypes = {
  businessProcessDetails: PropTypes.any,
  height: PropTypes.any,
  chartName: PropTypes.any,
};
CustomToolbar.propTypes = {
  businessProcessDetails: PropTypes.any,
};
