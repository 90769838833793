import { Box } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { setRunSearch, setSearchFlag, showError } from "../../store/actions";
import { useNavigate } from "react-router-dom";

export const SearchByTestSuite = () => {
  const searchFlag = true;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State variables for radio button and search text
  const [selectedRadio, setSelectedRadio] = useState("SuiteRunIDRadios");
  const [searchText, setSearchText] = useState("");

  // Event handler for radio button change
  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };

  // Event handler for search button click
  const handleSearch = (id) => {
    const searchTextData = id || searchText;
    if (!/^\d+$/.test(searchTextData)) {
      const errorMsg = "Please enter proper Run ID to Search";
      dispatch(showError(true, errorMsg, "error"));
    } else {
      dispatch(setRunSearch(searchTextData, selectedRadio));
      dispatch(setSearchFlag(searchFlag));
      if (selectedRadio === "scenRunIDRadios") {
        navigate("/workboard");
      }
    }
  };
  const handleReset = async () => {};

  return (
    <Box m={2}>
      <Row>
        {/* <div className="col-md-3 col-sm-12"> */}
        <Row className="mb-2">
          <Col className="col-10">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadios"
                id="SuiteRunIDRadios"
                value="SuiteRunIDRadios"
                aria-labelledby="SuiteRunIDRadios"
                checked={selectedRadio === "SuiteRunIDRadios"}
                onChange={handleRadioChange}
              />
              <label
                className="form-check-label small-select"
                htmlFor="SuiteRunIDRadios"
              >
                Suite Run ID
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadios"
                id="scenRunIDRadios"
                value="scenRunIDRadios"
                aria-labelledby="scenRunIDRadios"
                checked={selectedRadio === "scenRunIDRadios"}
                onChange={handleRadioChange}
              />
              <label
                className="form-check-label small-select"
                htmlFor="scenRunIDRadios"
              >
                Scenario Run ID
              </label>
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col className="col-2">
            <input
              type="text"
              className="form-control small-select"
              placeholder="Search..."
              value={searchText}
              inputMode="numeric"
              pattern="[0-9]*"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Col>
          <Col className="col-2">
            <Box display={"flex"} gap={2}>
              <button
                type="button"
                className="btn btn-primary small-select"
                style={{ width: 30, height: 25 }}
                onClick={() => handleSearch(searchText)}
              >
                Go
              </button>

              <button
                type="button"
                className="btn btn-outline-primary small-select"
                style={{ width: 40, height: 25 }}
                onClick={handleReset}
              >
                Reset
              </button>
            </Box>
          </Col>
        </Row>
        {/* </div> */}
      </Row>
    </Box>
  );
};
