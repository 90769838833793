import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

export const StackedChart = ({
  businessProcessDetails,
  isBar,
  colors,
  isStacked = false,
  height,
}) => {
  const initialChartDetails = {
    series: [],
    options: {
      chart: {
        height: height,
        type: "bar",
      },
      dataLabels: {
        enabled: false,
        formatter: (val) => `${val}%`,
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      colors: colors,
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
      },
      xaxis: {
        categories: [],
        position: "bottom",
        axisBorder: { show: false },
        axisTicks: { show: false },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        labels: {
          show: true,
          style: {
            fontSize: "12px",
            fontWeight: 400,
          },
          rotate: -90,
        },
      },
      yaxis: {
        axisBorder: { show: true },
        axisTicks: { show: true },
        labels: {
          show: true,
          formatter: (val) => val,
        },
      },
      title: {
        text: "",
        floating: true,
        offsetY: 330,
        align: "center",
        style: { color: "#444" },
      },
      responsive: [
        {
          options: { legend: { position: "bottom" } },
        },
      ],
    },
  };

  const [chartData, setChartData] = useState(initialChartDetails);

  useEffect(() => {
    if (businessProcessDetails?.length) {
      const seriesData = [];
      const stackedSeriesData = [];
      const xAxisCategories = [];

      businessProcessDetails.forEach(({ processName, data, Count }) => {
        if (processName !== "Total") {
          if (isStacked) {
            stackedSeriesData.push({
              name: processName || "",
              data: data || [],
            });
          } else {
            seriesData.push(Count || 0);
            xAxisCategories.push(processName || "");
          }
        }
      });

      const series = isStacked
        ? stackedSeriesData
        : [{ name: "Inflation", data: seriesData }];
      const categories = isStacked
        ? ["October", "November", "December", "January", "February", "March"]
        : xAxisCategories;

      setChartData((prevData) => ({
        ...prevData,
        series,
        options: {
          ...prevData.options,
          chart: {
            ...prevData.options.chart,
            stacked: isStacked,
            toolbar: { show: true },
            zoom: { enabled: true },
          },
          fill: { opacity: 1 },
          dataLabels: {
            enabled: isStacked,
            formatter: (val) => val,
            offsetY: isStacked ? 2 : prevData.options.dataLabels.offsetY,
          },
          plotOptions: {
            ...prevData.options.plotOptions,
            bar: isBar
              ? {
                  borderRadius: 4,
                  horizontal: true,
                  dataLabels: { position: "top" },
                }
              : {
                  horizontal: false,
                  borderRadius: isStacked ? 5 : 0,
                  dataLabels: isStacked
                    ? {
                        total: {
                          enabled: true,
                          style: { fontSize: "13px", fontWeight: 900 },
                        },
                      }
                    : { position: "top" },
                },
          },
          xaxis: {
            ...prevData.options.xaxis,
            categories,
          },
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessProcessDetails, isBar, isStacked]);

  return (
    <div style={{ width: "100%" }}>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={height}
      />
    </div>
  );
};

StackedChart.propTypes = {
  businessProcessDetails: PropTypes.array,
  isBar: PropTypes.bool,
  colors: PropTypes.array,
  isStacked: PropTypes.bool,
  height: PropTypes.number,
};
