import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

export const BarChart2 = ({ moduleData, colors, isStacked = false, height }) => {
  console.log("BarChart2 Data:", moduleData);

  if (!Array.isArray(moduleData) || moduleData.length === 0) {
    return <p>No Data Found</p>;
  }

  // Extract categories (dates)
  const categories = moduleData.map((item) => item.date);

  // Extract data series
  const series = [
    {
      name: "Passed",
      data: moduleData.map((item) => item.countSummary.passedCount || 0),
    },
    {
      name: "Failed",
      data: moduleData.map((item) => item.countSummary.failedCount || 0),
    },
    {
      name: "Warning",
      data: moduleData.map((item) => item.countSummary.warningCount || 0),
    },
    {
      name: "Undefined",
      data: moduleData.map((item) => item.countSummary.undefinedCount || 0),
    },
    {
      name: "In Progress",
      data: moduleData.map((item) => item.countSummary.runningCount || 0),
    },
    {
      name: "Cancelled",
      data: moduleData.map((item) => item.countSummary.cancelledCount || 0),
    }
  ];

  // Chart options
  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: isStacked,
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    colors: colors || ["#29CA7D", "#FF3F61", "#FFC53F", "#AAAAAA", "#007BFF", "#6C757D"], // Default colors
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
      },
    },
    dataLabels: { enabled: false },
    xaxis: { categories },
    legend: {
      position: "bottom",
      offsetX: -10,
      offsetY: 0,
    },
    fill: { opacity: 1 },
  };

  return (
    <div style={{ width: "100%" }}>
      <ReactApexChart options={options} series={series} type="bar" height={height} />
    </div>
  );
};

BarChart2.propTypes = {
  moduleData: PropTypes.array.isRequired, // Ensure it's an array
  colors: PropTypes.array,
  isStacked: PropTypes.bool,
  height: PropTypes.number,
};
