import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
} from "reactstrap";
import RunTestmgr from "./runTest";
import Breadcrumbs from "../AHeaderFooterSections/HeaderComponents/Breadcrumb";
import { menuSelectionChange, setLoadingState } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { RecentJobs } from "./RecentJobs";
import { Schedule } from "./Schedule";
import {
  fetchExecutedSuiteRunList,
  fetchSuiteRunListAPI,
  getAllScheduleDetails,
} from "../../store/dashboard/orchestractor";

const TestRunArea = () => {
  document.title = "TestManager";
  const dispatch = useDispatch();
  dispatch(menuSelectionChange(4));
  const [selectedTab, setSelectedTab] = useState(1);
  const [rawData, setRawData] = useState(null); // Initial value can be null or an empty object, depending on your needs
  const [scheduleList, setScheduleList] = useState([]);
  const [executedRunList, setExecutedRunList] = useState([]);

  const tenants = useSelector((state) => state.dashboardData.tenants || []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchSuiteRunListAPI();
      setRawData(data);
    };

    fetchData();
  }, []);

  const getScheduleList = async () => {
    try {
      dispatch(setLoadingState(true));
      const response = await getAllScheduleDetails();
      if (response?.scheduled_jobs?.length) {
        const scheduleJobs = response?.scheduled_jobs;
        setScheduleList(scheduleJobs);
      } else {
        setScheduleList([]);
      }
      dispatch(setLoadingState(false));
    } catch (error) {
      console.error(error);
      dispatch(setLoadingState(false));
    }
  };
  const getExecutedTestRunList = async () => {
    try {
      dispatch(setLoadingState(true));
      const response = await fetchExecutedSuiteRunList();
      if (response?.rows?.length) {
        const testSuiteRunList = response?.rows;
        setExecutedRunList(testSuiteRunList);
      } else {
        setExecutedRunList([]);
      }
      dispatch(setLoadingState(false));
    } catch (error) {
      dispatch(setLoadingState(false));
    }
  };

  const handleTabChange = async (newTabValue) => {
    if (newTabValue === 3) {
      await getScheduleList();
      setSelectedTab(newTabValue);
    } else if (newTabValue === 2) {
      await getExecutedTestRunList();
      setSelectedTab(newTabValue);
    } else {
      setSelectedTab(newTabValue);
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Home" breadcrumbItem="Test Run Manager" />
        <Nav tabs>
          <NavItem>
            <NavLink
              className={selectedTab === 1 ? "active" : ""}
              onClick={() => handleTabChange(1)}
            >
              Test Suite
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={selectedTab === 2 ? "active" : ""}
              onClick={() => handleTabChange(2)}
            >
              Suite Run Job
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={selectedTab === 3 ? "active" : ""}
              onClick={() => handleTabChange(3)}
            >
              Schedule
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={selectedTab}>
          {selectedTab === 1 ? (
            <TabPane tabId={selectedTab}>
              <Row>
                <RunTestmgr rawData={rawData} tenants={tenants} />
              </Row>
            </TabPane>
          ) : (
            <></>
          )}
          {selectedTab === 2 ? (
            <TabPane tabId={selectedTab}>
              <Row>
                <RecentJobs
                  executedRunList={executedRunList}
                  getExecutedTestRunList={getExecutedTestRunList}
                />
              </Row>
            </TabPane>
          ) : (
            <></>
          )}
          {selectedTab === 3 ? (
            <TabPane tabId={selectedTab}>
              <Row>
                <Schedule
                  testSuitDetails={rawData}
                  scheduleList={scheduleList}
                  getScheduleList={getScheduleList}
                />
              </Row>
            </TabPane>
          ) : (
            <></>
          )}
        </TabContent>
      </Container>
    </div>
  );
};

export default TestRunArea;
