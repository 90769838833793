// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.margin-top {
  margin-top: 20px;
}

.wrapper-div {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.selected-item-div-2 {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.selected-item-div-1 {
  width: 100%;
  margin-top: 10px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.selected-item-div {
  background: #ffffff;
  min-height: 50px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 10px;
  border: 1px dashed #000000;
}

.vertical-line {
  width: 1px;
  height: 30px;
  background-color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/pages/comparison.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AACA;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;AAEF;;AAAA;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;AAGF;;AADA;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;AAIF;;AAFA;EACE,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,0BAAA;AAKF;;AAHA;EACE,UAAA;EACA,YAAA;EACA,yBAAA;AAMF","sourcesContent":[".margin-top {\n  margin-top: 20px;\n}\n.wrapper-div {\n  display: flex;\n  text-align: center;\n  align-items: center;\n  justify-content: center;\n}\n.selected-item-div-2 {\n  display: flex;\n  text-align: center;\n  align-items: center;\n  justify-content: center;\n}\n.selected-item-div-1 {\n  width: 100%;\n  margin-top: 10px;\n  display: flex;\n  text-align: center;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 10px;\n}\n.selected-item-div {\n  background: #ffffff;\n  min-height: 50px;\n  width: 300px;\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n  padding: 0 10px;\n  border: 1px dashed #000000;\n}\n.vertical-line {\n  width: 1px;\n  height: 30px;\n  background-color: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
