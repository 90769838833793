import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";
import DashDonutChartSmall from "../Charts/DashdountchartSm";
import { setFAAreaSelection } from "../../../store/dashboard/actions";
import { connect } from "react-redux";

const smallSelect = {
  fontSize: "12px",
  lineHeight: "15px",
  width: "100%",
  borderRadius: "2px",
  padding: "3px",
  marginBottom: "3px",
};

const paddingStyle = {
  paddingTop: "0px",
  paddingRight: "0px",
  paddingBottom: "0px",
  paddingLeft: "0px",
};

const reduceFontSize = { fontSize: "10px" };

const statusFormat = {
  fontSize: "11px",
  paddingBottom: "0px",
  marginBottom: "0px",
  paddingTop: "0px",
  paddingLeft: "0px",
  paddingRight: "0px",
};

const paddingTopBottom = {
  paddingTop: "10px",
  paddingBottom: "5px",
  paddingLeft: "5px",
};

const myBorder = {
  borderRight: "1.4px solid rgb(223, 223, 223)",
  paddingRight: "5px",
  textAlign: "center",
};

const borderStyles = {
  border: "solid",
  borderWidth: "1px",
  borderColor: "#ced4da",
  marginBottom: "5px",
};

const FunAreaCards = ({ faAreas, faAreaSelection, setFAAreaSelection }) => {
  const selectedFAa = faAreas.find((fa) => fa.faId === faAreaSelection);
  const passedCount = selectedFAa.faScenarioRISummary.passedCount;
  const failedCount = selectedFAa.faScenarioRISummary.failedCount;
  const warningCount = selectedFAa.faScenarioRISummary.warningCount;
  const undefinedCount = selectedFAa.faScenarioRISummary.undefinedCount;

  useEffect(() => {}, [faAreaSelection]);

  const handleSuiteChange = async (event) => {
    const faAreaSelectionpull = parseInt(event.target.value);
    setFAAreaSelection(faAreaSelectionpull);
  };

  return (
    <Col xl={3} sm={6} style={{ paddingRight: "0px" }}>
      <Card style={borderStyles}>
        <CardBody style={paddingTopBottom}>
          <h6 style={{ marginBottom: "8px", fontSize: "11px" }}>
            Functional Area
          </h6>
          <Row>
            <Col xl={12}>
              <select
                className="form-select"
                style={smallSelect}
                id="inlineFormSelectPref"
                onChange={handleSuiteChange}
                value={faAreaSelection}
              >
                {faAreas.map((data) => (
                  <option key={data.faId} value={data.faId}>
                    {data.faShortName}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <Row>
            <Col className="col-4" style={myBorder}>
              <Row className="mb-2 row"></Row>
              <p
                className="mb-1"
                style={{ fontWeight: "bold", paddingTop: "10px" }}
              >
                {selectedFAa.faScenarioTotal}
              </p>

              <p style={reduceFontSize}> # Scenario Run</p>
            </Col>

            <Col className="col-4" style={{ paddingLeft: "4px" }}>
              <div id="radialchartfa">
                <DashDonutChartSmall
                  passedCount={passedCount}
                  failedCount={failedCount}
                  warningCount={warningCount}
                  undefinedCount={undefinedCount}
                />
              </div>
            </Col>

            <Col className="col-4" style={{ marginTop: "5px" }}>
              <Row>
                <Col
                  xl={12}
                  style={paddingStyle}
                  className="d-flex align-items-center justify-content-left text-left"
                >
                  <p style={statusFormat}>{passedCount} Passed</p>
                </Col>
              </Row>
              <Row>
                <Col
                  xl={12}
                  style={paddingStyle}
                  className="d-flex align-items-center justify-content-left text-left"
                >
                  <p style={statusFormat}>{failedCount} Failed</p>
                </Col>
              </Row>
              <Row>
                <Col
                  xl={12}
                  style={paddingStyle}
                  className="d-flex align-items-center justify-content-left text-left"
                >
                  <p style={statusFormat}>{warningCount} Warning</p>
                </Col>
              </Row>
              <Row>
                <Col
                  xl={12}
                  style={paddingStyle}
                  className="d-flex align-items-center justify-content-left text-left"
                >
                  <p style={statusFormat}>{undefinedCount} Undefined</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  faAreas: state.dashboardData.faAreas,
  faAreaSelection: state.dashboardData.faAreaSelection,
});

export default connect(mapStateToProps, { setFAAreaSelection })(FunAreaCards);

FunAreaCards.propTypes = {
  faAreas: PropTypes.any,
  faAreaSelection: PropTypes.any,
  setFAAreaSelection: PropTypes.any,
};
