// Importing necessary modules and components from external libraries and files.
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row, InputGroup } from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import {
  getTenants,
  setTenantSelection,
  showError,
} from "../../../store/dashboard/actions";
import { tenantfetch } from "../../../store/dashboard/orchestractor";
import { initDate } from "../../generalFunctions";

// CSS style objects for different components.
const smallSelect = {
  fontSize: "12px",
  lineHeight: "15px",
  width: "100%",
  borderRadius: "2px",
  padding: "3px",
  marginBottom: "3px",
};
const paddingBottomTop = {
  paddingTop: "10px",
  paddingBottom: "5px",
  paddingLeft: "10px",
};
const borderStyles = {
  border: "solid",
  borderWidth: "1px",
  borderColor: "#ced4da",
  marginBottom: "5px",
};

// Functional component definition.
const Wbfilters = ({
  tenants,
  selectedTenant,
  startDate,
  endDate,
  setTenantSelection,
}) => {
  // Local state variables for managing user input and local state changes.
  const [localSelectedTenant, setLocalSelectedTenant] = useState("");
  const [localStartDate, setLocalStartDate] = useState(null);
  const [localEndDate, setLocalEndDate] = useState(null);
  const dispatch = useDispatch();

  // Effect hook to synchronize local state with props from Redux store.
  useEffect(() => {
    setLocalSelectedTenant(selectedTenant);
    setLocalStartDate(startDate);
    setLocalEndDate(endDate);
  }, [selectedTenant, startDate, endDate]);

  // Event handler for tenant selection change.
  const handleTenantChange = (event) => {
    setLocalSelectedTenant(event.target.value);
  };

  // Event handler for form submission.
  const handleFormSubmit = async () => {
    // Formatting selected dates for consistency.
    const formattedStartDate = localStartDate
      ? moment(localStartDate).format("YYYY-MM-DD")
      : null;
    const formattedEndDate = localEndDate
      ? moment(localEndDate).format("YYYY-MM-DD")
      : null;

    if (formattedStartDate <= formattedEndDate) {
      // Dispatching action to update tenant selection in Redux store.
      setTenantSelection(
        parseInt(localSelectedTenant, 10),
        formattedStartDate,
        formattedEndDate
      );
    } else {
      const message = "From-Date should not great than or equalto To-Date";
      dispatch(showError(true, message, "error"));
      setLocalStartDate(startDate);
      setLocalEndDate(endDate);
    }
  };

  // Event handler for reset button click.
  const handleReset = async () => {
    // Fetching initial tenant data from some external source.
    const tenantInit = await tenantfetch();

    // Dispatching actions to update Redux store with tenants and reset selection.
    dispatch(getTenants(tenantInit.tenants));
    dispatch(
      setTenantSelection(
        tenantInit.tenantSelection,
        initDate().startDate,
        initDate().endDate
      )
    );
  };
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate());
  // Rendering the UI components with JSX.
  return (
    <Col xl={4} sm={6} style={{ paddingRight: "0px" }}>
      <Card style={borderStyles}>
        <CardBody style={paddingBottomTop}>
          <h6 style={{ marginBottom: "8px", fontSize: "11px" }}>Tenant</h6>
          {/* Row for tenant selection */}
          <Row>
            <Col xl={9}>
              <Row>
                <Col xl={12}>
                  {/* Dropdown to select a tenant */}
                  <select
                    style={smallSelect}
                    className="form-select"
                    id="inlineFormSelectPref"
                    value={localSelectedTenant}
                    onChange={handleTenantChange}
                  >
                    {tenants.map((tenant) => (
                      <option key={tenant.tenantId} value={tenant.tenantId}>
                        {tenant.tenantName}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
              {/* Row for date selection */}
              <Row style={{ marginTop: "15px" }}>
                <Col xl={6} style={{ marginBottom: "12px" }}>
                  <h6 style={{ marginBottom: "8px", fontSize: "11px" }}>
                    From
                  </h6>
                  <InputGroup>
                    <Flatpickr
                      id="startDatepicker"
                      className="form-control-cal d-block"
                      placeholder="Enter to Date"
                      options={{
                        altInput: true,
                        altFormat: "d-M-Y",
                        dateFormat: "Y-m-d",
                        onChange: (selectedDates) =>
                          setLocalStartDate(selectedDates[0]),
                        disable: [
                          {
                            from: tomorrow,
                            to: new Date(2099, 0, 1), // You can adjust the 'to' date as needed
                          },
                        ],
                      }}
                      value={localStartDate}
                    />
                  </InputGroup>
                </Col>
                <Col xl={6} style={{ marginBottom: "12px" }}>
                  <h6 style={{ marginBottom: "8px", fontSize: "11px" }}>To</h6>
                  <InputGroup>
                    <Flatpickr
                      id="endDatepicker"
                      className="form-control-cal d-block"
                      placeholder="Enter to Date"
                      options={{
                        altInput: true,
                        altFormat: "d-M-Y",
                        dateFormat: "Y-m-d",
                        onChange: (selectedDates) =>
                          setLocalEndDate(selectedDates[0]),
                        disable: [
                          {
                            from: tomorrow,
                            to: new Date(2099, 0, 1), // You can adjust the 'to' date as needed
                          },
                        ],
                      }}
                      value={localEndDate}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Col>

            {/* Buttons for form submission and reset */}
            <Col xl={3}>
              <div>
                <button
                  style={smallSelect}
                  type="button"
                  className="btn btn-primary"
                  onClick={handleFormSubmit}
                >
                  Submit
                </button>
              </div>
              <div>
                <button
                  style={smallSelect}
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

// Mapping Redux state to component props.
const mapStateToProps = (state) => ({
  tenants: Object.values(state.dashboardData.tenants),
  selectedTenant: state.dashboardData.tenantSelection || "",
  startDate: state.dashboardData.startDate || null,
  endDate: state.dashboardData.endDate || null,
});

// Mapping dispatch actions to component props.
const mapDispatchToProps = (dispatch) => ({
  setTenantSelection: (tenantId, startDate, endDate) =>
    dispatch(setTenantSelection(tenantId, startDate, endDate)),
});

// Connecting the component to the Redux store.
export default connect(mapStateToProps, mapDispatchToProps)(Wbfilters);

Wbfilters.propTypes = {
  tenants: PropTypes.any,
  selectedTenant: PropTypes.any,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  setTenantSelection: PropTypes.any,
};
