import React, { useState, useEffect } from "react"; // Import React, useState, and useEffect from React library
import { useSelector, useDispatch } from "react-redux"; // Import useSelector and useDispatch hooks from react-redux
import { showError } from "../../store/dashboard/actions"; // Import showError action from "../store/dashboard/actions"

const GlobalErrorMsg = () => {
  // Define the GlobalErrorMsg component as a functional component
  const [open, setOpen] = useState(false); // Declare state variable 'open' and its setter function using useState hook with initial value 'false'
  const error_flag = useSelector((state) => state.dashboardData.error_flag); // Extract 'error_flag' from Redux store using useSelector hook
  const error_msg = useSelector((state) => state.dashboardData.error_msg); // Extract 'error_msg' from Redux store using useSelector hook
  const error_type = useSelector((state) => state.dashboardData.error_type); // Extract 'error_type' from Redux store using useSelector hook
  const dispatch = useDispatch(); // Create a 'dispatch' function using useDispatch hook

  useEffect(() => {
    // Effect to set the 'open' state based on the 'error_flag' value from Redux store
    setOpen(error_flag);
  }, [error_flag]);

  const overlayStyles = {
    // Define styles for the overlay

    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
    color: "#fff",
    fontSize: "12px",
  };

  const cardHeaderStyles = {
    // Define styles for the card header based on error_type
    background: error_type === "info" ? "#0ac074" : "#ff3d60",
  };

  const handleClose = () => {
    // Function to handle closing the error message
    dispatch(showError(false, "", "")); // Dispatch the showError action to update 'error_flag' to 'false' and clear 'error_msg' and 'error_type'
    setOpen(false); // Close the error message by setting 'open' state to 'false'
  };

  // Return the error message overlay when 'open' is true, otherwise return null
  return open ? (
    <div style={overlayStyles}>
      {/* Overlay container */}
      <div className="container">
        {/* Bootstrap container */}
        <div className="row justify-content-center">
          {/* Center the error card */}
          <div className="col-lg-4">
            {/* Limit card width */}
            <div className="card alert border p-0 mb-0 bg-white">
              {/* Error card */}
              <div className="card-header" style={cardHeaderStyles}>
                {/* Card header */}
                <div className="d-flex">
                  <div className="flex-grow-1">
                    {error_type === "info" ? "Info" : "Error"}{" "}
                    {/* Display error_type */}
                  </div>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      onClick={handleClose}
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    ></button>
                  </div>
                </div>

                {/* Close button */}
              </div>

              <div className="card-body">
                <div className="text-center">
                  <p className="text-black mb-0">{error_msg}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null; // Return the error message overlay or null
};

export default GlobalErrorMsg; // Export the GlobalErrorMsg component
