// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlight:hover {
  background-color: rgba(233, 237, 240, 0.6);
}

.highlight {
  padding-right: 5px;
  padding-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/LandingPage/landpagestyle.scss"],"names":[],"mappings":"AAAA;EACE,0CAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;AACF","sourcesContent":[".highlight:hover {\n  background-color: rgba(233, 237, 240, 0.6);\n}\n\n.highlight {\n  padding-right: 5px;\n  padding-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
