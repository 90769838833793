/**
 * Common functions
 */

export const getTenantList = (tenants = []) => {
  const tenantDetails = tenants.map((rec) => {
    return {
      ...rec,
      id: rec?.tenantId ? rec.tenantId.toString() : "",
      content: rec?.tenantName || "",
    };
  });
  return tenantDetails;
};

export const getDetailsForSelectedRecord = (
  fullList = {},
  selection = null,
  type = "",
  selectedFuncArea = null
) => {
  try {
    const { suiteScriptListByFA = [] } = fullList;
    const selectionId = parseInt(selection?.id);
    if (type === "functionalArea") {
      return suiteScriptListByFA.map((rec) => {
        return {
          id: rec?.faId ? rec.faId.toString() : "",
          content: rec?.faShortName || "",
        };
      });
    } else if (type === "category") {
      let category = [];
      suiteScriptListByFA.forEach((rec) => {
        if (rec?.faId === selectionId) {
          category = rec?.modules?.map((item, index) => {
            const id = index + 1;
            return {
              id: id.toString(),
              content: item?.moduleName || "",
            };
          });
        }
      });
      return category;
    } else if (type === "testSuite") {
      const functionalAreaId = parseInt(selectedFuncArea?.id);
      let testSuite = [];
      suiteScriptListByFA.forEach((rec) => {
        if (rec?.faId === functionalAreaId) {
          rec?.modules?.forEach((item, index) => {
            const id = index + 1;
            if (id === selectionId) {
              testSuite = item?.suiteScripts?.map((test) => {
                return {
                  id: test?.suiteId?.toString(),
                  content: test?.suiteName || "",
                };
              });
            }
          });
        }
      });
      return testSuite;
    }
  } catch (error) {
    console.error(error);
  }
};

export const copy = (
  source,
  destination,
  droppableSource,
  droppableDestination
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const item = sourceClone[droppableSource.index];

  destClone.splice(droppableDestination.index, 0, {
    ...item,
  });

  return destClone;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const move = (
  source,
  destination,
  droppableSource,
  droppableDestination
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
