import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import {
  downloadTemplateFileForTestSuite,
  suiteRunAPI,
  uploadFile,
} from "../../store/dashboard/orchestractor";
import { useDispatch } from "react-redux";
import { setLoadingState, showError } from "../../store/dashboard/actions";
import { Box, FormControl, Link, Select, Typography } from "@mui/material";
import { FileUpload } from "./FileUpload";
import { useProfile } from "../../Hooks/UserHooks";
import { capitalizeFLetter, sortByName } from "../../config/util";

const getLabel = (functionalAreaName) => {
  return functionalAreaName === "HCM" ? "Module" : "Category";
};
const getJustifyContent = (fileUploadError, fileUploadSuccess) => {
  return fileUploadError?.isError || fileUploadSuccess?.isError
    ? "space-between"
    : "flex-end";
};

const RunTestmgr = (props) => {
  const { rawData, tenants } = props;
  const dispatch = useDispatch();
  const { userProfile } = useProfile();
  const [selectedFileDetails, setSelectedFileDetails] = useState([]);
  const [selectedSuiteScripts, setSelectedSuiteScripts] = useState([]);
  const [loader, setLoader] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [fileUploadError, setFileUploadError] = useState({
    isError: false,
    errorMessage: "",
    type: 0,
  });
  const [fileUploadSuccess, setFileUploadSuccess] = useState({
    isError: false,
    errorMessage: "",
  });
  const [fileUploaded, setFileUploaded] = useState(true);
  useEffect(() => {
    if (rawData?.suiteScriptListByFA?.length) {
      getDefaultTestSuitId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData]);

  const getDefaultTestSuitId = async () => {
    const defaultFunctionArea = rawData?.suiteScriptListByFA[0];
    const defaultModules = defaultFunctionArea?.modules[0];
    const defaultSelection = defaultModules?.suiteScripts || [];

    const defaultSuiteId = defaultSelection[0]?.suiteId || 0;
    const defaultSuiteName = defaultSelection[0]?.suiteName || "";

    const updatedFormValue = {
      ...formDetails,
      functionalArea: defaultFunctionArea?.faId || "",
      functionalAreaName: defaultFunctionArea?.faShortName || "",
      testSuite: parseInt(defaultSuiteId, 10) || 0,
      testSuiteName: defaultSuiteName,
      selectedTenant: tenants?.[0]?.tenantId || 1,
      suiteScript: defaultSelection[0] || null,
      item: defaultFunctionArea,
      moduleList: defaultFunctionArea?.modules || [],
      moduleName: defaultModules?.moduleName || "",
      fileList: [],
      selectedFile: null,
    };

    setSelectedSuiteScripts(defaultSelection);
    setFormDetails(updatedFormValue);
  };

  const handleRunClick = async () => {
    const {
      testSuite,
      testSuiteName,
      functionalArea,
      functionalAreaName,
      selectedTenant,
    } = formDetails;

    const filename = selectedFileDetails[0]?.fileDetails?.name || "";
    dispatch(setLoadingState(true));

    try {
      const response = await suiteRunAPI(
        functionalArea,
        testSuite,
        selectedTenant,
        filename
      );

      if (response?.error) {
        dispatch(showError(true, `${response.error}`, "error"));
      } else {
        const message = `"${functionalAreaName}-${testSuiteName}" is initiated`;
        dispatch(showError(true, message, "info"));
      }
    } catch (error) {
      dispatch(
        showError(
          true,
          `An unexpected error occurred: ${error.message}`,
          "error"
        )
      );
    } finally {
      setFileUploaded(true);
      setSelectedFileDetails([]);
      dispatch(setLoadingState(false));
    }
  };

  const getSelectedFileDetails = (suiteScript, item) => {
    if (selectedFileDetails?.length) {
      const selectedFile = selectedFileDetails?.find(
        (rec) => rec?.suiteId === formDetails?.testSuite
      );
      return selectedFile?.fileDetails?.name || "";
    } else {
      return "";
    }
  };
  const handleFileUpload = async (files) => {
    if (!files?.length) {
      showErrorFunc("Please select a file...", 1);
      return;
    }

    const { testSuite, testSuiteName, functionalArea } = formDetails;
    const fileName = testSuiteName
      ? testSuiteName.replace(/[ -]/g, "")
      : "test_template";
    const uploadedFileName = files[0].name.replace(/[_ -]/g, "");
    const expectedFileName = `${fileName}.xlsx`;

    if (uploadedFileName.toLowerCase() !== expectedFileName.toLowerCase()) {
      showErrorFunc("Please select the correct file...", 1);
      return;
    }

    setLoader(true);
    dispatch(setLoadingState(true));

    try {
      const formData = new FormData();
      formData.append("file", files[0]);

      const response = await uploadFile(functionalArea, testSuite, formData);

      if (response) {
        handleSuccess(files[0]);
      } else {
        showErrorFunc("Error with file upload...", 2);
      }
    } catch (error) {
      console.error(error);
      showErrorFunc("An unexpected error occurred.", 1);
    } finally {
      setLoader(false);
      dispatch(setLoadingState(false));
    }
  };

  const showErrorFunc = (message, type = 1) => {
    setFileUploadError({ isError: true, errorMessage: message, type });
    setFileUploadSuccess({ isError: false, errorMessage: "" });
    setFileUploaded(true);
  };

  const handleSuccess = (selectedFile) => {
    setFileUploadSuccess({
      isError: true,
      errorMessage: "File uploaded successfully.",
    });
    setFileUploadError({ isError: false, errorMessage: "", type: 0 });
    setFileUploaded(false);
    setSelectedFileDetails([
      {
        fileDetails: selectedFile,
        suiteId: formDetails.testSuite || 0,
        faId: formDetails.functionalArea || 0,
      },
    ]);

    setTimeout(() => {
      setFileUploadSuccess({ isError: false, errorMessage: "" });
    }, 10000);
  };

  const handleRemoveUploadedFile = (event, suiteScript) => {
    try {
      if (!suiteScript?.suiteId) {
        console.info("Please remove the correct file...");
        return;
      }

      const updatedFileDetails =
        selectedFileDetails?.filter(
          (rec) => rec?.suiteId !== suiteScript.suiteId
        ) || [];

      setSelectedFileDetails(updatedFileDetails);
    } catch (error) {
      console.error("Error removing file:", error);
    }
  };

  const getTestSuiteName = (value = 0, funcAreaId = 0) => {
    const data = rawData?.suiteScriptListByFA || [];

    const testSuite = data.find((item) => item?.faId === parseInt(funcAreaId));

    if (
      !testSuite ||
      !Array.isArray(testSuite.modules) ||
      testSuite.modules.length === 0
    )
      return "";

    const suiteScripts = testSuite.modules.flatMap(
      (module) => module?.suiteScripts || []
    );
    const suiteScript = suiteScripts.find(
      (script) => script?.suiteId === value
    );

    return suiteScript?.suiteName || "";
  };

  const handleChangeFunctionalArea = (event) => {
    const { name, value } = event.target;
    const idVal = value ? parseInt(value) : 0;

    const selectedFunctionalAreaDetails = rawData?.suiteScriptListByFA.find(
      (item) => item.faId === idVal
    );

    const selectedFAreaModules = selectedFunctionalAreaDetails?.modules || [];
    const selectedSuitScripts = getFirstSuiteScript(selectedFAreaModules);
    const functionalAreaName = selectedFunctionalAreaDetails?.faShortName || "";
    const defaultModuleName = selectedFAreaModules[0]?.moduleName || "";

    updateState(
      selectedFAreaModules,
      selectedSuitScripts,
      functionalAreaName,
      idVal,
      name,
      defaultModuleName
    );
  };

  const getFirstSuiteScript = (modules) => {
    return modules.length && modules[0]?.suiteScripts?.length
      ? modules[0]?.suiteScripts[0]
      : null;
  };

  const updateState = (
    modules,
    selectedSuitScripts,
    functionalAreaName,
    idVal,
    name,
    defaultModuleName
  ) => {
    setFileUploaded(true);
    setFileUploadSuccess({ isError: false, errorMessage: "" });
    setSelectedSuiteScripts(modules[0]?.suiteScripts || []);

    setFormDetails({
      ...formDetails,
      [name]: idVal,
      functionalAreaName,
      testSuite: selectedSuitScripts?.suiteId,
      testSuiteName: selectedSuitScripts?.suiteName,
      fileDetails: null,
      moduleList: modules,
      moduleName: defaultModuleName,
    });
  };

  const handleChangeModule = (event) => {
    const { name, value } = event.target;
    const { functionalArea, functionalAreaName } = formDetails || {};

    const selectedFunctionalAreaDetails =
      findFunctionalAreaDetails(functionalArea);
    const selectedFAreaModule = findModule(
      selectedFunctionalAreaDetails,
      value
    );
    const selectedSuiteScript = getFirstSuiteScriptFunc(selectedFAreaModule);

    updateStateFunc(selectedSuiteScript, name, value, functionalAreaName);
  };

  const findFunctionalAreaDetails = (functionalArea) => {
    return rawData?.suiteScriptListByFA.find(
      (item) => item.faId === functionalArea
    );
  };

  const findModule = (functionalAreaDetails, moduleName) => {
    return (
      functionalAreaDetails?.modules?.find(
        (mod) => mod?.moduleName === moduleName
      ) || {}
    );
  };

  const getFirstSuiteScriptFunc = (module) => {
    return module?.suiteScripts || [];
  };

  const updateStateFunc = (
    selectedSuiteScript,
    name,
    value,
    functionalAreaName
  ) => {
    setFileUploaded(true);
    setFileUploadSuccess({ isError: false, errorMessage: "" });
    setSelectedSuiteScripts(selectedSuiteScript || []);

    setFormDetails({
      ...formDetails,
      [name]: value,
      functionalAreaName: functionalAreaName || "",
      testSuite: selectedSuiteScript?.[0]?.suiteId,
      testSuiteName: selectedSuiteScript?.[0]?.suiteName,
      fileDetails: null,
    });
  };

  const handleChangeTestSuite = (event) => {
    const { name, value } = event.target;
    const idVal = value ? parseInt(value) : 0;
    const testSuiteName = getTestSuiteName(idVal, formDetails?.functionalArea);
    setFileUploaded(true);
    setFileUploadSuccess({
      isError: false,
      errorMessage: "",
    });
    setFormDetails({
      ...formDetails,
      [name]: idVal,
      testSuiteName: testSuiteName || "",
      fileDetails: null,
    });
  };
  const handleTenantChange = (event) => {
    const { name, value } = event.target;
    const idVal = value ? parseInt(value) : 0;
    setFormDetails({
      ...formDetails,
      [name]: idVal,
    });
  };
  const downloadTestSuiteTemplate = async (suiteScript, item) => {
    try {
      dispatch(setLoadingState(true));
      const resp = await downloadTemplateFileForTestSuite(
        formDetails?.functionalArea,
        formDetails?.testSuite
      );
      if (resp?.data?.fileUrl) {
        const url = resp?.data?.fileUrl;
        const a = document.createElement("a");
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        dispatch(setLoadingState(false));
      }
    } catch (error) {
      console.error(error);
      dispatch(setLoadingState(false));
    }
  };
  const handleViewErrorClick = (event) => {
    event.preventDefault();
    console.log("View Details...");
  };

  return (
    <>
      {loader || !rawData ? <span></span> : null}
      <Col xl={12}>
        <Card>
          <CardBody>
            <CardTitle className="h4">
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="page-title-right ml-auto"
                  style={{ display: "flex" }}
                >
                  <div style={{ width: "250px" }}>Suite Script Listing</div>
                </div>
              </div>
            </CardTitle>
            <div style={{ background: "#ffffff" }}>
              <Row>
                <div className="col-md-6 col-sm-12">
                  <Row className="mb-2 row">
                    <div className="card-title-desc-filters">
                      Click the Functional Areas below to expand/collapse
                    </div>
                  </Row>
                  <Row className="mb-2 row">
                    <Col className="col-3">
                      <label
                        className="small-select"
                        htmlFor="run-test-tenant-select"
                      >
                        Choose your Tenant
                      </label>
                    </Col>
                    <Col className="col-9">
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControl
                          sx={{
                            m: 1,
                            minWidth: 350,
                            minHeight: 20,
                            margin: "3px",
                          }}
                        >
                          <Select
                            native
                            aria-labelledby="run-test-tenant-select"
                            defaultValue=""
                            value={formDetails?.selectedTenant || null}
                            name="selectedTenant"
                            onChange={handleTenantChange}
                            style={{ height: 25 }}
                            className="font-size-12"
                          >
                            {tenants?.length &&
                              tenants.map((tenant) => {
                                return (
                                  <option
                                    className="font-size-12"
                                    key={tenant.tenantId || 0}
                                    value={tenant.tenantId || 0}
                                  >
                                    {tenant.tenantName || ""}
                                  </option>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Box>
                    </Col>
                  </Row>
                  <Row className="mb-2 row">
                    <Col className="col-3">
                      <label
                        className="small-select"
                        htmlFor="run-test-functional-area"
                      >
                        Functional Area
                      </label>
                    </Col>
                    <Col className="col-9">
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControl
                          sx={{
                            m: 1,
                            minWidth: 350,
                            minHeight: 20,
                            margin: "3px",
                          }}
                        >
                          <Select
                            native
                            aria-labelledby="run-test-functional-area"
                            defaultValue=""
                            value={formDetails?.functionalArea || null}
                            name="functionalArea"
                            onChange={handleChangeFunctionalArea}
                            style={{ height: 25 }}
                            className="font-size-12"
                          >
                            {rawData?.suiteScriptListByFA?.length &&
                              rawData?.suiteScriptListByFA
                                ?.filter((rec) => rec?.modules?.length)
                                ?.map((testSuite) => {
                                  return (
                                    <option
                                      className="font-size-12"
                                      key={testSuite.faId || 0}
                                      value={testSuite.faId || 0}
                                    >
                                      {testSuite?.faShortName || ""}
                                    </option>
                                  );
                                })}
                          </Select>
                        </FormControl>
                      </Box>
                    </Col>
                  </Row>
                  {formDetails?.moduleList?.[0]?.moduleName !== "default" ? (
                    <Row className="mb-2 row">
                      <Col className="col-3">
                        <label
                          className="small-select"
                          htmlFor="run-test-module-category"
                        >
                          {getLabel(formDetails?.functionalAreaName)}
                        </label>
                      </Col>
                      <Col className="col-9">
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FormControl
                            sx={{
                              m: 1,
                              minWidth: 350,
                              minHeight: 20,
                              margin: "3px",
                            }}
                          >
                            <Select
                              native
                              aria-labelledby="run-test-module-category"
                              defaultValue=""
                              value={formDetails?.moduleName || null}
                              name="moduleName"
                              onChange={handleChangeModule}
                              style={{ height: 25 }}
                              className="font-size-12"
                            >
                              {sortByName(
                                formDetails?.moduleList || [],
                                "moduleName"
                              ).map((rec, index) => {
                                return (
                                  <option
                                    className="font-size-12"
                                    key={`${index}_${
                                      formDetails?.functionalArea
                                    }_${rec?.moduleName || ""}`}
                                    value={rec?.moduleName || ""}
                                  >
                                    {capitalizeFLetter(rec?.moduleName || "")}
                                  </option>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  <Row className="mb-2 row">
                    <Col className="col-3">
                      <label
                        className="small-select"
                        htmlFor="run-test-test-suite"
                      >
                        Test Suite
                      </label>
                    </Col>
                    <Col className="col-9">
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControl
                          sx={{
                            m: 1,
                            minWidth: 350,
                            minHeight: 20,
                            margin: "3px",
                          }}
                        >
                          <Select
                            native
                            aria-labelledby="run-test-test-suite"
                            defaultValue=""
                            value={formDetails?.testSuite || null}
                            name="testSuite"
                            onChange={handleChangeTestSuite}
                            style={{ height: 25 }}
                            className="font-size-12"
                          >
                            {sortByName(
                              selectedSuiteScripts || [],
                              "suiteName"
                            ).map((suiteScript) => {
                              return (
                                <option
                                  className="font-size-12"
                                  key={suiteScript?.suiteId || 0}
                                  value={suiteScript?.suiteId || 0}
                                >
                                  {suiteScript?.suiteName || ""}
                                </option>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <button
                          className="btn btn-success btn-sm waves-effect waves-light"
                          style={{ width: 80, marginLeft: "10px" }}
                          onClick={() =>
                            downloadTestSuiteTemplate(
                              formDetails?.suiteScript,
                              formDetails?.item
                            )
                          }
                        >
                          Download
                        </button>
                      </Box>
                    </Col>
                  </Row>
                </div>
                <div className="col-md-6 col-sm-12 ">
                  {!userProfile?.user?.viewerRole && (
                    <>
                      <Box>
                        <FileUpload
                          formDetails={formDetails}
                          handleFileUpload={(files) => handleFileUpload(files)}
                          getSelectedFileDetails={getSelectedFileDetails}
                          handleRemoveUploadedFile={handleRemoveUploadedFile}
                        />
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: getJustifyContent(
                            fileUploadError,
                            fileUploadSuccess
                          ),
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        {fileUploadError?.isError ? (
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              style={{ color: "#D32F2F", marginRight: "10px" }}
                            >
                              {fileUploadError?.errorMessage}
                            </Typography>
                            {fileUploadError?.type !== 1 ? (
                              <Link href="#" onClick={handleViewErrorClick}>
                                View Error
                              </Link>
                            ) : (
                              <></>
                            )}
                          </Box>
                        ) : (
                          <></>
                        )}
                        {fileUploadSuccess?.isError ? (
                          <Box>
                            <Typography
                              variant="body1"
                              paragraph
                              style={{ color: "#0ac074" }}
                            >
                              {fileUploadSuccess?.errorMessage}
                            </Typography>
                          </Box>
                        ) : (
                          <></>
                        )}
                        <button
                          className="btn btn-success btn-sm waves-effect waves-light"
                          style={{ width: 61 }}
                          disabled={fileUploaded}
                          onClick={() => handleRunClick()}
                        >
                          Run
                        </button>
                      </Box>
                    </>
                  )}
                </div>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default RunTestmgr;

RunTestmgr.propTypes = {
  rawData: PropTypes.any,
  tenants: PropTypes.any,
};
