import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

const FrequencySelect = ({ frequency, onChange }) => (
  <Box style={{ display: "flex", alignItems: "center" }}>
    <FormLabel className="font-size-12" style={{ width: 130 }}>
      Run Frequency
    </FormLabel>
    <FormControl sx={{ m: 1, minWidth: 350, margin: "3px" }}>
      <Select
        defaultValue=""
        value={frequency}
        onChange={onChange}
        name="testSuiteFrequency"
        className="font-size-12"
      >
        <MenuItem className="font-size-12" value={1}>
          Daily Recurrence
        </MenuItem>
        <MenuItem className="font-size-12" value={2}>
          Weekly Recurrence
        </MenuItem>
        <MenuItem className="font-size-12" value={3}>
          Monthly Recurrence
        </MenuItem>
      </Select>
    </FormControl>
  </Box>
);

const RecurrenceType = ({ recurrenceType, onChange }) => (
  <RadioGroup
    row
    value={recurrenceType}
    name="radio-buttons-group"
    onChange={onChange}
  >
    <FormControlLabel
      value={1}
      control={<Radio size="small" />}
      label="Recurs Every Weekday"
    />
    <FormControlLabel
      value={2}
      control={<Radio size="small" />}
      label="Recurs Every x Day(s)"
    />
  </RadioGroup>
);

const TimeZoneSelect = ({ timezoneData, selectedTimezone, onChange }) => (
  <FormControl sx={{ m: 1, minWidth: 350, margin: "3px" }}>
    <Select
      defaultValue=""
      value={selectedTimezone}
      onChange={onChange}
      name="testSuiteTimezone"
      className="font-size-12"
    >
      {timezoneData.map((item) => (
        <MenuItem key={item?.id} value={item?.id}>
          {item?.text}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const DateSelect = ({ label, selectedDate, setDate }) => (
  <Box style={{ display: "flex", alignItems: "center" }}>
    <FormLabel className="font-size-12" style={{ width: 130 }}>
      {label}
    </FormLabel>
    <FormControl sx={{ m: 1, minWidth: 350, margin: "3px" }}>
      <Flatpickr
        className="form-control-cal d-block font-size-12"
        placeholder="DD-MMM-YYYY"
        options={{
          altInput: true,
          altFormat: "d-M-Y",
          dateFormat: "Y-m-d",
          onChange: ([date]) => setDate(date),
        }}
        value={selectedDate}
      />
    </FormControl>
  </Box>
);

export const CreateSchedule = ({
  open,
  handleClose,
  formDetails,
  handleChangeTestSuite,
  testSuitDetails,
  handleChangeTestSuiteFrequency,
  recurrenceConstant,
  handleChange,
  handleWeeklyRecurrenceValueChange,
  daysOfWeek,
  handleWeekChange,
  names,
  timeList,
  timezoneData,
  handleChangeTimeZone,
  handleChangeTimeSelection,
  handleRecurrenceTypeValueChange,
  handleCreateSchedule,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const renderFrequencyContent = () => {
    switch (formDetails?.testSuiteFrequency) {
      case 1:
        return (
          <>
            <Typography className="font-size-14" variant="h6">
              <strong>{recurrenceConstant[0]}</strong>
            </Typography>
            <RecurrenceType
              recurrenceType={formDetails?.recurrenceType}
              onChange={handleChange}
            />
            {formDetails?.recurrenceType === 2 && (
              <input
                type="number"
                min="0"
                className="form-control font-size-14"
                value={formDetails?.recurrenceTypeValue || 0}
                name="recurrenceTypeValue"
                onChange={handleRecurrenceTypeValueChange}
              />
            )}
          </>
        );
      case 2:
        return (
          <>
            <Typography className="font-size-14" variant="h6">
              <strong>{recurrenceConstant[1]}</strong>
            </Typography>
            <input
              type="number"
              min="0"
              className="form-control font-size-12"
              value={formDetails?.weeklyRecurrenceValue || 0}
              name="weeklyRecurrenceValue"
              onChange={handleWeeklyRecurrenceValueChange}
            />
            <FormLabel className="font-size-12" style={{ width: 130 }}>
              Day(s) of the Week
            </FormLabel>
            <FormControl sx={{ m: 1, minWidth: 350, margin: "3px" }}>
              <Select
                multiple
                value={daysOfWeek}
                onChange={handleWeekChange}
                renderValue={(selected) => selected.join(", ")}
                className="font-size-12"
              >
                {names.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={daysOfWeek.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        );
      case 3:
        return (
          <>
            <Typography className="font-size-14" variant="h6">
              <strong>{recurrenceConstant[2]}</strong>
            </Typography>
            {/* Monthly recurrence content */}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>
        <Typography className="font-size-18" variant="h5">
          <strong>Schedule Test Suite</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FrequencySelect
          frequency={formDetails?.testSuiteFrequency}
          onChange={handleChangeTestSuiteFrequency}
        />
        {formDetails?.testSuiteFrequency && renderFrequencyContent()}
        {/* Time and timezone selection */}
        <TimeZoneSelect
          timezoneData={timezoneData}
          selectedTimezone={formDetails?.testSuiteTimezone}
          onChange={handleChangeTimeZone}
        />
        <DateSelect
          label="Start Date"
          selectedDate={startDate}
          setDate={setStartDate}
        />
        <DateSelect
          label="End Date"
          selectedDate={endDate}
          setDate={setEndDate}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleCreateSchedule}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

FrequencySelect.propTypes = {
  frequency: PropTypes.any,
  onChange: PropTypes.any,
};
RecurrenceType.propTypes = {
  recurrenceType: PropTypes.any,
  onChange: PropTypes.any,
};
TimeZoneSelect.propTypes = {
  timezoneData: PropTypes.any,
  selectedTimezone: PropTypes.any,
  onChange: PropTypes.any,
};
DateSelect.propTypes = {
  label: PropTypes.any,
  selectedDate: PropTypes.any,
  setDate: PropTypes.any,
};
CreateSchedule.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.any,
  formDetails: PropTypes.any,
  handleChangeTestSuite: PropTypes.any,
  testSuitDetails: PropTypes.any,
  handleChangeTestSuiteFrequency: PropTypes.any,
  recurrenceConstant: PropTypes.any,
  handleChange: PropTypes.any,
  daysOfWeek: PropTypes.any,
  handleWeekChange: PropTypes.any,
  names: PropTypes.any,
  timeList: PropTypes.any,
  timezoneData: PropTypes.any,
  startDate: PropTypes.any,
  setStartDate: PropTypes.any,
  endDate: PropTypes.any,
  setEndDate: PropTypes.any,
  handleChangeTimeZone: PropTypes.any,
  handleChangeTimeSelection: PropTypes.any,
  handleRecurrenceTypeValueChange: PropTypes.any,
  handleCreateSchedule: PropTypes.any,
  handleWeeklyRecurrenceValueChange: PropTypes.any,
};
