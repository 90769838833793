import React from "react";
import PropTypes from "prop-types";

const ProgressBar = ({
  Passed = 0,
  Failed = 0,
  Warning = 0,
  Undefined = 0,
  Running = 0,
  Cancelled = 0
}) => {
  const circleStyle = {
    width: "13px",
    height: "13px",
    borderRadius: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "8px",
    color: "white",
    marginLeft: "2px",
  };

  const marginZero = {
    display: "flex",
    flexWrap: "nowrap",
  };

  const redCircleStyle = {
    ...circleStyle,
    backgroundColor: "#ff3d60",
  };

  const greenCircleStyle = {
    ...circleStyle,
    backgroundColor: "#11c46e",
  };

  const blueCircleStyle = {
    ...circleStyle,
    backgroundColor: "#9da1a6",
  };

  const yellowCircleStyle = {
    ...circleStyle,
    backgroundColor: "#f1b44c",
  };
  const darkYellowCircleStyle = {
    ...circleStyle,
    backgroundColor: "#DAA520",
  }; 

  const darkRedCircleStyle = {
    ...circleStyle,
    backgroundColor: "#FF2400",
  };

  return (
    <div style={marginZero}>
      <div style={greenCircleStyle}>{Passed}</div>

      <div style={redCircleStyle}>{Failed}</div>

      <div style={yellowCircleStyle}>{Warning}</div>

      <div style={blueCircleStyle}>{Undefined}</div>

      <div style={darkYellowCircleStyle}>{Running}</div>

      <div style={darkRedCircleStyle}>{Cancelled}</div>
    </div>
  );
};

export default ProgressBar;

ProgressBar.propTypes = {
  Passed: PropTypes.any,
  Failed: PropTypes.any,
  Warning: PropTypes.any,
  Undefined: PropTypes.any,
  Running: PropTypes.any,
  Cancelled: PropTypes.any,
};
