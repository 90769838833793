import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  currentSuiteNameSelection,
  fetchSuiteInstanceListing,
  menuSelectionChange,
  setSelectedReport,
  setSuiteSelection,
  setModuleSelection,
  setSelectedSuiteIds
} from "../../store/actions";

import Breadcrumbs from "../AHeaderFooterSections/HeaderComponents/Breadcrumb";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Paper,
  Select,
  Typography,
  Autocomplete,
  TextField,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput

} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import { SearchByFunctionArea } from "./SearchByFunctionArea";
import { SearchByTestSuite } from "./SearchByTestSuite";
import FunctionalArea from "../Dashboard/FunctionalArea";
// import SuiteArea from "../Dashboard/SuitArea";
import { BarChart } from "./Charts/BarChart";
import DashDountChart from "./Charts/Dashdountchart1";
import { sortSuites } from "../../config/util"; // getRandomColor,
import GridMain from "../Dashboard/DataGrid/gridDash";
import { ReportCard } from "./ReportCard";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { faFetch } from "../../store/dashboard/orchestractor";
import { BarChart2 } from "./Charts/BarChart2";
import GridMain2 from "../Dashboard/DataGrid/gridDash2";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DashboardNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const chartHeight = 370;
  const reportCardList = [
    {
      id: 1,
      reportName: "Report 1",
      reportDesc:
        "You can view all the failed test cases across all functional areas.",
    },
    {
      id: 2,
      reportName: "Failed Test Case 1",
      reportDesc:
        "You can view all the failed test cases across all functional areas.",
    },
    {
      id: 3,
      reportName: "Failed Test Case 2",
      reportDesc:
        "You can view all the failed test cases across all functional areas.",
    },
    {
      id: 4,
      reportName: "Failed Test Case 3",
      reportDesc:
        "You can view all the failed test cases across all functional areas.",
    },
    {
      id: 5,
      reportName: "Create Custom Report",
      reportDesc: "Create reports with your chosen parameters.",
    },
  ];

  const {
    tenants,
    suiteList,
    moduleList,
    testSuiteList,
    currentSuiteName,
    tenantSelection,
    startDate,
    endDate,
    dateRange
  } = useSelector((state) => state.dashboardData || []);

  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedSuite, setSelectedSuite] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedModule, setSelectedModule] = useState(
    moduleList?.modules?.length ? moduleList.modules[0] : ""
  );
  const [latestTestSuiteList, setLatestTestSuiteList] = useState(
    moduleList?.summary?.length ? moduleList?.summary[0]?.suiteIds : []
  );

  
  const [filteredModuleList, setFilteredModuleList] = useState([]);
  const [selectedSuites, setSelectedSuites] = useState([]);
  const [testSuiteListData, setTestSuiteListData] = useState([]);
  const [sumOfAllModuleData, setSumOfAllModuleData] = useState({});
  const [faSelector, setFaSelector] = useState('');




  // Function to handle selection changes
  const handleSuiteChange1 = (newValue) => {
    // const suiteIds = newValue.map(suite => suite.suiteRISummary.suiteId);
    setSelectedSuites(newValue);
    dispatch(setSelectedSuiteIds(newValue))
    console.log('setSelectedSuites', newValue,)
  };




  console.log('rudraaa', tenants,
    "suiteList", suiteList,
    "testSuiteList", testSuiteList,
    "moduleList", moduleList,
    "currentSuiteName", currentSuiteName,
    "tenantSelection", tenantSelection,
    "startDate", startDate,
    "endDate", endDate,
    "dateRange", dateRange)
  console.log(
    "testSuiteList", testSuiteList,
  )
  useEffect(() => {
    document.title = "Home | Ilumn ";
    dispatch(menuSelectionChange(2));
  }, [dispatch]);
  useEffect(() => {
    console.log('moduleList', moduleList)
  }, [moduleList]);
  console.log('moduleList', moduleList)
  // useEffect(() => {
  //   if (suiteList.length > 0) {
  //     console.log('suiteList0000',suiteList)
  //     const selectedSuiteList = suiteList.find(
  //       (suite) => suite.suiteRISummary.suiteId === parseInt(currentSuiteName)
  //     );
  //     getFunctionalAreaDetails();
  //     setSelectedSuite(selectedSuiteList || null);
  //   }
  // }, [suiteList, currentSuiteName]);
  useEffect(() => {

    getFunctionalAreaDetails();

  }, []);
  // console.log(" ===> ", tenants, suiteList, currentSuiteName);

  //

  useEffect(() => {
    if (moduleList?.modules?.length) {
      // Set the first module as selected
      setSelectedModule(moduleList.modules[0]);
      // Filter data based on selected module
      const selectedModuleData = moduleList.summary.find(
        (item) => item.moduleName === moduleList.modules[0]
      );

      setLatestTestSuiteList(moduleList?.summary[0]?.suiteIds)

      // Update filteredModuleList with the selected module data\
      const selectedModule = selectedModuleData ? [selectedModuleData] : [];
      setFilteredModuleList(selectedModuleData ? [selectedModuleData] : []);
      const sumOfAllModuleData = selectedModule.reduce((acc, moduleData) => {
        moduleData.scenarioSummary.forEach((scenario) => {
          Object.keys(scenario.countSummary).forEach((key) => {
            acc[key] = (acc[key] || 0) + scenario.countSummary[key];
          });
        });
        return acc;
      }, {});
      const defaultSummary = {
        failedCount: 0,
        passedCount: 0,
        undefinedCount: 0,
        warningCount: 0,
        runningCount: 0,
        cancelledCount: 0,
      };
      const finalSummary = { ...defaultSummary, ...sumOfAllModuleData };
      setSumOfAllModuleData(finalSummary)

      // Result in the required format
      console.log('sumOfAllModuleData', finalSummary);
    }
  }, [moduleList]);
  useEffect(() => {
    if (testSuiteList?.summary?.length) {


      // Update filteredModuleList with the selected module data
      setTestSuiteListData(testSuiteList?.summary);
    } else {
      setTestSuiteListData([]);
    }
  }, [testSuiteList]);

  console.log('setFilteredModuleList', filteredModuleList)
  const getFunctionalAreaDetails = async () => {
    try {
      console.log(
        "getFunctionalAreaDetails ===> ",
        tenantSelection,
        startDate,
        endDate
      );
      const response = await faFetch(tenantSelection, startDate, endDate);
      // debugger;
      console.log(response);
    } catch (error) {
    } finally {
    }
  };
  //
  const redirectToReportPage = (event) => {
    console.log("redirectToReportPage");
    setOpen(true);
    dispatch(setSelectedReport(null));
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleReportCardClick = (selectedReport) => {
    console.log(selectedReport);
    dispatch(setSelectedReport(selectedReport));
    navigate("/reports");
  };

  const handleTabChange = (newTabValue) => {
    if (newTabValue === 2) {
      setSelectedTab(newTabValue);
    } else {
      setSelectedTab(newTabValue);
    }
  };

  const handleSuiteChange = (event) => {
    const { value } = event?.target || {};
    console.log('valuevalue', value)
    if (value) {
      const selectedSuiteId = value;
      const selectedSuite = suiteList.find(
        (suite) => suite.suiteRISummary.suiteId === parseInt(selectedSuiteId)
      );
      setSelectedSuite(selectedSuite);
      dispatch(setSuiteSelection(selectedSuite.suiteRIDetails[0].suiteRIId));
      dispatch(currentSuiteNameSelection(parseInt(selectedSuiteId)));
      dispatch(fetchSuiteInstanceListing(selectedSuite.suiteRIDetails));
    } else {
      //
    }
  };
  // const handleModuleChange = (event) => {
  //   const { value } = event?.target || {};
  //   setSelectedModule(value);
  //   // Find the selected module data
  //   const selectedModuleData = moduleList.summary.find(
  //     (item) => item.moduleName === value
  //   );
  //   dispatch(setModuleSelection(selectedModule));

  //   setFilteredModuleList(selectedModuleData ? [selectedModuleData] : []);

  // };
  console.log('mmmmmmmmmmmmmmmmmm11', latestTestSuiteList)
console.log('setSelectedModule',selectedModule)
  const handleModuleChange = (event) => {
    const { value } = event?.target || {};
    setSelectedModule(value);

    // Find the selected module data
    const selectedModuleData = moduleList.summary.find(
      (item) => item.moduleName === value
    );

    const selectedModule = selectedModuleData ? [selectedModuleData] : [];
    setFilteredModuleList(selectedModule);
    console.log('selectedModule', selectedModule)
    setLatestTestSuiteList(selectedModule[0]?.suiteIds)
    const sumOfAllModuleData = selectedModule.reduce((acc, moduleData) => {
      moduleData.scenarioSummary.forEach((scenario) => {
        Object.keys(scenario.countSummary).forEach((key) => {
          acc[key] = (acc[key] || 0) + scenario.countSummary[key];
        });
      });
      return acc;
    }, {});
    
    console.log('mmmmmmmmmmmmmmmmmm', latestTestSuiteList)
    const defaultSummary = {
      failedCount: 0,
      passedCount: 0,
      undefinedCount: 0,
      warningCount: 0,
      runningCount: 0,
      cancelledCount: 0,
    };
    const finalSummary = { ...defaultSummary, ...sumOfAllModuleData };
    setSumOfAllModuleData(finalSummary)

    // Result in the required format
    console.log('sumOfAllModuleData', finalSummary);

    dispatch(setModuleSelection(value))
    // 🔥 Dispatch based on condition
    // if (faAreaSelection) {
    //   dispatch(setSelections({ moduleSelection: selectedModule, faAreaSelection }));
    // } else {
    //   dispatch(setModuleSelection(selectedModule));
    // }
  };


  // const [selectedTestSuiteDetails, setSelectedTestSuiteDetails] = useState([]);
  // const [prevAllSelected, setPrevAllSelected] = useState(false);
  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   let isAllSelected = false;
  //   let selectedItems = typeof value === "string" ? value.split(",") : value;
  //   if (selectedItems?.includes(0)) {
  //     selectedItems = suiteList?.map((suite) => suite.suiteRISummary.suiteId);
  //     selectedItems = [...selectedItems, 0];
  //     isAllSelected = true;
  //   } else if (prevAllSelected) {
  //     selectedItems = [];
  //     isAllSelected = false;
  //   } else {
  //     const selectedSuite = suiteList.filter((suite) =>
  //       selectedItems.includes(suite.suiteRISummary.suiteId)
  //     );
  //     console.log(selectedSuite);
  //   }
  //   if (!isAllSelected) {
  //     // selectedItems
  //   }
  //   setPrevAllSelected(isAllSelected);
  //   console.log("handleChange ===> ", selectedItems, suiteList);
  //   // suiteList?.length
  //   // suite.suiteRISummary.suiteName
  //   // suite.suiteRISummary.suiteId
  //   setSelectedTestSuiteDetails(selectedItems);
  // };

  return (
    <div className="page-content" id="component">
      <Box>
        <Breadcrumbs
          title="Home"
          breadcrumbItem="Dashboard"
          showButton={true}
          buttonText={"Go to custom report"}
          buttonClickHandler={redirectToReportPage}
        />
      </Box>
      <Box mt={2}>
        <Paper elevation={0} style={{ paddingBottom: 10 }}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={selectedTab === 1 ? "active" : ""}
                onClick={() => handleTabChange(1)}
              >
                By Function Area
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={selectedTab === 2 ? "active" : ""}
                onClick={() => handleTabChange(2)}
              >
                By Test Cases
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={selectedTab}>
            {selectedTab === 1 ? (
              <TabPane tabId={selectedTab}>
                <SearchByFunctionArea tenants={tenants} />
              </TabPane>
            ) : (
              <></>
            )}
            {selectedTab === 2 ? (
              <TabPane tabId={selectedTab}>
                <SearchByTestSuite />
              </TabPane>
            ) : (
              <></>
            )}
          </TabContent>
        </Paper>
      </Box>
      {selectedTab === 1 ? (
        <Box mt={2}>
          <FunctionalArea />
        </Box>
      ) : (
        <></>
      )}
      <Box mt={2}>
        {selectedTab === 1 ? (
          <Paper elevation={0} style={{ paddingBottom: 10, marginBottom: 20 }}>
            {moduleList?.modules?.length > 0 ?
              <>
                <Row
                  style={{
                    marginLeft: 2,
                    marginRight: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      marginTop: 2,
                      border: "1px solid #f3f3f3",
                      padding: "0px",
                      width: "49%",
                    }}
                  >


                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      style={{
                        borderBottom: "1px solid #f3f3f3",
                        padding: "0px 10px",
                      }}
                    >
                      <Typography>Modules</Typography>

                      <Box display={"flex"} alignItems={"center"}>
                        {/* <FormControl
                        sx={{
                          m: 1,
                          minWidth: 100,
                          minHeight: 20,
                          margin: "3px",
                        }}
                      >
                        <Select
                          native
                          aria-labelledby="run-test-tenant-select"
                          defaultValue=""
                          value={null}
                          name="dateRange"
                          onChange={() => {}}
                          style={{ height: 25 }}
                          className="font-size-12"
                        >
                          <option className="font-size-12" key={0} value={0}>
                            Hr Core
                          </option>
                        </Select>
                      </FormControl> */}
                        <FormControl
                          sx={{
                            m: 1,
                            minWidth: 100,
                            minHeight: 20,
                            margin: "3px",
                          }}
                        >
                          {/* <Select
                          native
                          aria-labelledby="run-test-tenant-select"
                          name="dateRange"
                          onChange={handleModuleChange}
                          // value={selectedSuite?.suiteRISummary.suiteId}
                          style={{ height: 25 }}
                          className="font-size-12"
                        >

                          {moduleList?.modules?.length ? (
                            moduleList?.modules.map((module) => (
                              <option
                                key={module}
                                value={module}
                              >
                                {module}
                              </option>
                            ))
                          ) : (
                            <></>
                          )}
                        </Select> */}
                          <Select
                            native
                            aria-labelledby="run-test-tenant-select"
                            name="dateRange"
                            onChange={handleModuleChange}
                            value={selectedModule} // Set default value
                            style={{ height: 25 }}
                            className="font-size-12"
                          >
                            {moduleList?.modules?.length ? (
                              moduleList.modules.map((module) => (
                                <option key={module} value={module}>
                                  {module}
                                </option>
                              ))
                            ) : (
                              <></>
                            )}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box>

                      <DashDountChart dataChart={{
                        suiteId: 53,
                        suiteName: "End Contingent Worker Contract",
                        suiteScenarioTotal: 32,
                        suiteRIStatusSummary: sumOfAllModuleData,
                        moduleName: "HR Core"
                      }} />
                    </Box>
                  </div>
                  <div
                    style={{
                      marginTop: 2,
                      border: "1px solid #f3f3f3",
                      padding: "0px",
                      width: "49%",
                    }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      style={{
                        borderBottom: "1px solid #f3f3f3",
                        padding: "0px 10px",
                      }}
                    >
                      <Typography>Modules</Typography></Box>
                    {/* <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      style={{
                        borderBottom: "1px solid #f3f3f3",
                        padding: "0px 10px",
                      }}
                    >
                      <Typography>Modules</Typography>

                      <Box display={"flex"} alignItems={"center"}>
                        
                        <FormControl
                          sx={{
                            m: 1,
                            minWidth: 100,
                            minHeight: 20,
                            margin: "3px",
                          }}
                        >
                        
                          <Select
                            native
                            aria-labelledby="run-test-tenant-select"
                            name="dateRange"
                            onChange={handleModuleChange}
                            value={selectedModule} // Set default value
                            style={{ height: 25 }}
                            className="font-size-12"
                          >
                            {moduleList?.modules?.length ? (
                              moduleList.modules.map((module) => (
                                <option key={module} value={module}>
                                  {module}
                                </option>
                              ))
                            ) : (
                              <></>
                            )}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box> */}
                    <Box>
                      <BarChart
                        isBar={false}
                        isStacked={true}
                        colors={["#29CA7D", "#FF3F61", "#FFC53F", "#AAAAAA", "#DAA520", "#FF2400"]}
                        height={chartHeight}
                        moduleData={filteredModuleList}
                      />

                    </Box>
                  </div>

                </Row>
                <Row
                  style={{
                    marginLeft: 2,
                    marginRight: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <div
                    style={{
                      marginTop: 2,
                      border: "1px solid #f3f3f3",
                      padding: "0px",
                      width: "49%",
                      minHeight: "503px"
                    }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      style={{
                        borderBottom: "1px solid #f3f3f3",
                        padding: "0px 10px",
                      }}
                    >
                      <Typography>Test Suites</Typography>
                      <Box display={"flex"} alignItems={"center"}>
                        {/* <FormControl
                          sx={{
                            m: 1,
                            minWidth: 100,
                            minHeight: 20,
                            margin: "3px",
                          }}
                        >
                          <Select
                            native
                            aria-labelledby="run-test-tenant-select"
                            name="dateRange"
                            onChange={handleSuiteChange}
                            value={selectedSuite?.suiteRISummary.suiteId}
                            style={{ height: 25 }}
                            className="font-size-12"
                          >
                            <option className="font-size-12" key={0} value={0}>
                              All
                            </option>
                            {suiteList?.length ? (
                              sortSuites(suiteList).map((suite) => (
                                <option
                                  key={suite.suiteRISummary.suiteId}
                                  value={suite.suiteRISummary.suiteId}
                                >
                                  {suite.suiteRISummary.suiteName}
                                </option>
                              ))
                            ) : (
                              <></>
                            )}
                          </Select>
                        </FormControl> */}
                        <FormControl sx={{ m: 1, width: 300 }}>
                        <Select
  id="suite-multiple-checkbox"
  multiple
  displayEmpty
  value={selectedSuites}
  onChange={(event) => handleSuiteChange1(event.target.value)}
  input={<OutlinedInput />}
  style={{ height: 25 }}
  className="font-size-12"
  renderValue={(selected) =>
    selected.length > 0
      ? selected
          .map((suiteId) =>
            latestTestSuiteList.find((suite) => suite.suiteId === suiteId)?.suiteName
          )
          .join(", ")
      : "Select Suites" // Placeholder text
  }
  MenuProps={MenuProps}
>
  {latestTestSuiteList?.map((suite) => (
    <MenuItem key={suite.suiteId} value={suite.suiteId}>
      <Checkbox checked={selectedSuites.includes(suite.suiteId)} />
      <ListItemText primary={suite.suiteName} />
    </MenuItem>
  ))}
</Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box>
                      {testSuiteListData &&
                        <BarChart2
                          isBar={false}
                          isStacked={true}
                          colors={["#29CA7D", "#FF3F61", "#FFC53F", "#AAAAAA", "#DAA520", "#FF2400"]}
                          height={chartHeight}
                          moduleData={testSuiteListData}
                        />
                      }

                    </Box>
                  </div>
                  <div
                    style={{
                      marginTop: 2,
                      border: "1px solid #f3f3f3",
                      padding: "0px",
                      width: "49%",
                    }}
                  >
                    <GridMain2 />

                  </div>

                </Row>
              </> : <>no record found</>}

          </Paper>
        ) : (
          <></>
        )}
        {/* <Paper elevation={0} style={{ paddingBottom: 10, marginBottom: 20 }}>
          <Row style={{ marginLeft: 2, marginRight: 2 }}>

            <GridMain2 />
          </Row>
        </Paper> */}
      </Box>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={"md"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <strong>Generate Report</strong>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box sx={{ display: "flex", gap: 4 }}>
            {reportCardList?.slice(0, 3)?.map((rec) => {
              return (
                <ReportCard
                  width={268}
                  id={rec?.id || 0}
                  reportName={rec?.reportName || ""}
                  reportDesc={rec?.reportDesc || ""}
                  handleReportCardClick={() => handleReportCardClick(rec)}
                />
              );
            })}
          </Box>
          <Box mt={2} sx={{ display: "flex", gap: 4 }}>
            {reportCardList?.slice(3, 5)?.map((rec, index) => {
              return (
                <ReportCard
                  width={268}
                  isCreateNew={index === 1 ? true : false}
                  id={rec?.id || 0}
                  reportName={rec?.reportName || ""}
                  reportDesc={rec?.reportDesc || ""}
                  handleReportCardClick={() => handleReportCardClick(rec)}
                />
              );
            })}
          </Box>
        </DialogContent>
        {/* <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={handleCreateSchedule}>Create</Button>
          </DialogActions> */}
      </BootstrapDialog>
    </div>
  );
};

export default DashboardNew;
