import React from "react";
import PropTypes from "prop-types";

export const MyTextField = (props) => {
  const {
    value = "",
    handleChange = () => {},
    type = "text",
    className = "",
    placeholder = "",
    pattern = "",
    inputMode = "text",
    disabled = false,
    maxLength = 100,
    ariaLabelledby = "",
  } = props;

  return (
    <input
      aria-labelledby={ariaLabelledby}
      type={type || "text"}
      className={className || "form-control small-select"}
      placeholder={placeholder || ""}
      value={value || ""}
      inputMode={inputMode || "text"}
      pattern={pattern || ""}
      onChange={(e) => handleChange(e)}
      disabled={disabled}
      maxLength={maxLength}
    />
  );
};

MyTextField.propTypes = {
  value: PropTypes.any,
  handleChange: PropTypes.any,
  type: PropTypes.any,
  className: PropTypes.any,
  placeholder: PropTypes.any,
  pattern: PropTypes.any,
  inputMode: PropTypes.any,
  disabled: PropTypes.any,
  maxLength: PropTypes.any,
  ariaLabelledby: PropTypes.any,
};
