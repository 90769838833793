import CryptoJS from "crypto-js";
import { encode as base64_encode } from "base-64";
import { configureStore } from "../store/store";
import { commonErrorHandle } from "../store/actions";

const { store } = configureStore({});
const getRandomNumber = Math.random(); // Sensitive

export const roleDetails = [
  { name: "Tester", value: "t" },
  { name: "Viewer", value: "v" },
  { name: "Admin", value: "a" },
  { name: "Privileged", value: "pv" },
];

export const capitalizeFLetter = (string = "") => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const pad = (text) => {
  const blockSize = 16;
  const padLength = blockSize - (text.length % blockSize);
  const padding = String.fromCharCode(padLength).repeat(padLength);

  return text + padding;
};

export const encryptData = (data, key, iv) => {
  const encrypted = CryptoJS.AES.encrypt(pad(data), key, { iv: iv }).toString();
  return base64_encode(encrypted);
};

export const generateTimeList = () => {
  return Array.from({ length: 24 * 4 }, (_, index) => {
    const hour = Math.floor(index / 4);
    const minute = (index % 4) * 15;
    const formattedHour = String(hour).padStart(2, "0");
    const formattedMinute = String(minute).padStart(2, "0");
    return `${formattedHour}:${formattedMinute}`;
  });
};
export const getWeekDay = (weekName = 1) => {
  const weekIds = {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday",
  };
  return weekIds[weekName];
};

export const getMonthDayNumber = (monthName = "January") => {
  const monthIds = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };
  return monthIds[monthName];
};
export const getJobTypeName = (id) => {
  const jobType = {
    1: "DAILY",
    2: "WEEKLY",
    3: "MONTHLY",
  };
  return jobType[id || 1];
};
export const getWeekDayNumber = (weekName = "Monday") => {
  const weekIds = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
  };
  return weekIds[weekName];
};

export const sortSuites = (suitesData) => {
  const details = suitesData.sort((a, b) => {
    const nameA = a.suiteRISummary.suiteName.toUpperCase();
    const nameB = b.suiteRISummary.suiteName.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return details;
};

export const sortByName = (funcData, keyName) => {
  return funcData?.sort((a, b) =>
    a[keyName].toUpperCase().localeCompare(b[keyName].toUpperCase())
  );
};

// Helper function to format the date
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    day: "2-digit",
    month: "short",
    year: "2-digit",
  };
  const formattedDateTime = date.toLocaleDateString("en-UK", options);
  return formattedDateTime;
};

export const getRandomColor = (colors) => {
  return colors[Math.floor(getRandomNumber * colors.length)];
};

export const handleCommonAPIError = (errMsg = "", errorType = "") => {
  console.log(errMsg, errorType);
  // store.dispatch(commonErrorHandle(true, errMsg, "error"));
};
