import React, { useEffect } from "react";
import { Col, Row, Container } from "reactstrap";
import Banner from "./PicBanner";
import Notifications from "./Notifications";
import Announcements from "./Announcements";
import QuickLinks from "./QuickLinks";
import { useDispatch } from "react-redux";
import { menuSelectionChange } from "../../store/actions";
const AdjBaner = {
  marginTop: "30px",
};

const Home = () => {
  const dispatch = useDispatch();

  document.title = "Home | Ilumn ";
  const obj = JSON.parse(localStorage.getItem("authUser"));
  const username = obj.user.userDisplayName;

  useEffect(() => {
    document.title = "Home | Ilumn ";
    dispatch(menuSelectionChange(1));
  }, [dispatch]);

  return (
    <div className="page-content" style={AdjBaner}>
      <Container fluid>
        <Row>
          <Banner />
        </Row>
        <Row style={{ marginTop: "18px" }}>
          <h5>Welcome {username}</h5>
        </Row>
        <Row>
          <Col lg={6}>
            <Notifications />
          </Col>
          <Col lg={6}>
            <Announcements />
            <QuickLinks />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home;
