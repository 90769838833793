import { combineReducers } from "redux";
import Layout from "./layout/reducer";
import dashboardData from "./dashboard/reducer";
import login from "./auth/login/reducer";

const rootReducer = combineReducers({
  // public
  dashboardData,
  Layout,
  login,
});

export default rootReducer;
