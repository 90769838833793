import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, Col } from "reactstrap";
import { StyledDataGrid } from "../generalFunctions";
import {
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import timezoneData from "./timezones.json";
import { CreateSchedule } from "./CreateSchedule";
import moment from "moment";
import {
  createSchedule,
  deleteSchedule,
  updateSchedule,
} from "../../store/dashboard/orchestractor";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  monthNames,
  recurrenceConstant,
  weekNames,
} from "../../config/constant";
import {
  generateTimeList,
  getJobTypeName,
  getMonthDayNumber,
  getWeekDay,
  getWeekDayNumber,
} from "../../config/util";
import { useProfile } from "../../Hooks/UserHooks";
import { ExcelExportIcon } from "../../components/ExcelExportIcon";

const smallSelect = {
  fontSize: "12px",
  lineHeight: "15px",
  width: "100%",
  borderRadius: "2px",
  padding: "3px",
  marginBottom: "3px",
};

const getStatus = (params) => {
  const jobStatus = params.row.jobStatus;
  // EXPIRED ACTIVE & NOTACTIVE
  return jobStatus === "ACTIVE" ? "Active" : "In-active";
};
const getJobType = (params) => {
  const jobType = params.row.jobType;
  // DAILY WEEKLY & MONTHLY
  const updatedValue = jobType
    ? jobType.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
    : "";
  return updatedValue ? `${updatedValue} Frequency` : "";
};

const getFilteredRows = ({ apiRef }) => {
  gridExpandedSortedRowIdsSelector(apiRef);
};
const CustomToolbar = () => {
  const apiRef = useGridApiContext();

  // Function to handle export button click to export data as CSV

  const handleExport = (options) => {
    // Set a custom file name for the exported CSV file
    const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    options.fileName = `suite_instance_listing_${timestamp}`;

    apiRef.current.exportDataAsCsv(options);
  };
  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ padding: "0 5px 0 5px", borderBottom: " 1px solid #f0f0f0" }}
    >
      <div>
        <h6 style={{ marginBottom: "0px", fontSize: "11px" }}>
          Suite Run Instance Listing
        </h6>
      </div>

      <ExcelExportIcon
        handleExport={handleExport}
        getFilteredRows={getFilteredRows}
      />
    </div>
  );
};

export const Schedule = (props) => {
  const { testSuitDetails, scheduleList, getScheduleList } = props;

  const { userProfile } = useProfile();

  const headerHeight = 28;
  const rowHeight = 26;
  const columns = [
    {
      field: "nextRun",
      headerName: "Next Scheduled Date Time",
      width: 200,
      type: "date",
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const options = {
          day: "2-digit",
          month: "short",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const formattedDateTime = date.toLocaleDateString("en-UK", options);
        return formattedDateTime;
      },
    },
    {
      field: "jobName",
      headerName: "Scheduled Process",
      width: 200,
      flex: 1,
    },
    {
      field: "jobType",
      renderCell: getJobType,
      headerName: "Run Frequency",
      width: 200,
    },
    {
      field: "userEmail",
      headerName: "Owned by User",
      width: 200,
    },
    {
      field: "startDate",
      headerName: "Recurrence Start Date",
      width: 190,
      type: "date",
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const options = {
          day: "2-digit",
          month: "short",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const formattedDateTime = date.toLocaleDateString("en-UK", options);
        return formattedDateTime;
      },
    },
    {
      field: "endDate",
      headerName: "Recurrence End Date",
      width: 190,
      type: "date",
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const options = {
          day: "2-digit",
          month: "short",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const formattedDateTime = date.toLocaleDateString("en-UK", options);
        return formattedDateTime;
      },
    },
    {
      field: "jobStatus",
      renderCell: getStatus,
      headerName: "Status",
      width: 100,
      maxWidth: 200,
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => {
        return userProfile?.user?.viewerRole
          ? []
          : [
              <GridActionsCellItem
                icon={<EditIcon />}
                key={`ss_${params.row?.jobId}`}
                label="Edit"
                onClick={EditScheduler(params)}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                key={`xx_${params.row?.jobId}`}
                onClick={deleteUser(params.id)}
                showInMenu
              />,
            ];
      },
    },
  ];

  const defaultFormDetails = {
    recurrenceType: 1,
    recurrenceTypeValue: 0,
    monthlyRecurrenceType: 1,
    monthlyRecurrenceTypeValue: 0,
    testSuiteTimezone: 38,
    testSuiteTimeSelection: "06:00",
    weeklyRecurrenceValue: 0,
  };

  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [open, setOpen] = useState(false);
  const [formDetails, setFormDetails] = useState({ ...defaultFormDetails });
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [monthlyMonthDetails, setMonthlyMonthDetails] = useState([]);
  const [monthlyWeekDetails, setMonthlyWeekDetails] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rows, setRows] = useState([]);

  // Example usage
  const timeList = generateTimeList();
  //
  useEffect(() => {
    const rowsData = [...scheduleList].map((item) => {
      return { ...item, id: item?.jobId };
    });
    setRows([...rowsData]);
  }, [scheduleList]);

  const handleScheduleEdit = (selectedRow) => {
    try {
      const selectedRecord = selectedRow?.row || {};
      const {
        jobId = 0,
        suiteId = 0,
        jobName = "",
        startTime = "06:00",
        jobConfiguration = {},
      } = selectedRecord;

      const defaultData = {
        jobId,
        testSuite: suiteId,
        testSuiteName: jobName,
        testSuiteTimezone: 39,
        testSuiteTimeSelection: startTime,
        monthlyRecurrenceType: 1,
        monthlyRecurrenceTypeValue: 0,
        weeklyRecurrenceValue: 0,
        testSuiteFrequency: 1, // Default to DAILY frequency
        recurrenceType: 1,
        recurrenceTypeValue: 0,
      };

      const updateDates = () => {
        setStartDate(new Date(startDate || new Date()));
        setEndDate(new Date(endDate || new Date()));
      };

      if (selectedRecord.jobType === "DAILY") {
        defaultData.recurrenceType = 2;
        const dayFrequency = jobConfiguration?.dayFrequency || [];
        defaultData.recurrenceTypeValue = dayFrequency.length
          ? dayFrequency[0]
          : 0;
        setFormDetails({ ...formDetails, ...defaultData });
        setOpen(true);
        updateDates();
      } else if (selectedRecord.jobType === "WEEKLY") {
        defaultData.testSuiteFrequency = 2; // Run Frequency
        const weekFrequency = jobConfiguration?.weekFrequency || [];
        defaultData.weeklyRecurrenceValue = weekFrequency.length
          ? weekFrequency[0]
          : 0;

        const dayWeek = jobConfiguration?.daysOfWeek || [];
        setDaysOfWeek(dayWeek.map(getWeekDay));

        setFormDetails({ ...formDetails, ...defaultData });
        setOpen(true);
        updateDates();
      } else if (selectedRecord.jobType === "MONTHLY") {
        defaultData.testSuiteFrequency = 3; // Run Frequency
        const daysOfMonths = jobConfiguration?.daysOfMonth || [];
        defaultData.monthlyRecurrenceTypeValue = daysOfMonths[0] || 0;

        const monthsDetails = jobConfiguration?.months || [];
        const updatedMonthName = monthsDetails.map(getMonthDayNumber);
        setMonthlyMonthDetails(updatedMonthName);

        const dayWeek = jobConfiguration?.daysOfWeek || [];
        const updatedWeekName = dayWeek.map(getWeekDay);
        setMonthlyWeekDetails(updatedWeekName);

        setFormDetails({ ...formDetails, ...defaultData });
        setOpen(true);
        updateDates();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteAPICall = async (id) => {
    try {
      const response = await deleteSchedule(id);
      if (response) {
        getScheduleList();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteDetail = (id) => {
    handleDeleteAPICall(id);
    const prevRows = rows.filter((row) => row.id !== id);
    setRows(prevRows);
  };

  const EditScheduler = useCallback(
    (selectedRecord) => () => {
      setTimeout(() => {
        handleScheduleEdit(selectedRecord);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const deleteUser = useCallback(
    (id) => {
      setTimeout(() => {
        handleDeleteDetail(id);
      }, 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleClose = () => {
    if (testSuitDetails?.suiteScriptListByFA?.length) {
      getDefaultTestSuitId(2);
      setStartDate(null);
      setEndDate(null);
      setDaysOfWeek([]);
      setMonthlyWeekDetails([]);
      setMonthlyMonthDetails([]);
    }
    setOpen(false);
  };

  useEffect(() => {
    if (testSuitDetails?.suiteScriptListByFA?.length) {
      getDefaultTestSuitId(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testSuitDetails]);

  const getDefaultTestSuitId = (type) => {
    const defaultSelection =
      testSuitDetails?.suiteScriptListByFA[0]?.modules[0]?.suiteScripts;
    const defaultSuiteId = defaultSelection?.length
      ? defaultSelection[0]?.suiteId
      : 0;
    const defaultSuiteName = defaultSelection?.length
      ? defaultSelection[0]?.suiteName
      : 0;
    const updatedFormValue =
      type === 1
        ? {
            ...formDetails,
            testSuite: defaultSuiteId ? parseInt(defaultSuiteId) : 0,
            testSuiteName: defaultSuiteName || "",
          }
        : {
            ...defaultFormDetails,
            testSuite: defaultSuiteId ? parseInt(defaultSuiteId) : 0,
            testSuiteName: defaultSuiteName || "",
          };
    setFormDetails(updatedFormValue);
  };
  const resetForm = (formValues) => {
    const defaultSelection =
      testSuitDetails?.suiteScriptListByFA[0]?.modules[0].suiteScripts;
    const defaultSuiteId = defaultSelection?.length
      ? defaultSelection[0]?.suiteId
      : 0;
    const defaultSuiteName = defaultSelection?.length
      ? defaultSelection[0]?.suiteName
      : 0;
    setFormDetails({
      testSuite:
        formDetails?.testSuite || defaultSuiteId ? parseInt(defaultSuiteId) : 0,
      testSuiteName: formDetails?.testSuiteName || defaultSuiteName || "",
      ...defaultFormDetails,
      ...formValues,
    });
    setDaysOfWeek([]);
    setStartDate(null);
    setEndDate(null);
  };
  const handleCellClick = async (params) => {
    const suiteSelection = params.row.jobId;
    console.error(suiteSelection);
    // setSuiteSelection(suiteSelection); // Call setSuiteSelection here when needed
  };
  const getTimeZoneData = (abbrValue) => {
    let timeZoneDetails = null;
    timezoneData?.forEach((item) => {
      if (item?.id === abbrValue) {
        timeZoneDetails = item;
      }
    });
    return timeZoneDetails?.utc?.length
      ? timeZoneDetails?.utc[0]
      : "Europe/Isle_of_Man";
  };
  const getTestSuiteFaId = (value = 0) => {
    const data = testSuitDetails?.suiteScriptListByFA || [];

    for (const item of data) {
      const suiteScripts = item?.modules?.[0]?.suiteScripts || [];
      const foundScript = suiteScripts.find(
        (script) => script?.suiteId === value
      );

      if (foundScript) {
        return item.faId || 0;
      }
    }

    return 0; // Default return value if not found
  };

  const getTestSuiteName = (value = 0) => {
    const data = testSuitDetails?.suiteScriptListByFA || [];

    for (const item of data) {
      const suiteScripts = item?.modules?.[0]?.suiteScripts || [];
      const foundScript = suiteScripts.find(
        (script) => script?.suiteId === value
      );

      if (foundScript) {
        return foundScript.suiteName || "";
      }
    }

    return ""; // Default return value if not found
  };

  const handleChangeTestSuite = (event) => {
    const { name, value } = event.target;
    const idVal = value ? parseInt(value) : 0;
    const testSuiteName = getTestSuiteName(idVal);
    setFormDetails({
      ...formDetails,
      [name]: idVal,
      testSuiteName: testSuiteName || "",
    });
  };
  const handleChangeTestSuiteFrequency = (event) => {
    const { name, value } = event.target;

    resetForm({ [name]: value });
  };
  const handleChange = (event) => {
    const { value } = event.target;
    setFormDetails({
      ...formDetails,
      recurrenceType: value ? parseInt(value) : 1,
      recurrenceTypeValue: 0,
    });
    setMonthlyMonthDetails([]);
  };
  const handleMonthlyChange = (event) => {
    const { value } = event.target;
    setFormDetails({
      ...formDetails,
      monthlyRecurrenceType: value ? parseInt(value) : 1,
      monthlyRecurrenceTypeValue: 0,
    });
    setDaysOfWeek([]);
    setMonthlyWeekDetails([]);
  };
  const handleWeekChange = (event) => {
    const {
      target: { value },
    } = event;
    setDaysOfWeek(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleMonthlyMonthChange = (event) => {
    const { value } = event?.target || {};
    setMonthlyMonthDetails(
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleMonthlyWeekChange = (event) => {
    const { value } = event?.target || {};
    setMonthlyWeekDetails(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeTimeZone = (event) => {
    const { value, name } = event?.target || {};
    setFormDetails({ ...formDetails, [name]: value ? parseInt(value) : 38 });
  };
  const handleChangeTimeSelection = (event) => {
    const { value, name } = event?.target || {};
    setFormDetails({ ...formDetails, [name]: value });
  };
  const handleRecurrenceTypeValueChange = (event) => {
    const { value, name } = event?.target || {};
    setFormDetails({ ...formDetails, [name]: value ? parseInt(value) : 0 });
  };
  const handleWeeklyRecurrenceValueChange = (event) => {
    // weeklyRecurrenceValue
    const { value, name } = event?.target || {};
    setFormDetails({ ...formDetails, [name]: value ? parseInt(value) : 0 });
  };
  const checkCreateScheduleFormValidation = () => {
    return formDetails?.testSuiteFrequency && startDate && endDate;
  };

  const handleCreateSchedule = async (event) => {
    try {
      if (!checkCreateScheduleFormValidation()) return;

      const formattedStartDate = startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : null;
      const formattedEndDate = endDate
        ? moment(endDate).format("YYYY-MM-DD")
        : null;
      const storedAuthUser = localStorage.getItem("authUser");
      const userId = storedAuthUser
        ? JSON.parse(storedAuthUser)?.user?.userId
        : null;

      if (!userId) return;

      const requestPayload = createRequestPayload(
        formattedStartDate,
        formattedEndDate,
        userId
      );

      const response = requestPayload.jobId
        ? await updateSchedule(requestPayload, requestPayload.jobId)
        : await createSchedule({ ...requestPayload, jobId: undefined });

      if (response) {
        handleClose();
        getScheduleList();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createRequestPayload = (startDate, endDate, userId) => {
    const payload = {
      jobId: formDetails?.jobId || 0,
      suiteId: formDetails?.testSuite || 0,
      jobName: formDetails?.testSuiteName || "",
      jobDescription: formDetails?.testSuiteName || "",
      jobType: getJobTypeName(formDetails?.testSuiteFrequency) || "",
      startDate,
      endDate,
      startTime: formDetails?.testSuiteTimeSelection || "",
      userId,
      faId: getTestSuiteFaId(formDetails?.testSuite || 0),
      jobStatus: "ACTIVE",
      timezone: getTimeZoneData(formDetails?.testSuiteTimezone),
    };

    return {
      ...payload,
      jobConfiguration: createJobConfiguration(),
    };
  };

  const createJobConfiguration = () => {
    let config = {};

    switch (formDetails?.testSuiteFrequency) {
      case 1: // Daily
        if (formDetails?.recurrenceType === 2) {
          config = {
            dayFrequency: [formDetails?.recurrenceTypeValue || 0],
          };
        }
        break;
      case 2: // Weekly
        config = {
          weekFrequency: [formDetails?.weeklyRecurrenceValue || 0],
          daysOfWeek: daysOfWeek?.map(getWeekDayNumber) || [0],
        };
        break;
      case 3: // Monthly
        handleMonthlyConfiguration(config);
        break;
      default:
        break;
    }

    return config;
  };

  const handleMonthlyConfiguration = (config) => {
    if (formDetails?.recurrenceType === 2) {
      config.months = monthlyMonthDetails?.map(getMonthDayNumber) || [0];
    }

    if (formDetails?.monthlyRecurrenceType === 1) {
      config.daysOfMonth = [formDetails?.monthlyRecurrenceTypeValue || 0];
    } else if (formDetails?.monthlyRecurrenceType === 2) {
      config.daysOfWeek = monthlyWeekDetails?.map(getWeekDayNumber) || [0];
    }
  };

  return (
    <>
      <Col xl={12}>
        <Card>
          <CardBody>
            <CardTitle className="h4">
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="page-title-right ml-auto"
                  style={{ display: "flex" }}
                >
                  <div style={{ width: "250px" }}>Scheduled Jobs</div>
                  <button
                    style={smallSelect}
                    disabled={userProfile?.user?.viewerRole}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    + Schedule
                  </button>
                </div>
              </div>
            </CardTitle>

            <div
              style={{
                background: "#ffffff",
                overflow: "auto",
                height: "445px",
                width: "100%",
                flexGrow: 1,
              }}
            >
              {/* Render the StyledDataGrid component with the specified rows, columns, and props */}
              <StyledDataGrid
                initialState={{}}
                rows={rows}
                columns={columns}
                rowHeight={rowHeight}
                columnHeaderHeight={headerHeight}
                onCellClick={handleCellClick}
                hideFooter
                hideFooterPagination
                hideFooterSelectedRowCount
                slots={{ toolbar: CustomToolbar }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                componentsProps={{
                  // Set custom styles for the datafg grid components

                  basePopper: {
                    sx: {
                      "& .MuiTypography-root": { fontSize: "12px" },
                    },
                  },
                }}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
      {open ? (
        <CreateSchedule
          open={open}
          handleClose={handleClose}
          formDetails={formDetails}
          handleChangeTestSuite={handleChangeTestSuite}
          testSuitDetails={testSuitDetails}
          handleChangeTestSuiteFrequency={handleChangeTestSuiteFrequency}
          recurrenceConstant={recurrenceConstant}
          handleChange={handleChange}
          daysOfWeek={daysOfWeek}
          handleWeekChange={handleWeekChange}
          names={weekNames}
          timeList={timeList}
          timezoneData={timezoneData}
          callingType={"TestManager"}
          handleMonthlyChange={handleMonthlyChange}
          monthlyMonthDetails={monthlyMonthDetails}
          handleMonthlyMonthChange={handleMonthlyMonthChange}
          monthlyWeekDetails={monthlyWeekDetails}
          handleMonthlyWeekChange={handleMonthlyWeekChange}
          monthNames={monthNames}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          handleChangeTimeZone={handleChangeTimeZone}
          handleChangeTimeSelection={handleChangeTimeSelection}
          handleRecurrenceTypeValueChange={handleRecurrenceTypeValueChange}
          handleCreateSchedule={handleCreateSchedule}
          handleWeeklyRecurrenceValueChange={handleWeeklyRecurrenceValueChange}
        />
      ) : (
        <></>
      )}
    </>
  );
};

Schedule.propTypes = {
  testSuitDetails: PropTypes.any,
  scheduleList: PropTypes.any,
  getScheduleList: PropTypes.any,
};
