import React from "react";
import PropTypes from "prop-types";
import withRouter from "../AHeaderFooterSections/HeaderComponents/withRouter";

const NonAuthLayout = (props) => {
  return <React.Fragment>{props.children}</React.Fragment>;
};

NonAuthLayout.propTypes = {
  children: PropTypes.any
};

export default withRouter(NonAuthLayout);
