import React from "react";
import { useSelector } from "react-redux";
const LoadingOverlay = () => {
  const isLoading = useSelector((state) => state.dashboardData.loading);

  const overlayStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
    color: "#fff",
    fontSize: "12px",
  };

  return isLoading ? (
    <div style={overlayStyles}>
      <div className="col-lg-2">
        <div className="card card-body">
          <button className="btn btn-primary" type="button" disabled>
            <span
              className="spinner-border spinner-border-sm"
              aria-hidden="true"
            ></span>{" "}
            Loading ...
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default LoadingOverlay;
