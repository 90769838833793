// Import necessary dependencies
import {
  takeEvery,
  call,
  put,
  all,
  fork,
  select,
  takeLatest,
} from "redux-saga/effects";
import {
  faFetch,
  scenarioFetch,
  searchScenarioID,
  searchSuiteFetch,
  searchSuiteID,
  stepFetch,
  suiteFetch,
  moduleFetch,
  testSuiteFetch
} from "./orchestractor";
import {
  currentScenarioNameSelection,
  currentSuiteNameSelection,
  fetchSuiteInstanceListing,
  getFAAreas,
  getScenarioList,
  getStepList,
  getSuiteList,
  getModuleList,
  getTestSuiteList,
  setFAAreaSelection,
  setLoadingState,
  setScenarioSelection,
  setSearchFlag,
  setSuiteSelection,
  setTestSuiteSelection,
  setModuleSelection,
  showError,
} from "./actions";

function* fetchFaList(action) {
  try {
    const searchFlag = yield select((state) => state.dashboardData.searchFlag);
    if (!searchFlag) {
      yield put(setLoadingState(true));
      const { faAreas, faAreaSelection } = yield call(
        faFetch,
        action.payload.tenantSelection,
        action.payload.startDate,
        action.payload.endDate
      );
      console.log('fetchFaList',faAreas)
      console.log('fetchFaList1',faAreaSelection)
      yield put(getFAAreas(faAreas));
      yield put(setFAAreaSelection(faAreaSelection));
    }
  } catch (error) {
    yield put(getFAAreas(null)); // Set FA areas to null in case of error
    yield put(setFAAreaSelection(null)); // Set FA area selection to null in case of error
  } finally {
    yield put(setLoadingState(false));
  }
}

function* watchTenantChange() {
  yield takeEvery("SET_TENANT_SELECTION", fetchFaList);
}

function* fetchModuleList(action) {
  try {
    const searchFlag = yield select((state) => state.dashboardData.searchFlag);
    if (!searchFlag) {
      yield put(setLoadingState(true));
      const faAreaSelection = action.payload;
      const tenantSelection = yield select(
        (state) => state.dashboardData.tenantSelection
      );
      const startDate = yield select((state) => state.dashboardData.startDate);
      const endDate = yield select((state) => state.dashboardData.endDate);
      const dateRange = yield select((state) => state.dashboardData.dateRange);
console.log('ahhahah',dateRange)
      const { moduleList, moduleSelection } =
        yield call(
          moduleFetch,
          faAreaSelection,
          tenantSelection,
          startDate,
          endDate,
          dateRange
        );

      yield put(getModuleList(moduleList));
      yield put(setModuleSelection(moduleSelection));
    }
  } catch (error) {
    yield put(getModuleList(null));
    yield put(setModuleSelection(null));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* fetchTestSuiteList(action) {
  try {
    console.log('fetchTestSuiteListACTION1', action);

    console.log('fetchTestSuiteListACTION', yield select((state) => state.dashboardData.moduleName));
    const searchFlag = yield select((state) => state.dashboardData.searchFlag);
    if (!searchFlag) {
      yield put(setLoadingState(true));
// const faAreaSelection ="1",
const moduleName = yield select((state) => state.dashboardData.moduleName);
      const faAreaSelection = yield select((state) => state.dashboardData.faAreaSelection);
      const tenantSelection = yield select(
        (state) => state.dashboardData.tenantSelection
      );
      const selectedSuiteIds = action?.payload;
      const startDate = yield select((state) => state.dashboardData.startDate);
      const endDate = yield select((state) => state.dashboardData.endDate);
      const dateRange = yield select((state) => state.dashboardData.dateRange);
      // const testSuiteSelection = yield select((state) => state.dashboardData.testSuiteSelection);

      const { testSuiteList, testSuiteSelection } =
        yield call(
          testSuiteFetch,
          faAreaSelection,
          tenantSelection,
          startDate,
          endDate,
          dateRange,
          moduleName,
          selectedSuiteIds
          
        );

      yield put(getTestSuiteList(testSuiteList));
      // yield put(setModuleSelection(testSuiteSelection));
    }
  } catch (error) {
    yield put(getTestSuiteList(null));
    yield put(setModuleSelection(null));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* fetchSuiteList(action) {
  try {
    const searchFlag = yield select((state) => state.dashboardData.searchFlag);
    if (!searchFlag) {
      yield put(setLoadingState(true));
      const faAreaSelection = action.payload;
      const tenantSelection = yield select(
        (state) => state.dashboardData.tenantSelection
      );
      const startDate = yield select((state) => state.dashboardData.startDate);
      const endDate = yield select((state) => state.dashboardData.endDate);

      const { suiteList, suiteSelection, currentSuiteName, SuiteInstanceList } =
        yield call(
          suiteFetch,
          faAreaSelection,
          tenantSelection,
          startDate,
          endDate
        );

      yield put(getSuiteList(suiteList));
      yield put(setSuiteSelection(suiteSelection));
      yield put(currentSuiteNameSelection(currentSuiteName));
      yield put(fetchSuiteInstanceListing(SuiteInstanceList));
    }
  } catch (error) {
    yield put(getSuiteList(null));
    yield put(setSuiteSelection(null));
    yield put(currentSuiteNameSelection(null));
    yield put(fetchSuiteInstanceListing(null));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* faChange() {
  yield takeLatest("SET_FA_AREA_SELECTION", fetchSuiteList);
  yield takeLatest("SET_FA_AREA_SELECTION", fetchModuleList);
  yield takeLatest("SET_FA_AREA_SELECTION", fetchTestSuiteList);
  yield takeLatest("SET_SELECTED_SUITE_IDS", fetchTestSuiteList);
  yield takeLatest("SET_WORKBOARD_SUITE_ID", fetchTestSuiteList);
  
  
}
// function* testSuiteChange() {

  
// }

function* fetchScenarioList(action) {
  try {
    const searchFlag = yield select((state) => state.dashboardData.searchFlag);
    if (!searchFlag) {
      yield put(setLoadingState(true));
      const suiteid = action.payload;
      const tenantSelection = yield select(
        (state) => state.dashboardData.tenantSelection
      );
      const startDate = yield select((state) => state.dashboardData.startDate);
      const endDate = yield select((state) => state.dashboardData.endDate);

      const { scenaioList, scenarioSelection, scenarioNameSelection } =
        yield call(scenarioFetch, suiteid, tenantSelection, startDate, endDate);

      yield put(getScenarioList(scenaioList));
      yield put(currentScenarioNameSelection(scenarioNameSelection));
      yield put(setScenarioSelection(scenarioSelection));
    }
  } catch (error) {
    yield put(getScenarioList(null));
    yield put(currentScenarioNameSelection(null));
    yield put(setScenarioSelection(null));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* suiteIDChange() {
  yield takeLatest("SET_SUITE_SELECTION", fetchScenarioList);
}

function* getSteps(action) {
  try {
    const searchFlag = yield select((state) => state.dashboardData.searchFlag);
    if (!searchFlag) {
      yield put(setLoadingState(true));
      const scenarioId = action.payload;
      const tenantSelection = yield select(
        (state) => state.dashboardData.tenantSelection
      );
      const startDate = yield select((state) => state.dashboardData.startDate);
      const endDate = yield select((state) => state.dashboardData.endDate);

      const stepList = yield call(
        stepFetch,
        scenarioId,
        tenantSelection,
        startDate,
        endDate
      );

      yield put(getStepList(stepList));
    }
  } catch (error) {
    yield put(getStepList(null));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* scenarioSelectionChange() {
  yield takeEvery("SET_SCENARIO_SELECTION", getSteps);
}

function* search_fun(action) {
  try {
    yield put(setLoadingState(true));
    const searchText = action.payload.searchText;
    const searchType = action.payload.searchType;

    const tenantSelection = yield select(
      (state) => state.dashboardData.tenantSelection
    );
    const startDate = yield select((state) => state.dashboardData.startDate);
    const endDate = yield select((state) => state.dashboardData.endDate);

    if (searchType === "SuiteRunIDRadios") {
      const { success, isWithinRange, faselection, suiteNameID } = yield call(
        searchSuiteID,
        searchText,
        startDate,
        endDate
      );

      if (success && isWithinRange) {
        const { suiteList, SuiteInstanceList } = yield call(
          searchSuiteFetch,
          suiteNameID,
          faselection,
          tenantSelection,
          startDate,
          endDate
        );
        yield put(setFAAreaSelection(faselection));
        yield put(getSuiteList(suiteList));
        yield put(currentSuiteNameSelection(suiteNameID));
        yield put(fetchSuiteInstanceListing(SuiteInstanceList));
        yield put(setSuiteSelection(searchText));

        const { scenaioList, scenarioSelection, scenarioNameSelection } =
          yield call(
            scenarioFetch,
            searchText,
            tenantSelection,
            startDate,
            endDate
          );

        yield put(getScenarioList(scenaioList));
        yield put(currentScenarioNameSelection(scenarioNameSelection));
        yield put(setScenarioSelection(scenarioSelection));

        const stepList = yield call(
          stepFetch,
          scenarioSelection,
          tenantSelection,
          startDate,
          endDate
        );

        yield put(getStepList(stepList));
      } else {
        let errorMsg = `The Suite Run ID ${searchText} does not exist.`;
        yield put(showError(true, errorMsg, "error"));
      }
    }

    if (searchType === "scenRunIDRadios") {
      const { success, rangeScenario, suiteID_n, scenarioNameID } = yield call(
        searchScenarioID,
        searchText,
        startDate,
        endDate
      );

      if (success && rangeScenario) {
        const { faselection, suiteNameID, suiteID } = yield call(
          searchSuiteID,
          suiteID_n
        );

        const { suiteList, SuiteInstanceList } = yield call(
          searchSuiteFetch,
          suiteNameID,
          faselection,
          tenantSelection,
          startDate,
          endDate
        );

        yield put(setFAAreaSelection(faselection));
        yield put(getSuiteList(suiteList));
        yield put(currentSuiteNameSelection(suiteNameID));
        yield put(fetchSuiteInstanceListing(SuiteInstanceList));
        yield put(setSuiteSelection(suiteID));

        const { scenaioList } = yield call(
          scenarioFetch,
          suiteID,
          tenantSelection,
          startDate,
          endDate
        );

        yield put(getScenarioList(scenaioList));
        yield put(setScenarioSelection(searchText));
        yield put(currentScenarioNameSelection(scenarioNameID));
        const stepList = yield call(
          stepFetch,
          searchText,
          tenantSelection,
          startDate,
          endDate
        );

        yield put(getStepList(stepList));
      } else {
        const errorMsg = `The Scenario Run ID ${searchText} does not exist.`;
        yield put(showError(true, errorMsg, "error"));
      }
    }

    // Other code here...
  } catch (error) {
    console.error("msg", error);
  } finally {
    yield put(setSearchFlag(false));
    yield put(setLoadingState(false));
  }
}

function* search() {
  yield takeEvery("SET_SEARCH", search_fun);
}
function* moduleChange(){
  yield takeLatest("SET_MODULE_SELECTION", fetchTestSuiteList);
}

function* DashboardSaga() {
  yield all([
    fork(watchTenantChange),
    fork(faChange),
    fork(suiteIDChange),
    fork(scenarioSelectionChange),
    fork(search),
    fork(moduleChange)
  ]);
}

export default DashboardSaga;
