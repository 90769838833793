import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import NonAuthLayout from "../Pages/AHeaderFooterSections/NonAuthLayout";
import HorizontalLayout from "../Pages/AHeaderFooterSections/index";
import { AuthProtected } from "./AuthProtected";
import { publicRoutes } from "./routes";
import {
  adminRoutes,
  commonRoutes,
  privilegedRoutes,
  testerRoutes,
  viewerRoutes,
} from "../Pages/accessSettingsMenu";
import { Profile } from "../Pages/Profile";
import { Error404Page } from "../Pages/Error404Page";

const Index = () => {
  const userLoggedIn = useSelector((state) => state.login.userLoggedIn || []);
  const [authProtectedRoutes, setAuthProtectedRoutes] = useState([]);

  useEffect(() => {
    if (!userLoggedIn) return;

    const storedAuthUser = localStorage.getItem("authUser");
    const userObj = storedAuthUser ? JSON.parse(storedAuthUser) : null;
    const userRoles = userObj?.user || {};

    const newAuthProtectedRoutes = [
      ...(userRoles.adminRole ? adminRoutes : []),
      ...(userRoles.testerRole ? testerRoutes : []),
      ...(userRoles.viewerRole ? viewerRoutes : []),
      ...(userRoles.privilegeUserRole ? privilegedRoutes : []),
      ...(userRoles.adminRole ? [] : commonRoutes),
    ];

    if (
      JSON.stringify(authProtectedRoutes) !==
      JSON.stringify(newAuthProtectedRoutes)
    ) {
      setAuthProtectedRoutes(newAuthProtectedRoutes);
    }
  }, [userLoggedIn, authProtectedRoutes]);

  const Layout = HorizontalLayout;
  return (
    <Routes>
      <Route path="*" element={<Error404Page />} />
      <Route>
        {publicRoutes.map((route) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={route.path}
            exact={true}
          />
        ))}
      </Route>

      <Route>
        {authProtectedRoutes.map((route) => (
          <Route
            path={route.path}
            element={
              <AuthProtected>
                <Layout>{route.component}</Layout>
              </AuthProtected>
            }
            key={route.path}
            exact={true}
          />
        ))}
        <Route
          path="/profile"
          element={
            <AuthProtected>
              <Layout>
                <Profile />
              </Layout>
            </AuthProtected>
          }
          exact={true}
        />
      </Route>
    </Routes>
  );
};

export default Index;
