import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "./HeaderComponents/withRouter";

//redux
import { useSelector, useDispatch } from "react-redux";

//actions
import {
  changeLayout,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions";

//components
import Navbar from "./TopMenu";
import Header from "./Header";
import Footer from "./Footer";

const Layout = (props) => {
  const dispatch = useDispatch();

  const { topbarTheme, layoutWidth } = useSelector((state) => ({
    topbarTheme: state.Layout.topbarTheme,
    layoutWidth: state.Layout.layoutWidth,
  }));

  /*
  document title
  */
  useEffect(() => {
    const title = props.router.location.pathname;
    let currentage = title.charAt(1).toUpperCase() + title.slice(2);

    document.title = currentage + " | Landing Page";
  }, [props.router.location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /*
  layout settings
  */
  useEffect(() => {
    dispatch(changeLayout("horizontal"));
  }, [dispatch]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [dispatch, topbarTheme]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [dispatch, layoutWidth]);

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  return (
    <div id="layout-wrapper">
      <Header
        theme={topbarTheme}
        isMenuOpened={isMenuOpened}
        openLeftMenuCallBack={openMenu}
      />
      <Navbar />
      <div className="main-content">{props.children}</div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.object,
  router: PropTypes.any,
};

export default withRouter(Layout);
