import { handleCommonAPIError } from "../../config/util";
import { logoutMyUser } from "./WrapperAPI";
import {
  tenantFetchAPI,
  FunctionalAreaFetchAPI,
  suiteListAPI,
  scenarioListAPI,
  stepListAPI,
  searchSuiteIdAPI,
  searchScenarioIdAPI,
  loginAPIpost,
  moduleListAPI,
  testSuiteListAPI,
  fetchSuiteRunListAPISub,
  suiteRunAPISub,
  notificationFetchPost,
  quicklinkFetchAPI,
  announcementFetchAPI,
  logoutAPI,
  getAllScheduleAPI,
  createScheduleAPI,
  updateScheduleAPI,
  deleteScheduleAPI,
  uploadFileAPI,
  getUploadedFilesListAPI,
  downloadTemplateFileForTestSuiteAPI,
  fetchExecutedSuiteRunListAPI,
  cancelExecutedTestSuiteAPI,
  getAllUserListAPI,
  updateUserDetailsAPI,
  updateSelfUserDetailsAPI,
  updateUserPasswordAPI,
  deleteUserAPI,
  announcementDeleteAPI,
  announcementUpdateAPI,
  announcementCreateAPI,
  quickLinkCreateAPI,
  quickLinkUpdateAPI,
  quickLinkDeleteAPI,
  createUserDetailsAPI,
  generateQroAPI,
  skipQroAPI,
  resetPasswordAPI,
  resetPasswordLinkAPI,
  validateQroAPI,
  forgotPasswordAPI,
  ssoCallbackAPI,
  moduleListFetchAPI,
} from "./apiCalls";

export const logoutFetch = async () => {
  try {
    const response = await logoutAPI();
    return response;
  } catch (error) {
    logoutMyUser();
    console.error("Error with logout user:", error);
    handleCommonAPIError("Failed to logout user");
  }
};
export const tenantfetch = async () => {
  try {
    const tenants = await tenantFetchAPI();
    const tenantSelection = tenants[0].tenantId;
    // Return the values as an object
    return { tenants, tenantSelection };
  } catch (error) {
    console.error("Error fetching tenants:", error);
    handleCommonAPIError("Failed to fetch tenants");
  }
};

export const faFetch = async (tenantSelection, startDate, endDate) => {
  try {
    const apiPayload = {
      filter: {
        tenant: tenantSelection,
        start_date: startDate,
        end_date: endDate,
      },
    };

    const faAreas = await FunctionalAreaFetchAPI(apiPayload);

    const faAreaSelection = faAreas[0].faId;

    // Return the values as an object
    return { faAreas, faAreaSelection };
  } catch (error) {
    console.error("Error fetching functional Areas:", error);
    handleCommonAPIError("Failed to functional Areas");
  }
};
export const moduleListFetch = async (
  faAreaSelection,
  tenantSelection,
  startDate,
  endDate,
  dateRange
) => {
  try {
    const apiPayload = {
      faId: faAreaSelection,
      filter: {
        tenant: tenantSelection,
        start_date: startDate,
        end_date: endDate,
        date_range: dateRange


      },
    };

    const response = await moduleListFetchAPI(apiPayload);

    return response;
  } catch (error) {
    console.error("Error fetching module list:", error);
    handleCommonAPIError("Failed to module list");
  }
};

export const suiteFetch = async (
  faAreaSelection,
  tenantSelection,
  startDate,
  endDate
) => {
  try {
    const apiPayload = {
      faId: faAreaSelection,
      filter: {
        tenant: tenantSelection,
        start_date: startDate,
        end_date: endDate,
      },
    };

    const suiteList = await suiteListAPI(apiPayload);
    const suiteSelection = suiteList[0].suiteRIDetails[0].suiteRIId;
    const currentSuiteName = suiteList[0].suiteRISummary.suiteId;
    const SuiteInstanceList = suiteList[0].suiteRIDetails;
    // Return the values as an object
    return { suiteList, suiteSelection, currentSuiteName, SuiteInstanceList };
  } catch (error) {
    console.error("Error fetching functional Areas:", error);
    handleCommonAPIError("Failed to functional Areas");
  }
};
export const moduleFetch = async (
  faAreaSelection,
  tenantSelection,
  startDate,
  endDate,
  dateRange
) => {
  try {
    const apiPayload = {
      faId: faAreaSelection,
      filter: {
        tenant: tenantSelection,
        start_date: startDate,
        end_date: endDate,
        date_Range: dateRange
      },
    };

    const moduleList = await moduleListAPI(apiPayload);
    const moduleSelection = moduleList[0]

    // Return the values as an object
    return { moduleList, moduleSelection };
  } catch (error) {
    console.error("Error fetching module:", error);
    handleCommonAPIError("Failed to get module");
  }
};

export const testSuiteFetch = async (
  faAreaSelection,
  tenantSelection,
  startDate,
  endDate,
  dateRange,
  moduleName,
  selectedSuiteIds

) => {

  try {
    const apiPayload = {
      faId: faAreaSelection,
      suiteRIIds: selectedSuiteIds,

      moduleName,
      filter: {
        tenant: tenantSelection,
        start_date: startDate,
        end_date: endDate,
        date_Range: dateRange
      },
    };

    const testSuiteList = await testSuiteListAPI(apiPayload);
    const testSuiteSelection = testSuiteList[0]

    // Return the values as an object
    return { testSuiteList, testSuiteSelection };
  } catch (error) {
    console.error("Error fetching module:", error);
    handleCommonAPIError("Failed to get module");
  }
};

export const scenarioFetch = async (
  suiteid,
  TenantSelection,
  startDate,
  endDate
) => {
  try {
    const apiPayload = {
      suiteRIId: parseInt(suiteid, 10),
      filter: {
        tenant: TenantSelection,
        start_date: startDate,
        end_date: endDate,
      },
    };

    const scenaioList = await scenarioListAPI(apiPayload);
    const scenarioSelection = scenaioList?.length
      ? scenaioList[0]?.scenarioRIDetails[0]?.scenarioRIId
      : 0;
    const scenarioNameSelection = scenaioList?.length
      ? scenaioList[0].scenarioDetails.scenarioId
      : 0;

    // Return the values as an object
    return { scenaioList, scenarioSelection, scenarioNameSelection };
  } catch (error) {
    console.error("Error fetching functional Areas:", error);
    handleCommonAPIError("Failed to functional Areas");
  }
};

export const stepFetch = async (
  senarioid,
  tenantSelection,
  startDate,
  endDate
) => {
  try {
    const apiPayload = {
      scenarioRIId: parseInt(senarioid, 10),
      filter: {
        tenant: tenantSelection,
        start_date: startDate,
        end_date: endDate,
      },
    };

    const stepList = await stepListAPI(apiPayload);

    // Return the values as an object
    return stepList;
  } catch (error) {
    console.error("Error fetching functional Areas:", error);
    handleCommonAPIError("Failed to functional Areas");
  }
};

export const searchSuiteID = async (searchText, startDate, endDate) => {
  try {
    const suiteDetails = await searchSuiteIdAPI(searchText);
    const faselection = suiteDetails?.suiteruninstances?.faId;
    const suiteNameID = suiteDetails?.suiteruninstances?.tsId;
    const todateRequest = new Date(
      suiteDetails?.suiteruninstances?.run_start_dt
    );
    const fromdateRequest = new Date(
      suiteDetails?.suiteruninstances?.run_stop_dt
    );
    const suiteID = parseInt(searchText, 10);
    // Convert the provided startDate and endDate to Date objects
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const isWithinRange =
      todateRequest >= startDateObj && fromdateRequest <= endDateObj;
    console.log(isWithinRange);
    // Return the values as an object along with the success status
    return {
      success: true,
      isWithinRange: true, // isWithinRange,
      faselection,
      suiteNameID,
      suiteID,
      todateRequest,
      fromdateRequest,
    };
  } catch (error) {
    console.error("Error fetching functional Areas:", error);

    // Return the error along with the failure status
    return { success: false, isWithinRange: true, error };
  }
};

export const searchSuiteFetch = async (
  suiteNameID,
  faAreaSelection,
  tenantSelection,
  startDate,
  endDate
) => {
  try {
    const apiPayload = {
      faId: faAreaSelection,
      filter: {
        tenant: tenantSelection,
        start_date: startDate,
        end_date: endDate,
      },
    };

    const suiteList = await suiteListAPI(apiPayload);

    const filteredSuite = suiteList.find(
      (suite) => suite.suiteRISummary.suiteId === suiteNameID
    );

    const SuiteInstanceList = filteredSuite ? filteredSuite.suiteRIDetails : [];

    // Return the values as an object
    return { suiteList, SuiteInstanceList };
  } catch (error) {
    console.error("Error fetching Search:", error);
    handleCommonAPIError("Failed to Search");
  }
};

export const searchScenarioID = async (searchText, startDate, endDate) => {
  try {
    const searchScenario = parseInt(searchText, 10);
    const sceneDetails = await searchScenarioIdAPI(searchScenario);
    const suiteID = sceneDetails?.scenarioruninstances?.suiteRunId;
    const scenarioNameID = sceneDetails?.scenarioruninstances?.scenarioId;
    const todateRequest = new Date(
      sceneDetails?.scenarioruninstances?.run_start_dt
    );
    const fromdateRequest = new Date(
      sceneDetails?.scenarioruninstances?.run_stop_dt
    );

    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const isWithinRangeScenario =
      todateRequest >= startDateObj && fromdateRequest <= endDateObj;
    const suiteID_n = parseInt(suiteID, 10);
    return {
      success: true,
      rangeScenario: isWithinRangeScenario,
      suiteID_n,
      scenarioNameID,
    }; // <-- Return suiteID_n instead of suiteID_txt
  } catch (error) {
    console.error("Error fetching Scnearios search:", error);
    return { success: false, rangeScenario: true, error };
  }
};

export const loginApi = async (userEmailId, password, encrypted) => {
  try {
    const apiPayload = {
      user: {
        userEmailId,
        password,
        encrypted,
      },
    };

    const userProfile = await loginAPIpost(apiPayload);

    return userProfile;
  } catch (error) {
    console.error("Login Error:", error);
    handleCommonAPIError("Login Error");
  }
};

//

export const fetchSuiteRunListAPI = async () => {
  try {
    const fetchSuiteRunListAPIContent = await fetchSuiteRunListAPISub();

    return fetchSuiteRunListAPIContent;
  } catch (error) {
    console.error("Fetching error on Suite Run List:", error);
    handleCommonAPIError("Fetching error on Suite Run List");
  }
};

export const suiteRunAPI = async (faId, suiteId, tenantId, filename = "") => {
  try {
    const apiPayload = {
      faId: faId,
      vmId: "5",
      suiteId: suiteId,
      tenantId: tenantId,
      filename,
    };

    const suiteRunResponse = await suiteRunAPISub(apiPayload);

    return suiteRunResponse;
  } catch (error) {
    console.error("error suite Run API:", error);
    handleCommonAPIError("error suite Run API");
  }
};

export const notificationFetch = async (tenant, userEmailId) => {
  try {
    const apiPayload = {
      filter: {
        tenant: tenant,
        userEmailId: userEmailId,
      },
    };
    const notifications = await notificationFetchPost(apiPayload);
    return notifications;
  } catch (error) {
    console.error("Error on fetching Notification:", error);
    handleCommonAPIError("Notification fetch Error");
  }
};

export const quickLinkFetch = async (requestParam) => {
  try {
    const quickLinkData = await quicklinkFetchAPI(requestParam);
    return quickLinkData;
  } catch (error) {
    console.error("Fetching error on quickLinkData:", error);
    handleCommonAPIError("Fetching error on quickLinkData");
  }
};
export const quickLinkCreate = async (requestPayload) => {
  try {
    const quickLinkData = await quickLinkCreateAPI(requestPayload);
    return quickLinkData;
  } catch (error) {
    console.error("Fetching error on quickLinkData:", error);
    handleCommonAPIError("Fetching error on quickLinkData");
  }
};
export const quickLinkUpdate = async (requestParam, id) => {
  try {
    const quickLinkData = await quickLinkUpdateAPI(requestParam, id);
    return quickLinkData;
  } catch (error) {
    console.error("Fetching error on quickLinkData:", error);
    handleCommonAPIError("Fetching error on quickLinkData");
  }
};
export const quickLinkDelete = async (requestParam) => {
  try {
    const quickLinkData = await quickLinkDeleteAPI(requestParam);
    return quickLinkData;
  } catch (error) {
    console.error("Fetching error on quickLinkData:", error);
    handleCommonAPIError("Fetching error on quickLinkData");
  }
};

export const announcementFetch = async (requestParam) => {
  try {
    const announcementData = await announcementFetchAPI(requestParam);
    return announcementData;
  } catch (error) {
    console.error("Fetching error on announcementData:", error);
    handleCommonAPIError("Fetching error on announcementData");
  }
};
export const announcementCreate = async (requestPayload) => {
  try {
    const announcementData = await announcementCreateAPI(requestPayload);
    return announcementData;
  } catch (error) {
    console.error("Fetching error on announcementData:", error);
    handleCommonAPIError("Fetching error on announcementData");
  }
};
export const announcementUpdate = async (requestParam, id) => {
  try {
    const announcementData = await announcementUpdateAPI(requestParam, id);
    return announcementData;
  } catch (error) {
    console.error("Fetching error on announcementData:", error);
    handleCommonAPIError("Fetching error on announcementData");
  }
};
export const announcementDelete = async (requestParam) => {
  try {
    const announcementData = await announcementDeleteAPI(requestParam);
    return announcementData;
  } catch (error) {
    console.error("Fetching error on announcementData:", error);
    handleCommonAPIError("Fetching error on announcementData");
  }
};
export const getAllScheduleDetails = async () => {
  try {
    const response = await getAllScheduleAPI();
    return response;
  } catch (error) {
    console.error("Error with get schedule:", error);
    handleCommonAPIError("Failed to get schedule");
  }
};
export const createSchedule = async (apiPayload) => {
  try {
    const response = await createScheduleAPI(apiPayload);
    return response;
  } catch (error) {
    console.error("Error with create schedule:", error);
    handleCommonAPIError("Failed to create schedule");
  }
};
export const updateSchedule = async (apiPayload, id) => {
  try {
    const response = await updateScheduleAPI(apiPayload, id);
    return response;
  } catch (error) {
    console.error("Error with update schedule:", error);
    handleCommonAPIError("Failed to update schedule");
  }
};
export const deleteSchedule = async (id) => {
  try {
    const response = await deleteScheduleAPI(id);
    return response;
  } catch (error) {
    console.error("Error with delete schedule:", error);
    handleCommonAPIError("Failed to delete schedule");
  }
};

export const uploadFile = async (faId, suiteId, formData) => {
  try {
    const response = await uploadFileAPI(faId, suiteId, formData);
    return response;
  } catch (error) {
    console.error("Error with delete schedule:", error);
    handleCommonAPIError("Failed to delete schedule");
  }
};
export const getUploadedFilesList = async (faId, suiteId, filename) => {
  try {
    const response = await getUploadedFilesListAPI(faId, suiteId, filename);
    return response;
  } catch (error) {
    console.error("Error with get text suite files:", error);
    handleCommonAPIError("Failed to get text suite files");
  }
};
export const downloadTemplateFileForTestSuite = async (faId, suiteId) => {
  try {
    const response = await downloadTemplateFileForTestSuiteAPI(faId, suiteId);
    return response;
  } catch (error) {
    console.error("Error with template file download:", error);
    handleCommonAPIError("Error with template file download");
  }
};

export const fetchExecutedSuiteRunList = async () => {
  try {
    const fetchSuiteRunListAPIContent = await fetchExecutedSuiteRunListAPI();

    return fetchSuiteRunListAPIContent;
  } catch (error) {
    console.error("Fetching error on Suite Run List:", error);
    handleCommonAPIError("Fetching error on Suite Run List");
  }
};
export const cancelExecutedTestSuite = async (id) => {
  try {
    const response = await cancelExecutedTestSuiteAPI(id);
    return response;
  } catch (error) {
    console.error("Error with delete schedule:", error);
    handleCommonAPIError("Failed to delete schedule");
  }
};
export const getAllUserList = async () => {
  try {
    const response = await getAllUserListAPI();
    return response;
  } catch (error) {
    console.error("Error with fetching user list:", error);
    handleCommonAPIError("Error with fetching user list");
  }
};
export const createNewUserDetails = async (requestPayload) => {
  try {
    const response = await createUserDetailsAPI(requestPayload);
    return response;
  } catch (error) {
    console.error("Error with user update:", error);
    handleCommonAPIError("Error with user update");
  }
};
export const updateUserDetails = async (userId, requestPayload) => {
  try {
    const response = await updateUserDetailsAPI(userId, requestPayload);
    return response;
  } catch (error) {
    console.error("Error with user update:", error);
    handleCommonAPIError("Error with user update");
  }
};
export const updateSelfUserDetails = async (userId, requestPayload) => {
  try {
    const response = await updateSelfUserDetailsAPI(userId, requestPayload);
    return response;
  } catch (error) {
    console.error("Error with user update:", error);
    handleCommonAPIError("Error with user update");
  }
};
export const updateUserPassword = async (requestPayload) => {
  try {
    const response = await updateUserPasswordAPI(requestPayload);
    return response;
  } catch (error) {
    console.error("Error with user update:", error);
    handleCommonAPIError("Error with user update");
  }
};
export const deleteUser = async (requestPayload) => {
  try {
    const response = await deleteUserAPI(requestPayload);
    return response;
  } catch (error) {
    console.error("Error with user update:", error);
    handleCommonAPIError("Error with user update");
  }
};

// ============= MFA API Methods  ============= \\
export const generateQro = async (requestPayload) => {
  try {
    const response = await generateQroAPI(requestPayload);
    return response;
  } catch (error) {
    console.error("Error with generate QRO:", error);
    handleCommonAPIError("Error with generate QRO");
  }
};

export const skipQro = async (requestPayload) => {
  try {
    const response = await skipQroAPI(requestPayload);
    return response;
  } catch (error) {
    console.error("Error with skip QRO:", error);
    handleCommonAPIError("Error with skip QRO");
  }
};
export const validateQro = async (requestPayload = null) => {
  try {
    const response = await validateQroAPI(requestPayload);
    return response;
  } catch (error) {
    console.error("Error with validate QRO:", error);
    handleCommonAPIError("Error with validate QRO");
  }
};

// ============= Reset Password API Methods  ============= \\
export const resetPasswordLink = async (requestPayload) => {
  try {
    const response = await resetPasswordLinkAPI(requestPayload);
    return response;
  } catch (error) {
    console.error("Error with reset password link:", error);
    handleCommonAPIError("Error with reset password link");
  }
};
export const resetPassword = async (requestPayload, token) => {
  try {
    const response = await resetPasswordAPI(requestPayload, token);
    return response;
  } catch (error) {
    console.error("Error with reset password:", error);
    handleCommonAPIError("Error with reset password");
  }
};
export const forgotPassword = async (requestPayload) => {
  try {
    const response = await forgotPasswordAPI(requestPayload);
    return response;
  } catch (error) {
    console.error("Error with forgot password:", error);
    handleCommonAPIError("Error with forgot password");
  }
};
export const ssoCallback = async (requestPayload) => {
  try {
    const response = await ssoCallbackAPI(requestPayload);
    return response;
  } catch (error) {
    console.error("Error with SSO Callback:", error);
    handleCommonAPIError("Error with SSO Callback");
  }
};
