import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, Col } from "reactstrap";
import { StyledDataGrid } from "../generalFunctions";
import {
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
} from "@mui/x-data-grid";
import {
  cancelExecutedTestSuite,
  suiteRunAPI,
} from "../../store/dashboard/orchestractor";
import { setLoadingState, showError } from "../../store/actions";
import { useDispatch } from "react-redux";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { IconButton } from "@mui/material";
import { useProfile } from "../../Hooks/UserHooks";
import { ExcelExportIcon } from "../../components/ExcelExportIcon";

const smallSelect = {
  fontSize: "12px",
  lineHeight: "15px",
  width: "100%",
  borderRadius: "2px",
  padding: "3px",
  marginBottom: "3px",
};
const circleStyle = {
  width: "13px",
  height: "13px",
  borderRadius: "55%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "8px",
  color: "white",
  marginLeft: "2px",
};

const marginZero = {
  display: "flex",
  flexWrap: "nowrap",
};

const redCircleStyle = {
  ...circleStyle,
  backgroundColor: "#ff3d60",
};

const greenCircleStyle = {
  ...circleStyle,
  backgroundColor: "#11c46e",
};

const blueCircleStyle = {
  ...circleStyle,
  backgroundColor: "#9da1a6",
};

const yellowCircleStyle = {
  ...circleStyle,
  backgroundColor: "#f1b44c",
};

const getStatus = (params) => {
  const status = params.row.status;
  // status = warning   running   passed  undefined failed  cancelled

  return (
    <div style={marginZero}>
      {status === "passed" ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={greenCircleStyle}></div>
          <div>&nbsp;{"Completed"}</div>
        </div>
      ) : (
        <></>
      )}
      {status === "failed" ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={redCircleStyle}></div>
          <div>&nbsp;{"Failed"}</div>
        </div>
      ) : (
        <></>
      )}
      {status === "running" ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={yellowCircleStyle}></div>
          <div>&nbsp;{"In Progress"}</div>
        </div>
      ) : (
        <></>
      )}
      {status === "warning" ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={blueCircleStyle}></div>
          <div>&nbsp;{"Warning"}</div>
        </div>
      ) : (
        <></>
      )}
      {status === "cancelled" ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={blueCircleStyle}></div>
          <div>&nbsp;{"Cancelled"}</div>
        </div>
      ) : (
        <></>
      )}
      {status === "undefined" ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={blueCircleStyle}></div>
          <div>&nbsp;{"Undefined"}</div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const getFilteredRows = ({ apiRef }) => {
  gridExpandedSortedRowIdsSelector(apiRef);
};

const CustomToolbar = () => {
  const apiRef = useGridApiContext();

  // Function to handle export button click to export data as CSV

  const handleExport = (options) => {
    // Set a custom file name for the exported CSV file
    const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    options.fileName = `suite_instance_listing_${timestamp}`;

    apiRef.current.exportDataAsCsv(options);
  };
  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ padding: "0 5px 0 5px", borderBottom: " 1px solid #f0f0f0" }}
    >
      <div>
        <h6 style={{ marginBottom: "0px", fontSize: "11px" }}>
          Suite Run Instance Listing
        </h6>
      </div>

      <ExcelExportIcon
        handleExport={handleExport}
        getFilteredRows={getFilteredRows}
      />
    </div>
  );
};

export const RecentJobs = (props) => {
  const { executedRunList, getExecutedTestRunList } = props;
  const dispatch = useDispatch();
  const { userProfile } = useProfile();
  const headerHeight = 28;
  const rowHeight = 26;

  const getButtonText = (status) => {
    switch (status) {
      case "running":
        return "Cancel";
      case "failed":
        return "Re-Run";
      default:
        return "";
    }
  };

  const columns = [
    {
      field: "testSuiteName",
      headerName: "Suite Name",
      width: 300,
      maxWidth: 300,
    },
    {
      field: "functionalAreaName",
      headerName: "Functional Area",
      width: 150,
      maxWidth: 150,
    },
    {
      field: "tenantName",
      headerName: "Tenant",
      width: 150,
      maxWidth: 150,
    },
    {
      field: "startedAt",
      headerName: "Started Date",
      width: 160,
      type: "date",
      valueFormatter: (params) => {
        const date = params.value ? new Date(params.value) : "";
        const options = {
          day: "2-digit",
          month: "short",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const formattedDateTime = date
          ? date.toLocaleDateString("en-UK", options)
          : "";
        return params.value ? formattedDateTime : "";
      },
    },
    {
      field: "stoppedAt",
      headerName: "Completed Date",
      width: 160,
      type: "date",
      valueFormatter: (params) => {
        const date = params.value ? new Date(params.value) : "";
        const options = {
          day: "2-digit",
          month: "short",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const formattedDateTime = date
          ? date.toLocaleDateString("en-UK", options)
          : "";
        return params.value ? formattedDateTime : "";
      },
    },
    {
      field: "userEmailId",
      headerName: "User Email",
      width: 200,
      maxWidth: 200,
    },
    {
      field: "status",
      renderCell: getStatus,
      headerName: "Status",
      width: 100,
      maxWidth: 200,
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      maxWidth: 100,
      renderCell: (params) => {
        const status = params.row.status;
        // warning  undefined  passed  failed  running  cancelled
        return (status === "failed" || status === "running") &&
          !userProfile?.user?.viewerRole ? (
          <button
            style={smallSelect}
            type="button"
            className="btn btn-primary"
            onClick={() => handleAction(params, status)}
          >
            {getButtonText(status)}
          </button>
        ) : (
          <></>
        );
      },
    },
    {
      field: "downloadFilePath",
      headerName: "File Download",
      width: 100,
      maxWidth: 100,
      renderCell: (params) => {
        const fileUrl = params?.row?.fileUrl;
        return fileUrl ? (
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => handleFileDownloadClick(fileUrl)}
          >
            <FileDownloadOutlinedIcon fontSize="inherit" />
          </IconButton>
        ) : (
          <></>
        );
      },
    },
  ];

  const rows =
    executedRunList?.map((item, index) => ({
      ...item,
      id: index + 1,
      downloadFilePath: item.fileUrl,
    })) || [];

  const [selectedSuiteSelection, setSelectedSuiteSelection] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  useEffect(() => {
    // Effect to handle changes in selected suite selection
  }, [selectedSuiteSelection]);

  const handleFileDownloadClick = (filePath) => {
    try {
      if (filePath) {
        const url = filePath;
        const a = document.createElement("a");
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleAction = async (record, status) => {
    dispatch(setLoadingState(true));

    try {
      const id = record?.row?.testSuiteId;

      if (status === "running" && id) {
        const response = await cancelExecutedTestSuite(id);
        if (response) handleRefreshClick();
      } else if (status === "failed") {
        const {
          testSuiteId = 0,
          filename = "",
          testSuiteName = "",
          faId = 0,
          functionalAreaName = "",
          tenantId = 0,
        } = record?.row || {};

        if (filename && faId && testSuiteId && tenantId) {
          const response = await suiteRunAPI(
            faId,
            testSuiteId,
            tenantId,
            filename
          );
          console.log(response);
          const message = `"${functionalAreaName}-${testSuiteName}" is initiated`;
          dispatch(showError(true, message, "info"));
          handleRefreshClick(); // Refresh list
        } else {
          const errorMessage = getErrorMessage({
            filename,
            faId,
            testSuiteId,
            tenantId,
          });
          dispatch(showError(true, errorMessage, "error"));
        }
      }
    } catch (error) {
      console.error(error);
      dispatch(showError(true, "An unexpected error occurred.", "error"));
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  const getErrorMessage = ({ filename, faId, testSuiteId, tenantId }) => {
    if (!filename) return "Uploaded file is not available.";
    if (!faId) return "Functional Area Id is not available.";
    if (!testSuiteId) return "Test Run Suite Id is not available.";
    if (!tenantId) return "Tenant Id is not available.";
    return "Some error occurred.";
  };

  const handleRefreshClick = () => {
    try {
      getExecutedTestRunList();
    } catch (error) {
      console.error(error);
    }
  };
  const handleCellClick = async (params) => {
    const suiteSelection = params.row.id;
    setSelectedSuiteSelection(suiteSelection);
  };

  return (
    <Col xl={12}>
      <Card>
        <CardBody>
          <CardTitle className="h4">
            <div className="d-flex justify-content-between align-items-center">
              <div
                className="page-title-right ml-auto"
                style={{ display: "flex" }}
              >
                <div style={{ width: "250px" }}>Suite Run Jobs</div>
                <button
                  style={smallSelect}
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleRefreshClick()}
                >
                  Refresh
                </button>
              </div>
            </div>
          </CardTitle>

          <div
            style={{
              background: "#ffffff",
              overflow: "auto",
              height: "445px",
              width: "100%",
              flexGrow: 1,
            }}
          >
            {/* Render the StyledDataGrid component with the specified rows, columns, and props */}
            <StyledDataGrid
              initialState={{}}
              rows={rows}
              columns={columns}
              rowHeight={rowHeight}
              columnHeaderHeight={headerHeight}
              onCellClick={handleCellClick}
              hideFooter
              hideFooterPagination
              hideFooterSelectedRowCount
              slots={{ toolbar: CustomToolbar }}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              componentsProps={{
                // Set custom styles for the datafg grid components

                basePopper: {
                  // }
                  sx: {
                    "& .MuiTypography-root": { fontSize: "12px" },
                  },
                },
              }}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

RecentJobs.propTypes = {
  executedRunList: PropTypes.any,
  getExecutedTestRunList: PropTypes.any,
};
