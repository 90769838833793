import { Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

export const PieChart = (props) => {
  const { name, series, labels, width = 250 } = props;
  const chartDetails = {
    series: series,
    options: {
      chart: {
        width: width,
        title: "My Chart",
        type: "donut",
      },
      stroke: {
        width: 0,
      },
      // Mismatch", "Newly Added", "No-Change",
      colors: ["#3971B8", "#1D2753", "#EF8621"],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: false,
                show: false,
              },
            },
          },
        },
      },
      labels: labels,
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return `${val}`;
        },
      },
      legend: {
        show: false,
        position: "bottom",
        horizontalAlign: "center",
      },
    },
  };
  return (
    <div>
      <ReactApexChart
        options={chartDetails.options}
        series={chartDetails.series}
        type="donut"
        width={width}
      />
      <div id="html-dist"></div>
      <Typography>
        <strong>{name || ""}</strong>
      </Typography>
    </div>
  );
};

PieChart.propTypes = {
  name: PropTypes.any,
  series: PropTypes.any,
  labels: PropTypes.any,
  width: PropTypes.any,
};
