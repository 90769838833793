// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workitems {
  border: solid;
  border-width: 1px;
  border-color: #d5d5d5;
  margin-top: 10px;
}

#workarea {
  height: 100%;
  width: 100%;
  margin-bottom: 5px;
}
#workarea .split-container {
  height: 100%;
  width: 100%;
  border: 1px solid #d5d5d5;
  border-radius: 2px;
}
#workarea .left-section {
  height: 100%;
  width: 29.5%;
  max-width: 29.5%;
  background: #d7e0ea;
}
#workarea .middle-section {
  height: 100%;
  width: 35%;
  background: #d7e0ea;
  min-width: 32%;
}
#workarea .right-section {
  flex: 1 1;
  height: 100%;
  width: 39.4%;
  background: #d7e0ea;
}

.smallp {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/pages/workBoard.scss"],"names":[],"mappings":"AAkBA;EACE,aAAA;EACA,iBAAA;EACA,qBAAA;EACA,gBAAA;AAjBF;;AAuBA;EAEE,YAAA;EACA,WAAA;EACA,kBA9BuB;AASzB;AAsBE;EACE,YAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAhCkB;AAYtB;AAuBE;EACE,YAAA;EACA,YAnCiB;EAoCjB,gBAnCqB;EAoCrB,mBA7Be;AAQnB;AAwBE;EACE,YAAA;EACA,UAvCmB;EAwCnB,mBAnCe;EAoCf,cAxCuB;AAkB3B;AAyBE;EACE,SAAA;EACA,YAAA;EACA,YA5CkB;EA8ClB,mBA5Ce;AAoBnB;;AA6BA;EACE,eAAA;AA1BF","sourcesContent":["$workarea-margin-left: 5px;\n$workarea-margin-right: 5px;\n$workarea-margin-bottom: 5px;\n\n$split-border-color: #d5d5d5;\n$split-border-radius: 2px;\n\n$left-section-width: 29.5%;\n$left-section-max-width: 29.5%;\n \n$middle-section-width: 35%;\n$middle-section-min-width: 32%;\n\n$right-section-width: 39.4%;\n$right-section-min-width: 39.4%;\n$panel-background: #d7e0ea;\n\n\n.workitems {\n  border: solid;\n  border-width: 1px;\n  border-color: #d5d5d5;\n  margin-top: 10px;\n\n}\n\n\n\n#workarea {\n \n  height: 100%;\n  width: 100%;\n  margin-bottom:$workarea-margin-bottom;\n  .split-container {\n    height: 100%;\n    width: 100%;\n    border: 1px solid $split-border-color;\n    border-radius: $split-border-radius;\n  }\n\n  .left-section {\n    height: 100%;\n    width: $left-section-width;\n    max-width: $left-section-max-width;\n    background: $panel-background;\n  }\n\n  .middle-section {\n    height: 100%;\n    width: $middle-section-width;\n    background: $panel-background;\n    min-width:$middle-section-min-width;\n  }\n\n  .right-section {\n    flex: 1;\n    height: 100%;\n    width: $right-section-width;\n   \n    background: $panel-background;\n  }\n}\n\n\n.smallp {\n  font-size: 12px;\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
