import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";
import { Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const Breadcrumbs = (props) => {
  const {
    breadcrumbItem = "",
    titleRoute = "",
    title = "",
    showButton = false,
    buttonText = "",
    buttonClickHandler = () => {},
  } = props;

  try {
    return (
      <Row>
        <Col xs="12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
              <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>
              {showButton ? (
                <Button
                  variant="contained"
                  endIcon={<OpenInNewIcon />}
                  onClick={buttonClickHandler}
                  style={{ textTransform: "none" }}
                >
                  {buttonText || ""}
                </Button>
              ) : (
                <></>
              )}
            </div>
            <div className="page-title-right">
              {/* Breadcrumb component */}
              <Breadcrumb listClassName="m-0">
                {/* Home breadcrumb item */}
                <BreadcrumbItem>
                  <Link to={titleRoute || "/home"}>{title}</Link>
                </BreadcrumbItem>
                {/* Active breadcrumb item */}
                <BreadcrumbItem active>
                  <Link to="#">{breadcrumbItem}</Link>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </Col>
      </Row>
    );
  } catch (error) {
    // Log the error using the logger instance
    // Replace 'logger' with your actual logger instance from Log4js or another library
    console.error("Error in Breadcrumbs component");

    // Return an alternative error message or null to suppress rendering the component
    return (
      <div>
        <p>An error occurred while rendering the Breadcrumbs component.</p>
      </div>
    );
  }
};

export default Breadcrumbs;

Breadcrumbs.propTypes = {
  breadcrumbItem: PropTypes.string,
  titleRoute: PropTypes.string,
  title: PropTypes.string,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonClickHandler: PropTypes.func,
};
