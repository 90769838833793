import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";

const RenderExpandableCell = (props) => {
  // State to track whether the content is overflowed or not

  // Extract the 'value' prop from the 'props'
  const { value } = props;

  // Ref to access the DOM element containing the text
  const textElementRef = useRef(null);

  // Function to check if the content is overflowed
  const checkOverflow = () => {
    // Get the client width of the text element
    const clientWidth = textElementRef.current.getBoundingClientRect().width;

    // Temporarily set overflow to visible to calculate content width
    textElementRef.current.style.overflow = "visible";
    const contentWidth = textElementRef.current.getBoundingClientRect().width;
    // Reset overflow to hidden
    textElementRef.current.style.overflow = "hidden";

    // Compare client width with content width to check if content is overflowed
    console.log(contentWidth > clientWidth);
  };

  // useEffect hook to check for overflow on component mount and window resize
  useEffect(() => {
    // Check for overflow on component mount
    checkOverflow();

    // Add a window resize event listener to re-check overflow on window resize
    window.addEventListener("resize", checkOverflow);

    // Clean up by removing the window resize event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  // Render the component with a tooltip if the content is overflowed
  return (
    <Tooltip title={value}>
      <span
        ref={textElementRef}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </span>
    </Tooltip>
  );
};

export default RenderExpandableCell;

RenderExpandableCell.propTypes = {
  value: PropTypes.any,
};
