import React, { useState } from "react";
import classnames from "classnames";
import FunAreaCards from "./FilterCards/WBfunAreaCard";
import WbsuiteCard from "./FilterCards/WBsuiteCard";
import Wbfilters from "./FilterCards/WBFilterscard";
import WbQuickSearchCard from "./FilterCards/WBQuickSearch";
import { Col, Collapse, Row } from "reactstrap";

const WBFilters = (props) => {
  const [columnData, setColumnData] = useState(true);

  const handleAdvanceFilterClick = () => {
    setColumnData(!columnData);
  };

  return (
    <Row>
      <Col className="col-12">
        <div className="card">
          <div className="p-0 card-body">
            <div className="accordion" id="accordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !columnData,
                    })}
                    type="button"
                    onClick={handleAdvanceFilterClick}
                    style={{ cursor: "pointer" }}
                  >
                    <h4 className="mb-0 font-size-12">Advanced Filters</h4>
                  </button>
                </h2>

                <Collapse isOpen={columnData} className="accordion-collapse">
                  <div className="accordion-body">
                    <div className="text-muted">
                      <Row>
                        <FunAreaCards />
                        <WbsuiteCard />

                        <Wbfilters />
                        <WbQuickSearchCard />
                      </Row>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default WBFilters;
